<template>
    <div class="filter-item search">
        <el-form @submit.native.prevent="action">
            <el-form-item class="margin-bottom-0">
                <el-input v-model="container[model]" placeholder="ara...">
                    <div class="suffix" slot="suffix" @click="action">
                        <i class="icon el-icon-search"></i>
                    </div>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'SearchFilter',
    props: ['container', 'model', 'action'],
    data() {
        return {
            //
        }
    },
    computed: mapGetters(['account']),
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
