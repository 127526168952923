<template>
    <page title="Seviye Tespit Sınavı">
        <div class="page page-test page-exam page-leveling-exams" v-loading="pageLoading">
            <div class="start-head">
                <div class="head">
                    <div class="title">Seviye Tespit Sınavı</div>
                    <div class="description"> Pozitifdil, rastgele oluşturulmuş bir seviye tespit sınavı yerine, milyonlarca öğrenciyi</div>
                </div>
                <div class="body box" v-if="ui">
                    <div class="column">
                        <el-form :model="form" label-position="top">
                            <el-form-item :class="{ 'is-error': submitted && $v.form.archive_branch_id.$error }">
                                <el-select v-model="form.archive_branch_id" placeholder="Dil Seçiniz">
                                    <el-option
                                        v-for="item in ui.leveling_exams"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id">
                                    </el-option>

                                    <template slot="prefix">
                                        <icon source="flag" class="el-input__icon" />
                                    </template>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="column">
                        <el-button type="primary" class="fluid" size="large" :loading="loading" :disabled="loading" @click="store(1)">
                            Sınavı Başlat!
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="page-body is-search" v-if="!pageLoading && elementSize(items(storePath(path)))">
                <div class="box">
                    <div class="box-body rows">
                        <template v-for="(item, index) in items(storePath(path))">
                            <div class="row cursor-pointer" :key="index" @click="goRoute('/leveling-exams/' + item.id)" :class="{tertiary : item.status === 'wait'}">
                                <div class="content">
                                    <div class="media">
                                        <icon source="clipboard-clock-outline" />
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ item.title }}
                                        </div>
                                        <div class="metas">
                                            <div class="meta">
                                                <icon source="translate" />
                                                {{ item.metas.branch }}
                                            </div>
                                        </div>
                                        <div class="metas">
                                            <div class="meta">
                                                <icon source="flag-outline" />
                                                {{ item.metas.audience }}
                                            </div>
                                            <div class="meta">
                                                <icon source="ballot-recount-outline" />
                                                {{ item.metas.question_count }} Soru
                                            </div>
                                            <div class="meta">
                                                <icon source="clock-outline" />
                                                {{ item.metas.duration }} dk.
                                            </div>
                                            <template v-if="item.status !== 'wait'">
                                                <div class="meta">
                                                    <icon source="check-circle-outline" />
                                                    {{ item.metas.correct_count }} Doğru
                                                </div>
                                                <div class="meta">
                                                    <icon source="alert-circle-outline" />
                                                    {{ item.metas.wrong_count }} Yanlış
                                                </div>
                                                <div class="meta">
                                                    <icon source="checkbox-blank-circle-outline" />
                                                    {{ item.metas.empty_count }} Boş
                                                </div>
                                                <div class="meta">
                                                    <icon source="clock-outline" />
                                                    {{ formatSecondTime(item.metas.time) }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
            </div>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_UI_VISIBLE_PLUGIN} from "@/store/mutations.type";
import {required} from "vuelidate/lib/validators";
import {GET_CRUD_ITEMS, STORE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Start',
    data() {
        return {
            path: 'platform/student/leveling-exams',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
            response: null,
            loading: false,
            submitted: false,
            form: {
                archive_branch_id: null
            },
        }
    },
    validations: {
        form: {
            archive_branch_id: {required},
        }
    },
    computed: mapGetters(['ui', 'pagination', 'items']),
    mounted() {
        this.$store.commit(SET_UI_VISIBLE_PLUGIN, false)

        this.index();
    },
    methods: {

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        handleSelectedProduct(productIds) {
            this.filter.products = productIds;
            this.page = 1;
            this.index();
        },

        handleSelectedTab(value) {
            this.filter.tab = value;
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, products : this.filter.products, tab : this.filter.tab, title : this.filter.title, leveling_exams : true})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        store(level) {
            this.submitted = true;

            this.$v.$touch()

            if (this.$v.$invalid) {
                return false
            }

            this.loading = true;
            this.form.path = this.path;
            this.form.disable_push = true;
            this.form.archive_audience_id = level;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.$router.push('/leveling-exams/' + response.id)
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
