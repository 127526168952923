<template>
    <page :title="$t('menu.readings')" v-loading="pageLoading">
        <template #actions>
            <div class="breadcrumb-wrp">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/dashboard' }">
                        {{ $t('menu.dashboard') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/readings' }">
                        {{ $t('menu.readings') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        {{ $route.meta.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </template>
        <div class="page reading-page-detail" v-if="response" >
            <div class="reading-page-detail--head">
                {{ response.title }}
            </div>
            <SingleReading :response="response" v-if="response.view === 'single'" />
            <MultipleReading :response="response" v-if="response.view === 'multiple'" />
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import {SET_UI_VISIBLE_PLUGIN} from "@/store/mutations.type";
import SingleReading from "@/app/Pages/Readings/_parts/Single";
import MultipleReading from "@/app/Pages/Readings/_parts/Multiple";

export default {
    name: 'Show',
    components: {MultipleReading, SingleReading},
    data() {
        return {
            path: 'platform/student/readings',
            pageLoading: true,
            response: null,
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
        this.$store.commit(SET_UI_VISIBLE_PLUGIN, false)
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        }
    }
}
</script>
