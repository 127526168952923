<template>
   <page :title="$route.meta.title">
       <template #actions>
           <div class="page-filter">
               <ProductFilter :filter="handleSelectedProduct" />
           </div>
       </template>
       <div class="page document-page" v-loading="pageLoading">
           <div class="page-search">
               <SearchFilter :container="filter" model="title" :action="index" />
           </div>
           <div class="page-body is-search">
               <div class="box">
                   <empty alias="document" title="document" v-if="!pageLoading && !elementSize(items(storePath(path)))" />
                   <div class="box-body rows">
                       <template v-for="(item, index) in items(storePath(path))">
                           <div class="row cursor-pointer" :key="index" @click="goRoute('/documents/' + item.id)">
                               <div class="content">
                                   <div class="media">
                                       <svg-icon src="app-documents" />
                                   </div>
                                   <div class="text">
                                       <div class="title">
                                           {{ item.title }}
                                       </div>
                                       <div class="metas">
                                           <div class="meta">
                                               <icon source="calendar-outline" />
                                               {{ item.date }}
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div class="actions">
                                   <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" />
                               </div>
                           </div>
                       </template>
                   </div>
               </div>
               <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
           </div>
       </div>
   </page>
</template>

<script>
import ProductFilter from "@/app/Components/Filters/Product";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import SearchFilter from "@/app/Components/Filters/Search";

export default {
    name: 'Index',
    components: {SearchFilter, ProductFilter},
    data() {
        return {
            path: 'platform/student/documents',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        handleSelectedProduct(productIds) {
            this.filter.products = productIds;
            this.page = 1;
            this.index();
        },

        handleSelectedTab(value) {
            this.filter.tab = value;
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, products : this.filter.products, title : this.filter.title})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
    }
}
</script>
