import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Dictionary/Index";
import Show from "@/app/Pages/Dictionary/Show";

export default {
	path: '/dictionary',
	component: PageLayout,
	meta: {
		auth: true,
		title: 'Sözlük',
	},
	children: [
		{
			name: 'app.dictionary.index',
			path: '',
			component: Index,
			meta: {
				auth: true,
				title: 'Sözlük',
			},
		},
		{
			name: 'app.dictionary.show',
			path: ':id',
			component: Show,
			meta: {
				auth: true,
				title: 'Sözlük',
			},
		},
	]
}
