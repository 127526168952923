<template>
    <div class="education-relation-tests">
        <template v-for="test in items">
            <div class="box margin-top-20 ">
                <div class="box-head">
                    <div class="title">{{ test.title }}</div>
                </div>
                <div class="box-body page-test">
                    <template v-for="(item, index) in test.questions">
                        <QuestionItem :item="item" :key="index" :view="view" />
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import QuestionItem from "@/app/Pages/Tests/_parts/QuestionItem.vue";

export default {
    name: 'RelationTest',
    components: {QuestionItem},
    props : ['items', 'view'],
}
</script>
