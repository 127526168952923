<template>
    <div class="page-test">
        <div class="box">
            <template v-for="(item, index) in response.questions">
                <QuestionItem :item="item" :key="index" />
            </template>
        </div>
    </div>
</template>

<script>
import QuestionItem from "@/app/Pages/Tests/_parts/QuestionItem";

export default {
    name: 'TestContentType',
    components: {
        QuestionItem
    },
    props: ['response'],
}
</script>
