import Vue from 'vue'

// Components
import Icon from "./icon";
import SvgIcon from "./SvgIcon.vue";
import Empty from "./empty";
import SelectBox from "./select-box";
import Avatar from "./avatar";
import Zones from "./zones";
import ProductBox from "./product-box";
import FileUploads from "./file-uploads";
import AddToBasket from "./add-to-basket";
import Editor from "./editor";
import PagePagination from "./page-pagination";
import RouteDropdown from "./route-dropdown";
import Page from "./Page.vue";

Vue.component('icon', Icon);
Vue.component('svg-icon', SvgIcon);
Vue.component('empty', Empty);
Vue.component('select-box', SelectBox);
Vue.component('avatar', Avatar);
Vue.component('zones', Zones);
Vue.component('product-box', ProductBox);
Vue.component('file-uploads', FileUploads);
Vue.component('add-to-basket', AddToBasket);
Vue.component('editor', Editor);
Vue.component('page-pagination', PagePagination);
Vue.component('route-dropdown', RouteDropdown);
Vue.component('page', Page);

