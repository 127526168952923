<template>
    <div></div>
</template>

<script>
import JwtService from "@/services/jwt.service";
import {SET_ACCOUNT} from "@/store/mutations.type";

export default {
    name: 'LogoutAccount',
    mounted() {SET_ACCOUNT
        JwtService.destroyToken();
        this.$store.commit(SET_ACCOUNT, null);
        this.goRoute('/auth/login')
    }
}
</script>
