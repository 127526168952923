<template>
    <div>
        <el-button icon="mdi mdi-comment-edit-outline" plain type="primary" size="small" @click="create">
            Yeni Konu Oluştur
        </el-button>

        <el-dialog title="Yeni Konu Oluştur" :visible.sync="dialogVisible" width="60%" append-to-body
                   class="forum-add-new-topic-dialog">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <el-form :model="form" label-position="top">
                <el-form-item :class="{ 'is-error': submitted && $v.form.category_id.$error }">
                    <select-box placeholder="Kategoriler" :container="form" model="category_id"
                                :options="ui.forum_categories" />
                </el-form-item>

                <el-form-item :class="{ 'is-error': submitted && $v.form.title.$error }">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item :class="{ 'is-error': submitted && $v.form.description.$error }"
                              class="markdown-editor-form-item">
                    <editor :container="form" model="description" />
                </el-form-item>
            </el-form>

            <template slot="footer">
                <el-button type="primary" class="fluid" @click="store" :loading="loading" :disabled="loading">
                    Konu Oluştur
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {STORE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'NewTopic',
    data() {
        return {
            path: 'platform/misc/forums',
            loading: false,
            submitted: false,
            form: {
                //
            },

            dialogVisible: false,
        }
    },
    validations: {
        form: {
            category_id: {required},
            title: {required, minLength: minLength(10)},
            description: {required, minLength: minLength(10)},
        }
    },
    computed: mapGetters(['ui']),
    mounted() {
        //
    },
    methods: {
        create() {
            this.loading = false;
            this.submitted = false;
            this.dialogVisible = true;
            this.form = {
                //
            }
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;
                    this.loading = false;

                    this.goRoute('/forums/' + response.category.id + '/' + response.id)

                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
