<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/exams' }">
                            {{ $t('menu.exams') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page page-test page-exam" >
                <div class="navigation">
                    <template v-for="(item, index) in response.questions">
                        <el-tooltip :content="item.prefix + '. Soru'" placement="bottom">
                            <div class="nav" :key="index" :class="{active : navPrefix === item.prefix}"
                                 @click="handleChangeQuestion(item.prefix)"></div>
                        </el-tooltip>
                    </template>
                </div>
                <div class="page-body is-head is-footer">
                    <div class="box margin-top-20">
                        <template v-for="(item, index) in response.questions">
                            <QuestionItem :item="item" :key="index" v-if="navPrefix === item.prefix"/>
                        </template>
                    </div>
                </div>
                <FooterExam :response="response" :prev="handlePrevQuestion" :next="handleNextQuestion"/>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import QuestionItem from "@/app/Pages/Tests/_parts/QuestionItem";
import FooterExam from "@/app/Pages/Exams/_parts/Footer";
import {SET_UI_VISIBLE_PLUGIN} from "@/store/mutations.type";

export default {
    name: 'Start',
    components: {FooterExam, QuestionItem},
    data() {
        return {
            path: 'platform/student/tests',
            pageLoading: true,
            response: null,

            navPrefix: null,
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
        this.$store.commit(SET_UI_VISIBLE_PLUGIN, false)
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));

                    if (this.response.status === 'end') {
                        return this.goRoute('/exams/' + this.response.id + '/report');
                    }

                    this.navPrefix = this.response.questions[0].prefix;
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        handleChangeQuestion(prefix) {
            let navPrefix = this.navPrefix;
            this.navPrefix = prefix;

            let question = this.findQuestionByPrefix(navPrefix);

            if (!question) {
                return null;
            }

            this.$store.dispatch(UPDATE_CRUD_ITEM, {
                path: this.path,
                id: this.$route.params.id,
                question_id: question.id,
                option_id: question.selected_option_id,
                time: question.time,
                draw: question.draw
            });
        },

        findQuestionByPrefix(prefix) {
            let question = this.lodash.filter(this.response.questions, (item) => {
                return item.prefix === prefix
            });

            if (question) {
                return question[0];
            }

            return null;
        },

        handlePrevQuestion() {

            let prefix = this.navPrefix;

            if (prefix !== 1) {
                return this.handleChangeQuestion(prefix - 1)
            }
        },

        handleNextQuestion() {

            let prefix = this.navPrefix;

            if (prefix !== this.elementSize(this.response.questions)) {
                return this.handleChangeQuestion(prefix + 1)
            }
        },
    }
}
</script>
