<template>
    <div class="address-page">
        <el-row :gutter="24">
            <el-col :span="17" :xs="24">
                <div class="address-box" v-loading="pageLoading">
                    <div class="box">
                        <div class="box-head">
                            <div class="title">
                                <span>Teslimat</span> Adresim
                            </div>
                            <div class="actions">
                                <div class="add-new-address-btn" @click="create">
                                    <icon source="plus" /> Yeni adres ekle
                                </div>
                            </div>
                        </div>
                        <div class="box-body" v-if="!shippingAddress">
                            adres bulunamadı...
                        </div>
                        <div class="box-body rows" v-if="shippingAddress">
                            <div class="row" @click="changeShippingAddress = true">
                                <div class="content">
                                    <div class="media">
                                        <icon source="map-outline" />
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ shippingAddress.title }} <span>(Değiştir)</span>
                                        </div>
                                        <div class="description">
                                            {{ shippingAddress.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body" v-if="changeShippingAddress">
                            <el-row :gutter="24">
                                <template v-for="(item, index) in items(storePath(path))">
                                    <el-col :span="8" :xs="24" :key="index">
                                        <div class="address-edit-row" :class="{active : item.id === shippingAddress.id}">
                                            <div class="address-edit-row-head">
                                                <div class="status">
                                                    <icon source="check" />
                                                </div>
                                                <div class="actions">
                                                    <el-popover placement="bottom-end" width="200" trigger="click" popper-class="popover-menu">
                                                        <div slot="reference">
                                                            <el-tooltip content="İşlemler">
                                                                <div class="btn">
                                                                    <icon source="dots-vertical" />
                                                                </div>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="popover-menu-container">
                                                            <div class="popover-menu-item" @click="edit(item)">
                                                                <a>
                                                                    <div class="media">
                                                                        <icon source="square-edit-outline" />
                                                                    </div>
                                                                    <div class="text">
                                                                        {{ $t('btn_edit') }}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div class="popover-menu-item" @click="destroy(item.id)">
                                                                <a>
                                                                    <div class="media">
                                                                        <icon source="trash-can-outline" />
                                                                    </div>
                                                                    <div class="text">
                                                                        {{ $t('btn_delete') }}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </el-popover>
                                                </div>
                                            </div>
                                            <div class="address-edit-row-body" @click="handleChangeShippingAdres(item)">
                                                <div class="address-edit-row-body-title">
                                                    {{ item.title }}
                                                </div>
                                                <div class="address-edit-row-body-description">
                                                    {{ item.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </template>

                            </el-row>
                        </div>
                    </div>
                    <div class="box margin-top-20">
                        <div class="box-head">
                            <div class="title">
                                <span>Fatura</span> Adresim
                            </div>
                            <div class="actions">
                                <div class="add-new-address-btn" @click="create">
                                    <icon source="plus" /> Yeni adres ekle
                                </div>
                            </div>
                        </div>
                        <div class="box-body" v-if="!invoiceAddress">
                            adres bulunamadı...
                        </div>
                        <div class="box-body rows" v-if="invoiceAddress">
                            <div class="row" @click="changeInvoiceAddress = true">
                                <div class="content">
                                    <div class="media">
                                        <icon source="map-outline" />
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ invoiceAddress.title }} <span>(Değiştir)</span>
                                        </div>
                                        <div class="description">
                                            {{ invoiceAddress.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body" v-if="changeInvoiceAddress">
                            <el-row :gutter="24">
                                <template v-for="(item, index) in items(storePath(path))">
                                    <el-col :span="8" :xs="24" :key="index">
                                        <div class="address-edit-row" :class="{active : item.id === invoiceAddress.id}">
                                            <div class="address-edit-row-head">
                                                <div class="status">
                                                    <icon source="check" />
                                                </div>
                                                <div class="actions">
                                                    <el-popover placement="bottom-end" width="200" trigger="click" popper-class="popover-menu">
                                                        <div slot="reference">
                                                            <el-tooltip content="İşlemler">
                                                                <div class="btn">
                                                                    <icon source="dots-vertical" />
                                                                </div>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="popover-menu-container">
                                                            <div class="popover-menu-item" @click="edit(item)">
                                                                <a>
                                                                    <div class="media">
                                                                        <icon source="square-edit-outline" />
                                                                    </div>
                                                                    <div class="text">
                                                                        {{ $t('btn_edit') }}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div class="popover-menu-item" @click="destroy(item.id)">
                                                                <a>
                                                                    <div class="media">
                                                                        <icon source="trash-can-outline" />
                                                                    </div>
                                                                    <div class="text">
                                                                        {{ $t('btn_delete') }}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </el-popover>
                                                </div>
                                            </div>
                                            <div class="address-edit-row-body" @click="handleChangeInvoiceAdres(item)">
                                                <div class="address-edit-row-body-title">
                                                    {{ item.title }}
                                                </div>
                                                <div class="address-edit-row-body-description">
                                                    {{ item.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </template>
                            </el-row>
                        </div>
                    </div>
                    <div class="box margin-top-20">
                        <div class="box-footer checkout-box-footer">
                            <div class="checkout-nav-btn left" @click="goRoute('/checkout/summary')">
                                <icon source="arrow-left" />
                                <div class="txt">
                                    <span>Önceki Adıma</span> Geri Dön
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="7" :xs="24">
                <SidebarCheckout>
                    <el-button type="primary" icon="el-icon-arrow-right" class="fluid" @click="store" :loading="loading" :disabled="loading">
                        Devam Et
                    </el-button>
                </SidebarCheckout>
            </el-col>
        </el-row>

        <AddressDrawer ref="AddressDrawer" type="checkout" :index="index" :update-call="addressUpdate" :store-call="addressStore" />
    </div>
</template>

<script>
import {SET_CHECKOUT_NAVIGATION_STEP} from "@/store/mutations.type";
import SidebarCheckout from "@/app/Pages/Checkout/_parts/Sidebar";
import AddressDrawer from "@/app/Pages/Account/_parts/AddressDrawer";
import {GET_CRUD_ITEMS, STORE_CHECKOUT_ADDRESS, STORE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";
import FormResponseService from "@/services/form_response.service";

export default {
    name: 'Address',
    components: {AddressDrawer, SidebarCheckout},
    data() {
        return {
            path: 'platform/account/addresses',
            filter: {
                //
            },
            page: 1,
            pageLoading: false,

            shippingAddress: null,
            invoiceAddress: null,

            changeShippingAddress : false,
            changeInvoiceAddress : false,

            loading : false
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 2);
        this.index();
    },
    methods: {
        store() {
            if (!this.shippingAddress) {
                return FormResponseService.error({error : this.$t('checkout.delivery_address_not_selected')})
            }

            if (!this.invoiceAddress) {
                return FormResponseService.error({error : this.$t('checkout.invoice_address_not_selected')})
            }

            this.loading = true;

            this.$store
                .dispatch(STORE_CHECKOUT_ADDRESS, {delivery_address_id : this.shippingAddress.id, invoice_address_id : this.invoiceAddress.id})
                .then(() => {
                    this.loading = false;
                    this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 3);
                    this.goRoute('/checkout/payment');
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.pageLoading = false;

                    let items = this.items(this.storePath(this.path));

                    if (this.elementSize(items)) {
                        this.lodash.forEach(items, (item) => {
                            if (item.is_main) {
                                this.shippingAddress = item;
                                this.invoiceAddress = item;
                            }
                        })
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create() {
            this.$refs.AddressDrawer.create();
        },

        addressStore(response) {
            this.shippingAddress = response;
            this.invoiceAddress = response;
        },

        edit(item) {
            this.$refs.AddressDrawer.edit(item.updated);
        },

        addressUpdate(response) {
            console.log('address update');
        },

        destroy(id) {
            this.$refs.AddressDrawer.destroy(id);
        },

        handleChangeShippingAdres(item) {
            this.shippingAddress = item;
            //this.changeShippingAddress = false;
        },

        handleChangeInvoiceAdres(item) {
            this.invoiceAddress = item;
            //this.changeInvoiceAddress = false;
        },
    }
}
</script>
