import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Books/Index";
import Show from "@/app/Pages/Books/Show";
import Unit from "@/app/Pages/Books/Unit";
import Section from "@/app/Pages/Books/Section";
import Exercise from "@/app/Pages/Books/Exercise";

export default {
    path: '/books',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Kitaplar',
    },
    children: [
        {
            name: 'app.books.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Kitaplar',
            },
        },
        {
            name: 'app.books.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Kitap',
            },
            children: [
                {
                    name: 'app.books.exercises.show',
                    path: 'units/:unitId/sections/:sectionId/exercises/:exerciseId',
                    component: Exercise,
                    meta: {
                        auth: true,
                        title: 'Alistirmalar',
                    },
                },
                {
                    name: 'app.books.sections.show',
                    path: 'units/:unitId/sections/:sectionId',
                    component: Section,
                    meta: {
                        auth: true,
                        title: 'Bolumler',
                    },
                },
                {
                    name: 'app.books.units.show',
                    path: 'units/:unitId',
                    component: Unit,
                    meta: {
                        auth: true,
                        title: 'Uniteler',
                    },
                },
            ]
        },

    ]
}
