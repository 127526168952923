<template>
    <div class="page-footer">
        <div class="column start">
            <div class="metas">
                <div class="meta">Kalan Süre:</div>
                <div class="meta time">
                    <i class="el-icon-time"></i> <span class="time">{{ formatSecondTime(timer.time) }}</span>
                </div>
            </div>
        </div>
        <div class="column center nav">
            <el-tooltip content="Önceki Soru" placement="top">
                <div class="nav-btn left" @click="handlePrevQuestion">
                    <icon source="chevron-left" />
                </div>
            </el-tooltip>
            <el-tooltip content="Kısa Bir Ara" placement="top">
                <div class="nav-btn center" @click="handleToggleTime">
                    <icon source="pause" v-if="timer.isPlay" />
                    <icon source="play" v-if="!timer.isPlay" />
                </div>
            </el-tooltip>
            <el-tooltip content="Sonraki Soru" placement="top">
                <div class="nav-btn right" @click="handleNextQuestion">
                    <icon source="chevron-right" />
                </div>
            </el-tooltip>
        </div>
        <div class="column end" >
            <div class="metas cursor-pointer" @click="handleEndDialogVisible">
                <div class="meta"><icon source="check" /></div>
                <div class="meta">Tamamla</div>
            </div>
        </div>

        <el-dialog :visible.sync="dialog.toggle" width="40%" :before-close="handleToggleTime" class="confirm-dialog no-header" :close-on-press-escape="false" append-to-body>
            <div class="body">
                <div class="text" v-loading="dialog.loading">
                    <div class="text-wrp">
                        <div class="media">
                            <img src="/img/illustration/test-confirm.svg" alt="cover">
                        </div>
                        <div class="title">
                            Denemeye Kısa Bir Ara
                        </div>
                        <div class="description">
                            Anlaşılan kısa bir araya ihtiyacın vardı. Sen geri dönene kadar
                            her şey olduğu gibi seni bekleyecek.
                        </div>
                    </div>
                </div>

                <div class="actions center">
                    <el-button type="primary" @click="handleToggleTime">
                        Denemeye Devam Et
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialog.end" width="40%" :before-close="handleEndDialogClose" class="confirm-dialog no-header" :close-on-press-escape="false" append-to-body>
            <div class="body">
                <div class="text" v-loading="dialog.loading">
                    <div class="text-wrp">
                        <div class="media">
                            <img src="/img/illustration/test-confirm.svg" alt="cover">
                        </div>
                        <div class="title">
                            Sınavı Tamamlıyoruz
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <el-button type="primary" @click="handleEndDialogClose">
                        Devam Et
                    </el-button>
                    <el-button type="primary" @click="goRoute('/exams')">
                        Sonra Devam Et
                    </el-button>
                    <el-button type="primary" @click="handleEnd">
                        Bitir
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'FooterExam',
    props: ['response', 'prev', 'next'],
    data() {
        return {
            path: 'platform/student/tests',
            timer: {
                time: 0,
                isPlay: true,
                run: null,
                store: null,
            },

            pauseDialogVisible: false,
            endDialogVisible: false,

            dialog: {
                loading: false,
                end: false,
                toggle: false,
            }
        }
    },
    mounted() {
        this.timer.time = this.response.duration;

        this.down();
        //this.save();
    },
    methods: {
        down() {
            this.timer.run = setInterval(() => {

                if (!this.$route.params.id) {
                    return clearInterval(this.timer.run);
                }

                if (this.$route.params.id === undefined) {
                    return clearInterval(this.timer.run);
                }

                if (this.timer.isPlay) {
                    this.timer.time--;
                }
            }, 1000)
        },

        save() {
            this.timer.store = setInterval(() => {
                if (!this.$route.params.id) {
                    return clearInterval(this.timer.store);
                }

                if (this.$route.params.id === undefined) {
                    return clearInterval(this.timer.store);
                }

                this.$store.dispatch(UPDATE_CRUD_ITEM, {
                    path: this.path,
                    id: this.$route.params.id,
                    process: 'time',
                    time: this.timer.time
                });
            }, 30000);
        },

        handleEndDialogVisible() {
            this.timer.isPlay = false;
            this.dialog.end = true;
        },

        handleEndDialogClose() {
            this.timer.isPlay = true;
            this.dialog.end = false;
        },

        handleEnd() {
            this.$store.dispatch(UPDATE_CRUD_ITEM, {
                path: this.path,
                id: this.$route.params.id,
                process: 'end-or-next',
                time: this.timer.time
            }).then((response) => {
                if (response.id) {
                    return this.goRoute('/leveling-exams/' + response.id)
                }

                return this.goRoute('/leveling-exams/' + this.$route.params.id + '/report')
            })
        },

        handlePrevQuestion() {
            this.prev();
        },

        handleNextQuestion() {
            this.next();
        },

        handleToggleTime() {
            this.timer.isPlay = !this.timer.isPlay;
            this.dialog.toggle = ! this.dialog.toggle;
        }
    }
}
</script>
