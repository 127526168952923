<template>
    <div class="column-wrp">
        <div class="status-text">
            {{ $t('auth.dont_have_account') }}
            <router-link to="/auth/register" class="inline-link">
                {{ $t('btn_register') }}!
            </router-link>
        </div>
        <div class="head">{{ $t('misc.welcome') }}</div>
        <el-form :model="form" label-position="top" ref="AuthForm" @submit.native.prevent="store">
            <el-form-item :label="$t('inputs.your_email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                <el-input v-model="form.email" type="email" prefix-icon="el-icon-message"
                          :placeholder="$t('inputs.your_email')"/>
            </el-form-item>
            <el-form-item :label="$t('inputs.your_password')"
                          :class="{ 'is-error': submitted && $v.form.password.$error }">
                <el-input type="password" v-model="form.password" prefix-icon="el-icon-key"
                          :placeholder="$t('inputs.your_password')" show-password/>
            </el-form-item>
            <div class="flex-between">
                <div>
                    <el-checkbox v-model="form.remember_me" :label="$t('auth.remember_me')" class="no-margin"/>
                </div>
                <div>
                    <router-link to="/auth/forgot-password" class="inline-link">
                        <icon source="fish"/>
                        {{ $t('auth.forgot_password') }}
                    </router-link>
                </div>
            </div>
            <div class="margin-top-40">
                <el-button native-type="submit" class="fluid" type="primary" :loading="loading" :disabled="loading">
                    {{ $t('btn_login') }}
                </el-button>
            </div>

            <div class="or">
                <div class="or-text">{{ $t('misc.or') }}</div>
            </div>

            <div class="register">
                <router-link to="/auth/register">
                    {{ $t('btn_register') }}!
                </router-link>
            </div>

            <div class="social-login">
                <el-button class="fluid social facebook" plain icon="mdi mdi-facebook">
                    {{ $t('btn_facebook_login') }}
                </el-button>

                <el-button class="fluid social google margin-top-20" plain icon="mdi mdi-google">
                    {{ $t('btn_google_login') }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {required, email, minLength, maxLength} from "vuelidate/lib/validators";
import {STORE_LOGIN} from "@/store/actions.type";
import {SET_PRELOADING} from "@/store/mutations.type";
import JwtService from '@/services/jwt.service';
import {mapGetters} from "vuex";

export default {
    name: 'LoginAuth',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                language: null
            },
        }
    },
    validations: {
        form: {
            email: {required, email},
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
        }
    },
    computed: mapGetters(['ui']),
    mounted() {
        if (this.$route.query.token) {
            this.redirectLogin();
        }
    },
    methods: {
        store() {

            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(STORE_LOGIN, this.form)
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        redirectLogin() {
            JwtService.storeToken(this.$route.query.token);
            this.$store.commit(SET_PRELOADING, true);

            setTimeout(() => {
                if (this.$route.query.redirect) {
                    window.location = '#/' + this.$route.query.redirect;
                } else {
                    window.location = '#/dashboard';
                }
            }, 500)
        },

        handleChangeLanguage(event) {
            if (!event) {
                return;
            }

            this.$i18n.locale = event;

            localStorage.setItem('language', event);
        }
    }
}
</script>
