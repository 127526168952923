<template>
    <div>
        <el-row :gutter="24">
            <el-col :span="12" :xs="24">
                <div class="box">
                    <div class="box-head">
                        <div class="title">{{ $t('account.my_profile') }}</div>
                    </div>
                    <div class="box-body">
                        <el-form :model="form" label-position="top" @submit.native.prevent="store">
                            <el-form-item :label="$t('inputs.username')" :class="{ 'is-error': submitted && $v.form.name.$error }">
                                <el-input v-model="form.name" prefix-icon="el-icon-user" :placeholder="$t('inputs.username')" />
                            </el-form-item>

                            <el-form-item :label="$t('inputs.email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                                <el-input v-model="form.email" prefix-icon="el-icon-message" :placeholder="$t('inputs.email')" type="email" :disabled="true" />
                            </el-form-item>

                            <el-form-item :label="$t('inputs.phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }">
                                <el-input v-model="form.phone" prefix-icon="el-icon-phone-outline" :placeholder="$t('inputs.phone')" v-mask="'0 (###) ### ## ##'" />
                            </el-form-item>

                            <el-form-item :label="$t('inputs.birthday')" :class="{ 'is-error': submitted && $v.form.birthday.$error }" class="margin-bottom-0">
                                <el-date-picker v-model="form.birthday" type="date" :placeholder="$t('inputs.birthday')" value-format="yyyy-MM-dd"/>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="box-footer">
                        <el-button class="fluid" type="primary" icon="el-icon-check" :loading="loading" :disabled="loading" @click="update">
                            {{ $t('btn_update') }}
                        </el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" :xs="24">
                <PasswordAccount class="mobile-margin-top-40" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {email, required, minLength, maxLength} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {UPDATE_ACCOUNT} from "@/store/actions.type";
import PasswordAccount from "@/app/Pages/Account/Password";

export default {
    name: 'MeAccount',
    components: {PasswordAccount},
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            }
        }
    },
    validations: {
        form: {
            name: {required},
            email: {required, email},
            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
            birthday: {required},
        }
    },
    computed: mapGetters(['account']),
    mounted() {
        this.form = this.cloneObjectData(this.account);
    },
    methods: {
        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(UPDATE_ACCOUNT, this.form)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
