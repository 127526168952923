<template>
    <div class="column-wrp">
        <div class="status-text">
            {{ $t('auth.have_account') }}

            <router-link to="/auth/login" class="inline-link">
                {{ $t('btn_login') }}!
            </router-link>

        </div>
        <div class="head">{{ $t('misc.welcome') }}</div>

        <el-form :model="form" label-position="top" ref="AuthForm" @submit.native.prevent="store">
            <el-form-item :label="$t('inputs.username')" :class="{ 'is-error': submitted && $v.form.name.$error }">
                <el-input v-model="form.name" prefix-icon="el-icon-user" :placeholder="$t('inputs.username')" />
            </el-form-item>

            <el-form-item :label="$t('inputs.your_email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                <el-input v-model="form.email" type="email" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_email')" />
            </el-form-item>

            <el-form-item :label="$t('inputs.your_password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                <el-input type="password" v-model="form.password" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_password')" show-password />
            </el-form-item>

            <el-form-item :label="$t('inputs.your_password_repeat')" :class="{ 'is-error': submitted && $v.form.password_confirmation.$error }">
                <el-input type="password" v-model="form.password_confirmation" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_password_repeat')" show-password />
            </el-form-item>

            <el-form-item :class="{ 'is-error': submitted && $v.form.contract.$error }">
                <div class="checkbox-capsule">
                    <el-checkbox v-model="form.contract"/>
                    <div class="checkbox-text">
                        Önemli kampanyalardan haberdar olmak için <span @click="contractDialog('acik_riza')" class="link">Rıza Metni</span> kapsamında elektronik ileti almak istiyorum.
                    </div>
                </div>
            </el-form-item>

            <el-button native-type="submit" class="fluid margin-top-20" type="primary" :loading="loading" :disabled="loading">
                {{ $t('btn_register') }}
            </el-button>

            <div class="auth-info-message margin-top-20">
                Kişisel verileriniz, <span class="link" @click="contractDialog('aydinlatma')">Aydınlatma Metni</span> kapsamında işlenmektedir. <b>“Üye ol”</b> veya <b>“Sosyal Hesap”</b> butonlarından birine basarak
                <span class="link" @click="contractDialog('membership')">Üyelik Sözleşmesi</span>’ni, <span class="link" @click="contractDialog('privacy')">Gizlilik Sözleşmesi</span>’ni, ve <span class="link" @click="contractDialog('acik_riza')">Rıza Metni</span>’ni, okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
            </div>

            <div class="or">
                <div class="or-text">{{ $t('misc.or') }}</div>
            </div>

            <div class="social-login">
                <el-button class="fluid social facebook" plain icon="mdi mdi-facebook">
                    {{ $t('btn_facebook_register') }}
                </el-button>

                <el-button class="fluid social google margin-top-20" plain icon="mdi mdi-google">
                    {{ $t('btn_google_register') }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {STORE_REGISTER} from "@/store/actions.type";

export default {
    name: 'RegisterAuth',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {

            },
        }
    },
    validations: {
        form: {
            name: {required, minLength: minLength(3), maxLength: maxLength(100)},
            email: {required, email, maxLength: maxLength(191)},
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password_confirmation: {required, sameAsPassword: sameAs('password')},
            contract: {required},
        }
    },
    computed: mapGetters(['ui']),
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(STORE_REGISTER, this.form)
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
