<template>
    <div class="column-wrp">
        <div class="head">
            <div class="title">{{ $t('auth.forgot_password') }}</div>
            <div class="description">Kayıt olduğunuz e-posta adresini girerek şifre sıfırlama talebinde bulunabilirsiniz.</div>
        </div>
        <el-form :model="form" label-position="top" ref="AuthForm" @submit.native.prevent="store">
            <el-form-item :class="{ 'is-error': submitted && $v.form.email.$error }">
                <el-input v-model="form.email" type="email" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_email')" />
            </el-form-item>

            <el-button native-type="submit" class="fluid" type="primary" :loading="loading" :disabled="loading">
                {{ $t('btn_send') }}
            </el-button>
        </el-form>
    </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {STORE_FORGOT_PASSWORD} from "@/store/actions.type";

export default {
    name: 'ForgotPasswordAuth',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            },
        }
    },
    validations: {
        form: {
            email: {required, email},
        }
    },
    computed: mapGetters(['ui']),
    methods: {
        store() {

            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.process = 'platform'

            this.$store
                .dispatch(STORE_FORGOT_PASSWORD, this.form)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
