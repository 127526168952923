<template>
    <div class="body-wrp checkout-page" v-loading="pageLoading" v-if="ui">
        <header id="Header">
            <div class="header">
                <el-container>
                    <div class="header-wrp">
                        <div class="logo">
                            <router-link to="/dashboard">
                                <img :src="publicFilePath(ui.view.logo_light)" alt="logo">
                            </router-link>
                        </div>
                        <div class="hero">
                            <div class="navigation" :class="'size-' + step">
                                <div class="item" :class="{active : step === 1, completed : step > 1}">
                                    <div class="media">
                                        <el-tooltip content="Sepet Bilgileriniz">
                                            <div class="media-wrp">
                                                <template v-if="step > 1">
                                                    <icon source="check" />
                                                </template>
                                                <template v-if="step <= 1">
                                                    1
                                                </template>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="item" :class="{active : step === 2, completed : step > 2}">
                                    <div class="media">
                                        <el-tooltip content="Adres Bilgileriniz">
                                            <div class="media-wrp">
                                                <template v-if="step > 2">
                                                    <icon source="check" />
                                                </template>
                                                <template v-if="step <= 2">
                                                    2
                                                </template>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="item" :class="{active : step === 3, completed : step > 3}">
                                    <div class="media">
                                        <el-tooltip content="Ödeme Bilgileriniz">
                                            <div class="media-wrp">
                                                <template v-if="step > 3">
                                                    <icon source="check" />
                                                </template>
                                                <template v-if="step <= 3">
                                                    3
                                                </template>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="item" :class="{active : step === 4, completed : step >= 4}">
                                    <div class="media">
                                        <el-tooltip content="Siparişiniz Tamamlandı">
                                            <div class="media-wrp">
                                                <template v-if="step >= 4">
                                                    <icon source="check" />
                                                </template>
                                                <template v-if="step < 4">
                                                    4
                                                </template>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-wrp header-mobile-wrp">
                        <div class="logo">
                            <router-link to="/dashboard">
                                <img :src="publicFilePath(ui.view.logo_light)" alt="logo">
                            </router-link>
                        </div>
                        <div class="hero">
                        <div class="navigation" :class="'size-' + step">
                            <div class="item" :class="{active : step === 1, completed : step > 1}">
                                <div class="media">
                                    <el-tooltip content="Sepet Bilgileriniz">
                                        <div class="media-wrp">
                                            <template v-if="step > 1">
                                                <icon source="check" />
                                            </template>
                                            <template v-if="step <= 1">
                                                1
                                            </template>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="text">Sepet Bilgileriniz</div>
                            </div>
                            <div class="item" :class="{active : step === 2, completed : step > 2}">
                                <div class="media">
                                    <el-tooltip content="Adres Bilgileriniz">
                                        <div class="media-wrp">
                                            <template v-if="step > 2">
                                                <icon source="check" />
                                            </template>
                                            <template v-if="step <= 2">
                                                2
                                            </template>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="text">Adres Bilgileriniz</div>
                            </div>
                            <div class="item" :class="{active : step === 3, completed : step > 3}">
                                <div class="media">
                                    <el-tooltip content="Ödeme Bilgileriniz">
                                        <div class="media-wrp">
                                            <template v-if="step > 3">
                                                <icon source="check" />
                                            </template>
                                            <template v-if="step <= 3">
                                                3
                                            </template>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="text">Ödeme Bilgileriniz</div>
                            </div>
                            <div class="item" :class="{active : step === 4, completed : step >= 4}">
                                <div class="media">
                                    <el-tooltip content="Siparişiniz Tamamlandı">
                                        <div class="media-wrp">
                                            <template v-if="step >= 4">
                                                <icon source="check" />
                                            </template>
                                            <template v-if="step < 4">
                                                4
                                            </template>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="text">Siparişiniz Tamamlandı</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </el-container>
            </div>
        </header>
        <main id="Main">
            <el-container>
                <div class="main-wrp">
                    <div class="page">
                        <div class="page-wrp">
                            <div class="page-content">
                                <div class="page-body">
                                    <vue-page-transition>
                                        <router-view />
                                    </vue-page-transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-container>
        </main>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AccountHeader from "@/app/Layouts/Parts/Account";
import {GET_CHECKOUT_SUMMARY} from "@/store/actions.type";

export default {
    name: 'CheckoutLayout',
    components: {AccountHeader},
    data() {
        return {
            pageLoading : false
        }
    },
    computed: mapGetters(['ui', 'step', 'summary']),
    mounted() {
        this.pageLoading = true;

        this.$store
            .dispatch(GET_CHECKOUT_SUMMARY)
            .then(() => {
                this.pageLoading = false;
            })
            .catch(() => {
                this.pageLoading = false;
            })
    }
}
</script>
