<template>
    <div class="wordy-app">
        <div class="app-type-image-intro">
            <div class="head">
                <div class="title">Sunum Sona Erdi!</div>
                <div class="description"> {{ elementSize(response.words) }} Adet Kelime Gösterildi </div>
            </div>
            <div class="box word-list-rows">
                <div class="box-body overflow-400">
                    <template v-for="(word, index) in response.words">
                        <div class="item" :key="index">
                            <audio :ref="'AudioPlayer' + index" controls :src="word.media.sound" style="display: none"></audio>
                            <div class="content">
                                <div class="medias">
                                    <el-tooltip content="Dinle">
                                        <div class="media" @click="listen(word, index)">
                                            <icon source="volume-high" />
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip content="İzle">
                                        <div class="media" v-if="word.media.video" @click="watch(word, index)">
                                            <icon source="play" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="text">
                                    <div class="titles">
                                        <div class="name">{{ word.title }}</div>
                                        <div class="type">{{ word.type.title }}</div>
                                    </div>
                                    <div class="metas">
                                        <div class="meta cursor-pointer">
                                            {{ word.translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="footer text-center margin-top-40">
                <el-button class="app-btn" type="error" @click="goRoute('/word-lists')">
                    {{ $t('btn_close') }}
                </el-button>
                <el-button class="app-btn" type="primary" @click="next('intro')">
                    {{ $t('btn_repeat') }}
                </el-button>
            </div>
        </div>

        <el-dialog :visible.sync="watchDialogVisible" width="50%" append-to-body class="video-dialog">
            <div class="dialog-global-close-btn" @click="watchDialogVisible = false">
                <i class="el-icon-close"></i>
            </div>
            <div v-if="activeWord">
                <video :src="activeWord.media.video" controls controlsList="nodownload" oncontextmenu="return false;"></video>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'IntroImageApp',
    props: ['response', 'next'],
    data() {
        return {
            watchDialogVisible: false,
            activeWord : null
        }
    },
    mounted() {
        //
    },
    methods: {
        listen(word, index) {
            if (!word.media.sound) {
                return window.speechSynthesis.speak(new SpeechSynthesisUtterance(word.title));
            }

            this.$refs['AudioPlayer' + index][0].play()
        },

        watch(word) {
            this.watchDialogVisible = true;
            this.activeWord = word;
        },
    }
}
</script>
