<template>
    <div class="error-page error-page-404">
        <div class="wrapper">
            <div class="container">
                <div class="content">
                    <div class="code">503</div>
                    <div class="message">{{ $t('error.maintenance_title') }}</div>
                    <div class="buttons">
                        <el-button size="large flat dark secondary" style="padding: 15px 30px !important" @click="$router.push('/')">
                            {{ $t('btn_login') }}
                        </el-button>
                    </div>
                </div>
                <div class="media">
                    <img src="/img/errors/maintenance.svg" alt="error">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Maintenance',
    mounted() {
        localStorage.removeItem('auth_token')
    }
}
</script>
