<template>
    <page title="Sözlük">
        <div class="page dictionary-page-wrp">
            <div class="dictionary-page">
                <DictionaryHead />

                <div class="dictionary-page-detail" v-loading="pageLoading" :key="$route.fullPath">
                    <empty alias="dictionary" title="dictionary" v-if="!pageLoading && !response" />

                    <div class="word" v-if="response" :key="response.title">

                        <audio ref="AudioPlayer" controls :src="response.media.sound" style="display: none"></audio>

                        <div class="word-head">
                            <div class="medias">
                                <el-tooltip content="Dinle">
                                    <div class="media" @click="listen">
                                        <icon source="volume-high" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip content="İzle">
                                    <div class="media" v-if="response.media.video" @click="watch">
                                        <icon source="play" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="text">
                                <div class="titles">
                                    <div class="name">{{ response.title }}</div>
                                    <div class="type" v-if="response.type.title">{{ response.type.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="word-body">
                            <div class="translate">{{ response.translation }}</div>
                            <template v-for="(sentence, sentenceIndex) in response.sentences">
                                <div class="sentence" :key="sentenceIndex">{{ sentence }}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog :visible.sync="watchDialogVisible" width="50%" append-to-body class="video-dialog">
                <div class="dialog-global-close-btn" @click="watchDialogVisible = false">
                    <i class="el-icon-close"></i>
                </div>
                <div v-if="response">
                    <video :src="response.media.video" controls controlsList="nodownload" oncontextmenu="return false;"></video>
                </div>
            </el-dialog>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import DictionaryHead from "@/app/Pages/Dictionary/_parts/Head";

export default {
    name: 'Show',
    components: {DictionaryHead},
    data() {
        return {
            path: 'platform/student/dictionary',
            pageLoading: true,
            response: null,

            watchDialogVisible: false,
        }
    },
    computed: mapGetters(['item']),
    watch: {
        '$route.params.id': {
            handler: function(search) {
                this.response = null;
                this.show();
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.show()
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id, directory : this.$route.query.directory})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        listen() {
            if (!this.response.media.sound) {
                return window.speechSynthesis.speak(new SpeechSynthesisUtterance(this.response.title));
            }

            return this.$refs.AudioPlayer.play()
        },

        watch() {
            this.watchDialogVisible = true;
        },
    }
}
</script>
