<template>
    <el-dialog :visible.sync="dialogVisible" class="lesson-live-dialog" append-to-body fullscreen>
        <div class="page-loading" style="min-height: 400px" v-loading="pageLoading">
            <template v-if="response">
                <iframe allow="encrypted-media; camera; microphone" :src="response.access_link" allowfullscreen="allowfullscreen" frameborder="0" v-if="!response.password"></iframe>
                <div class="live-box" v-if="response.password">
                    <div class="zoom-box">
                        <div class="box-title">Zoom Giriş Bilgileriniz</div>
                        <div class="box-line">
                            <div class="name">Id:</div>
                            <div class="description">{{ response.service_id }}</div>
                        </div>
                        <div class="box-line">
                            <div class="name">Şifre:</div>
                            <div class="description">{{ response.password }}</div>
                        </div>
                        <div class="box-action">
                            <a :href="response.access_link" target="_blank" class="el-button el-button--primary el-button--large">
                                <span>Zoom Üzerinden Derse Katılmak İçin Buraya Tıklayın</span>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </el-dialog>
</template>

<script>
import {GET_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'Live',
    data() {
        return {
            path: 'platform/student/lessons',
            pageLoading: true,
            dialogVisible : true,
            response : null
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();

        this.$message({
            message : 'Klavyenizden "ESC" tuşuna basarak ekranı kapatabilirsiniz.',
            duration : 3000
        });

        $(document).keyup(function(e) {
            if (e.key === 'Escape') {

                if (this.response) {
                    window.location = '/#/lessons/' + this.response.id
                } else {
                    window.location = '/#/lessons'
                }
            }
        })
    },
    methods : {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id, isLive: true})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        }
    }
}
</script>
