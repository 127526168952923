<template>
    <el-popover ref="AppMenu" :placement="isSidebar ? 'right-start' : 'bottom-start'" width="460" trigger="click" popper-class="app-menu" append-to-body>
        <div :class="isSidebar ? 'item sidebar-apps-item' : 'app-menu-main-item'" slot="reference">
            <template v-if="isSidebar">
                <el-tooltip content="Uygulamalar" placement="right">
                    <icon source="apps" />
                </el-tooltip>
            </template>
            <template v-if="!isSidebar">
                <icon source="apps" /> <span>{{ title ? title : 'Uygulamalar' }}</span>
            </template>
        </div>
        <div class="items" @click="handleVisible">
            <el-row :gutter="24">
                <template v-for="(menu, index) in menus">
                    <el-col :span="8" :xs="12" :key="index" v-if="ui.permissions['has_access_' + menu.name]">
                        <router-link :to="'/' + menu.route" class="item" :class="'app-' + menu.name" >
                            <div class="media">
                                <svg-icon :src="menu.icon" />
                            </div>
                            <div class="title">{{ $t('menu.' + menu.name) }}</div>
                        </router-link>
                    </el-col>
                </template>
            </el-row>
        </div>
    </el-popover>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'AppMenu',
    props : ['title', 'isSidebar'],
    computed: mapGetters(['menus', 'ui']),
    methods: {
        handleVisible() {

            if (!this.$refs.AppMenu) {
                return
            }

            this.$refs.AppMenu.doClose();
        }
    }
}
</script>
