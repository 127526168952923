<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/misc/announcements' }">
                            {{ $t('menu.announcements') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page">
                <div class="page-body is-head">
                    <div class="box">
                        <div class="box-body">
                            <div v-html="response.description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Show',
    data() {
        return {
            path: 'platform/student/misc/announcements',
            pageLoading: true,
            response: null
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },
    }
}
</script>
