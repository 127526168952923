<template>
    <div class="payment-bank">
        <div class="message-box" v-if="plugin.alert" v-html="plugin.alert"></div>
        <el-collapse v-model="item" accordion>
            <template v-for="(bank, index) in plugin.rows">
                <el-collapse-item :name="bank.title" :key="index">
                    <template slot="title">
                        <div class="head">
                            <div class="media">
                                <img :src="publicFilePath(bank.image)" alt="bank">
                            </div>
                            <div class="title">{{ bank.title }}</div>
                        </div>
                    </template>
                    <div class="bank-detail">
                        <div class="bank-item-row">
                            <div class="name">IBAN</div>
                            <div class="description">{{ bank.iban }}</div>
                        </div>
                        <div class="bank-item-row">
                            <div class="name">Hesap No</div>
                            <div class="description">{{ bank.account }}</div>
                        </div>
                        <div class="bank-item-row">
                            <div class="name">Şube</div>
                            <div class="description">{{ bank.branch }}</div>
                        </div>
                        <div class="bank-item-row">
                            <div class="name">Alıcı Adı</div>
                            <div class="description">{{ bank.recipient_name }}</div>
                        </div>
                    </div>
                </el-collapse-item>
            </template>
        </el-collapse>
    </div>
</template>

<script>
import {SET_CHECKOUT_INSTALLMENT} from "@/store/mutations.type";

export default {
    name: 'BankPaymentMethod',
    props : ['plugin', 'summary'],
    data() {
        return {
            item : null,
        }
    },
    mounted() {
        this.$store.commit(SET_CHECKOUT_INSTALLMENT, null);
        this.summary();
    },
    methods: {
        validation() {
            if (!this.item) {
                this.$message({
                    type: 'error',
                    message: 'Devam edebilmek için lütfen banka seçimi yapınız.'
                });

                return false;
            }

            return {
                type : this.plugin.name,
                payment_plugin_id : this.plugin.id,
                bank : this.item,
            }
        }
    }
}
</script>
