<template>
    <div class="item">

        <audio ref="AudioPlayer" controls :src="item.media.sound" style="display: none"></audio>

        <div class="content">
            <div class="medias">
                <el-tooltip content="Dinle">
                    <div class="media" @click="listen">
                        <icon source="volume-high" />
                    </div>
                </el-tooltip>
                <el-tooltip content="İzle">
                    <div class="media" v-if="item.media.video" @click="watch">
                        <icon source="play" />
                    </div>
                </el-tooltip>
            </div>
            <div class="text">
                <div class="titles">
                    <div class="name">{{ item.title }}</div>
                    <div class="type">{{ item.type.title }}</div>
                </div>
                <div class="metas">
                    <el-tooltip content="Kelimenin Ornek Cumleleri">
                        <div class="meta cursor-pointer" @click="sentenceDialogVisible = true">
                            {{ item.translate }}
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="word-media" v-if="item.media.image">
                <img :src="item.media.image" alt="image">
            </div>
            <el-tooltip content="Seviye: Kolay">
                <div class="word-rates" v-if="item.rate">
                    <template v-for="(rate, rateIndex) in 4">
                        <div class="rate" :class="{active : rateIndex < 2}" :key="rateIndex"></div>
                    </template>
                </div>
            </el-tooltip>
        </div>

        <el-dialog :visible.sync="watchDialogVisible" width="50%" append-to-body class="video-dialog">
            <div class="dialog-global-close-btn" @click="watchDialogVisible = false">
                <i class="el-icon-close"></i>
            </div>
            <video :src="item.media.video" controls controlsList="nodownload" oncontextmenu="return false;"></video>
        </el-dialog>

        <el-dialog :visible.sync="sentenceDialogVisible" width="40%" append-to-body class="no-header word-meaning-dialog">
            <div class="dialog-global-close-btn" @click="meaningDialogVisible = false">
                <i class="el-icon-close"></i>
            </div>
            <div class="word-list-rows">
                <div class="item">
                    <div class="content">
                        <div class="medias">
                            <el-tooltip content="Dinle">
                                <div class="media" v-if="item.media.sound" @click="listen">
                                    <icon source="volume-high" />
                                </div>
                            </el-tooltip>
                            <el-tooltip content="İzle">
                                <div class="media" v-if="item.media.video" @click="watch">
                                    <icon source="play" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="text">
                            <div class="titles">
                                <div class="name">{{ item.title }}</div>
                                <div class="type">{{ item.type.title }}</div>
                            </div>
                            <div class="metas">
                                <div class="meta">
                                    {{ item.translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="meaning-rows">
                <template v-for="(sentence, index) in item.sentences">
                    <div class="item" :key="index">
                        <div class="text">
                            {{ sentence }}
                        </div>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Word',
    props: ['item'],
    data() {
        return {
            sentenceDialogVisible: false,
            watchDialogVisible: false,
        }
    },
    mounted() {
        //
    },
    methods: {
        listen() {
            if (!this.item.media.sound) {
                return window.speechSynthesis.speak(new SpeechSynthesisUtterance(this.item.title));
            }

            this.$refs.AudioPlayer.play()
        },

        watch() {
            this.watchDialogVisible = true;
        },
    }
}
</script>
