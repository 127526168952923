<template>
    <div class="box shop-page-route-dropdown" v-if="active">
        <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
            <span class="el-dropdown-link">
                <span class="media">
                    <icon :source="active.icon"></icon>
                </span>
                <span class="content">
                    <span class="title">
                        {{ !active.lang ? active.title : $t(active.lang) }}
                    </span>
                    <span class="action">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                </span>
            </span>
            <el-dropdown-menu slot="dropdown" class="route-dropdown-popover">
                <template v-for="(item, index) in items">
                    <el-dropdown-item :command="{item : item}" :key="index">
                        <div class="media">
                            <icon :source="item.icon"></icon>
                        </div>
                        <div class="text">
                            {{ !item.lang ? item.title : $t(item.lang) }}
                        </div>
                    </el-dropdown-item>
                </template>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: 'RouteDropdown',
    props: ['items'],
    data() {
        return {
            active : null,
        }
    },
    mounted() {
        this.lodash.forEach(this.items, (item) => {
            if (item.slug && item.slug === this.$route.params.slug) {
                return this.active = item;
            }

            if (item.link && item.link === this.$route.path) {
                return this.active = item;
            }
        })
    },
    methods: {
        handleCommand(command) {
            this.active = command.item;

            if (this.active.slug) {
                return this.goRoute('/shop/' + this.active.slug);
            }

            if (this.active.link) {
                return this.goRoute(this.active.link);
            }
        }
    },
};
</script>
