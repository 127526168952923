<template>
    <div class="filter-item">
        <el-popover placement="bottom-end" width="400" trigger="click" append-to-body popper-class="filter-list-popover">
            <div class="action" slot="reference">
                <div class="title">
                    <template v-if="tab === 'all'">
                        {{ $t(type + '.filter.all') }}
                    </template>
                    <template v-if="tab === 'passive'">
                        {{ $t(type + '.filter.passive') }}
                    </template>
                    <template v-if="tab === 'active'">
                        {{ $t(type + '.filter.active') }}
                    </template>
                </div>
                <div class="media">
                    <i class="icon el-icon-arrow-down"></i>
                </div>
            </div>
            <div class="items">
                <div class="item" @click="handleSelectedTab('all')" :class="{active : tab === 'all'}">
                    <div class="media">
                        <icon source="menu" />
                    </div>
                    <div class="text">
                        <div class="title">{{ $t(type + '.filter.all') }}</div>
                    </div>
                </div>
                <div class="item" @click="handleSelectedTab('passive')"
                     :class="{active : tab === 'passive'}">
                    <div class="media">
                        <i class="icon el-icon-time"></i>
                    </div>
                    <div class="text">
                        <div class="title">{{ $t(type + '.filter.passive') }}</div>
                    </div>
                </div>
                <div class="item" @click="handleSelectedTab('active')" :class="{active : tab === 'active'}">
                    <div class="media">
                        <i class="icon el-icon-date"></i>
                    </div>
                    <div class="text">
                        <div class="title">{{ $t(type + '.filter.active') }}</div>
                    </div>
                </div>
            </div>
        </el-popover>

        <div class="action action-mobile" @click="dialogVisible = true">
            <div class="title">
                <template v-if="tab === 'all'">
                    {{ $t(type + '.filter.all') }}
                </template>
                <template v-if="tab === 'passive'">
                    {{ $t(type + '.filter.passive') }}
                </template>
                <template v-if="tab === 'active'">
                    {{ $t(type + '.filter.active') }}
                </template>
            </div>
            <div class="media">
                <i class="icon el-icon-arrow-down"></i>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="100%" append-to-body class="page-filter-item-dialog no-header">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <div class="filter-list-popover">
                <div class="items">
                    <div class="item" @click="handleSelectedTab('all')" :class="{active : tab === 'all'}">
                        <div class="media">
                            <icon source="menu" />
                        </div>
                        <div class="text">
                            <div class="title">{{ $t(type + '.filter.all') }}</div>
                        </div>
                    </div>
                    <div class="item" @click="handleSelectedTab('passive')"
                         :class="{active : tab === 'passive'}">
                        <div class="media">
                            <i class="icon el-icon-time"></i>
                        </div>
                        <div class="text">
                            <div class="title">{{ $t(type + '.filter.passive') }}</div>
                        </div>
                    </div>
                    <div class="item" @click="handleSelectedTab('active')" :class="{active : tab === 'active'}">
                        <div class="media">
                            <i class="icon el-icon-date"></i>
                        </div>
                        <div class="text">
                            <div class="title">{{ $t(type + '.filter.active') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'StatusFilter',
    props : ['type', 'filter'],
    data() {
        return {
            tab : 'all',
            dialogVisible : false
        }
    },
    mounted() {
        //
    },
    methods: {
        handleSelectedTab(value) {
            this.dialogVisible = false;
            this.tab = value;
            return this.filter(value)
        }
    }
}
</script>
