<template>
    <el-drawer :title="title" :visible.sync="visible" append-to-body>
        <div class="drawer-body" v-loading="pageLoading">
            <el-form ref="form" :model="form">
                <el-form-item v-if="form.id && type === 'account'">
                    <el-switch v-model="form.is_main" :active-text="$t('inputs.main_address')" />
                </el-form-item>

                <el-form-item :label="$t('inputs.address_title')" :class="{ 'is-error': submitted && $v.form.title.$error }">
                    <el-input v-model="form.title" :placeholder="$t('inputs.address_title')" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item :label="$t('inputs.first_name')" :class="{ 'is-error': submitted && $v.form.first_name.$error }">
                            <el-input v-model="form.first_name" :placeholder="$t('inputs.first_name')" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('inputs.last_name')" :class="{ 'is-error': submitted && $v.form.last_name.$error }">
                            <el-input v-model="form.last_name" :placeholder="$t('inputs.last_name')" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item :label="$t('inputs.identification_no')" :class="{ 'is-error': submitted && $v.form.identification_no.$error }">
                    <el-input v-model="form.identification_no" :placeholder="$t('inputs.identification_no')" v-mask="'###########'" />
                </el-form-item>

                <el-form-item :label="$t('inputs.phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }">
                    <el-input v-model="form.phone" :placeholder="$t('inputs.phone')" v-mask="'0 (###) ### ## ##'" />
                </el-form-item>

                <zones :form="form" />

                <el-form-item :label="$t('inputs.address_1')" :class="{ 'is-error': submitted && $v.form.address_1.$error }">
                    <el-input v-model="form.address_1" :placeholder="$t('inputs.address_1')" />
                </el-form-item>

                <el-form-item :label="$t('inputs.address_2')" :class="{ 'is-error': submitted && $v.form.address_2.$error }" class="margin-bottom-0">
                    <el-input v-model="form.address_2" :placeholder="$t('inputs.address_2')" />
                </el-form-item>
            </el-form>

            <div class="actions">
                <div class="action">
                    <el-button type="success" class="fluid" size="large" @click="store" v-if="!form.id" v-loading="loading" :disabled="loading">
                        {{ $t('btn_save') }}
                    </el-button>
                    <el-button type="success" class="fluid" size="large" @click="update" v-if="form.id" v-loading="loading" :disabled="loading">
                        {{ $t('btn_update') }}
                    </el-button>
                </div>
                <div class="action">
                    <el-button class="fluid" size="large" @click="visible = false">
                        {{ $t('btn_cancel') }}
                    </el-button>
                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {DESTROY_CRUD_ITEM, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'AddressDrawer',
    props: ['index', 'type', 'storeCall', 'updateCall'],
    data() {
        return {
            path: 'platform/account/addresses',
            pageLoading: false,
            title: null,
            visible: false,
            loading: false,
            submitted: false,
            form: {
                //
            },
        }
    },
    validations: {
        form: {
            country_zone_id: {required},
            city_zone_id: {required},
            town_zone_id: {required},

            identification_no: {required, minLength: minLength(11), maxLength: maxLength(11)},
            title: {required, minLength: minLength(2), maxLength: maxLength(191)},
            first_name: {required},
            last_name: {required},

            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
            address_1: {required},
            address_2: {},
            is_main: {},
            company_name: {},
            tax_no: {},
            tax_center: {},
        }
    },
    methods: {
        create() {
            this.submitted = false;
            this.loading = false;
            this.form = {
                //
            };

            this.title = this.$t('account.create_address');
            this.visible = true;

        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;
                    this.loading = false;
                    this.visible = false;

                    if (this.storeCall) {
                        return this.storeCall(response)
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        edit(item) {
            this.title = this.$t('account.address_detail')
            this.visible = true;
            this.form = this.cloneObjectData(item);
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;
                    this.loading = false;

                    this.index();

                    if (this.updateCall) {
                        return this.updateCall(response)
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        }
    }
}
</script>
