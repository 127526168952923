import Vue from 'vue'
import Router from 'vue-router'
import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import store from "@/store";

// Layouts
import PageLayout from '../app/Layouts/PageLayout'

// Pages
import DashboardIndex from '../app/Pages/Dashboard/Index'

// Error
import Error404 from '../app/Pages/Errors/Error404'
import ErrorAccess from '../app/Pages/Errors/ErrorAccess'
import Maintenance from '../app/Pages/Errors/Maintenance'
import Pay from '../app/Pages/Checkout/Pay'

// Routes Group
import AuthRoutes from './auth'
import AccountRoutes from './account'
import HomeworkRoutes from './homeworks'
import ForumRoutes from './forums'
import ShopRoutes from './shop'
import CheckoutRoutes from './checkout'
import MiscRoutes from './misc'
import LessonRoutes from './lessons'
import BookRoutes from './books'
import TestRoutes from './tests'
import ExamRoutes from './exams'
import LevelingExamRoutes from './leveling_exams'
import ReadingRoutes from './readings'
import DocumentRoutes from './documents'
import VideoRoutes from './videos'
import ListeningRoutes from './listenings'
import WordListRoutes from './word_lists'
import DictionaryRoutes from './dictionary'
import ReportRoutes from './reports'


// Init
Vue.use(Router);

/**
 * Vue router
 *
 * @type {VueRouter}
 */
const router = new Router({
    mode: 'hash',
    routes: [
        AuthRoutes,
        AccountRoutes,
        HomeworkRoutes,
        ForumRoutes,
        ShopRoutes,
        CheckoutRoutes,
        MiscRoutes,
        LessonRoutes,
        BookRoutes,
        ReadingRoutes,
        TestRoutes,
        ExamRoutes,
        LevelingExamRoutes,
        WordListRoutes,
        VideoRoutes,
        ListeningRoutes,
        DocumentRoutes,
        DictionaryRoutes,
        ReportRoutes,
        {
            path: '/',
            name: 'app',
            redirect: '/auth/login',
            component: PageLayout,
            children: [
                {
                    path: 'dashboard',
                    name: 'app.dashboard.index',
                    component: DashboardIndex,
                    meta: {
                        auth: true,
                        title: 'Platform',
                        layout: 'full',
                    }
                }
            ],
        },
        {
            path: '/errors/access',
            name: 'app.errors.access.index',
            component: ErrorAccess,
            meta: {
                layout: 'content',
                title: 'Upss! Access Denied!',
            }
        },
        {
            path: '/maintenance',
            name: 'app.errors.maintenance.index',
            component: Maintenance,
            meta: {
                layout: 'content',
                title: 'Bağlantı Problemi',
            }
        },
        {
            path: '/checkout/pay',
            name: 'app.checkout.pay.index',
            component: Pay,
            meta: {
                layout: 'content',
                title: 'Pay',
            }
        },
        {
            path: '*',
            name: 'app.errors.404.index',
            component: Error404,
            meta: {
                layout: 'content',
                title: 'Upss! Page Not Found',
            }
        },
    ]
});

router.afterEach(() => {
    ApiService.setHeader()
});

router.beforeEach((to, from, next) => {

    ApiService.setHeader();

    if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' | Öğrenci Platformu';
    } else {
        document.title = 'Öğrenci Platformu';
    }

    if ((to.name).search('app.errors.maintenance') >= 0) {
        return next();
    }

    if (!JwtService.getToken()) {
        if ((to.name).search('app.auth.') < 0) {
            return window.location = '/#/auth/login';
        }
    } else {
        if ((to.name).search('app.auth.') >= 0) {

            if (to.query.redirect) {
                return window.location = '/#/' + to.query.redirect;
            }

            return window.location = '/#/dashboard';
        }
    }

    if (store.getters.account) {
        if (store.getters.account.role !== 'admin' && from.meta.access === 'admin') {
            return window.location = '/#/errors/access';
        }
    }

    return next();
});

export default router;
