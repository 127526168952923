<template>
    <div>
        <el-tooltip content="Şikayet Et" placement="top">
            <div class="btn" @click="create">
                <icon source="alert-outline" />
            </div>
        </el-tooltip>

        <el-dialog title="Şikayet Et" :visible.sync="dialogVisible" width="50%" append-to-body class="forum-add-new-topic-dialog">
            <el-form :model="form" label-position="top">
                <el-form-item :class="{ 'is-error': submitted && $v.form.message.$error }" class="margin-bottom-0">
                    <el-input v-model="form.message" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_message')" type="textarea" rows="4" />
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button type="primary" class="fluid">
                    Şikayet Et
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";

export default {
    name: 'Report',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            },

            dialogVisible: false,
        }
    },
    validations: {
        form: {
            message: {required, minLength: minLength(10)},
        }
    },
    mounted() {
        //
    },
    methods: {
        create() {
            this.dialogVisible = true;
            this.form = {
                //
            }
        },

        store() {

        },
    }
}
</script>
