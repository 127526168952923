<template>
    <div :ref="'QuestionRow-' + item.id" class="question-row" v-loading="loading">
        <div class="old-draw" style="display: none">
            <img :src="item.draw" alt="">
        </div>
        <div class="asset-content" v-if="item.meta.file_src">
            <audio ref="AudioPlayer" controls :src="publicFilePath(item.meta.file_src)" ></audio>
        </div>
        <canvas class="paint-canvas draw" :class="'draw-' + item.id" :width="canvas.width" :height="canvas.height"></canvas>
        <div class="question-container">
            <div class="question-instruction" v-if="item.instruction" v-html="item.instruction.description"></div>
            <div class="question-content" v-if="item.content.description" v-html="item.content.description"></div>
            <div class="question">
                <div class="prefix">
                    {{ item.prefix }}
                </div>
                <div class="description">
                    <div class="fluid" v-html="item.question.description"></div>
                </div>
            </div>
            <div class="options">
                <template v-for="(option, optionIndex) in item.options">
                    <div class="option" :key="optionIndex">
                        <div
                            class="prefix"
                             :class="{active : item.selected_option_id === option.id, correct : item.is_show_answer && option.is_correct}"
                            @click="handleSelectedOption(option)"
                        >
                            {{ option.prefix }})
                        </div>
                        <div class="option-description">
                            <div class="option-description-wrp" v-html="option.description"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="actions">
            <el-tooltip content="Cevabi Gor" placement="left">
                <div class="item" @click="handleShowAnswer(item)" v-if="view === 'answer'">
                    <icon source="eye-outline" />
                </div>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import {UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'QuestionItem',
    props: ['item', 'view'],
    data() {
        return {
            path: 'platform/student/tests',
            loading: false,
            canvas: {
                width: 100,
                height: 100,
            }
        }
    },
    mounted() {
        this.loading = true;

        setTimeout(() => {

            this.initMathJax(window.document.getElementsByTagName('math'));

            setTimeout(() => {
                this.initCanvas();
            }, 500);
        }, 500);

        setInterval(() => {
            this.item.time++;
        }, 1000);
    },
    methods: {
        handleSelectedOption(option) {
            this.item.selected_option_id = option.id

            this.$store.dispatch(UPDATE_CRUD_ITEM, {
                path: this.path,
                id: this.item.test_id,
                question_id: this.item.id,
                option_id: this.item.selected_option_id,
                time: this.item.time,
                draw: this.item.draw
            });
        },

        initCanvas() {

            let ref = this.$refs['QuestionRow-' + this.item.id]

            this.canvas.width = ref.offsetWidth - 50;
            this.canvas.height = ref.offsetHeight + 20;

            setTimeout(() => {
                const paintCanvas = document.querySelector('.draw-' + this.item.id);
                const context = paintCanvas.getContext('2d');
                context.lineCap = 'round';
                context.strokeStyle = 'rgba(66, 167, 254, .3';
                context.lineWidth = 4;

                let x = 0, y = 0;
                let isMouseDown = false;

                const stopDrawing = () => {
                    isMouseDown = false;
                }
                const startDrawing = event => {
                    isMouseDown = true;
                    [x, y] = [event.offsetX, event.offsetY];
                }
                const drawLine = event => {
                    if (isMouseDown) {
                        const newX = event.offsetX;
                        const newY = event.offsetY;
                        context.beginPath();
                        context.moveTo(x, y);
                        context.lineTo(newX, newY);
                        context.stroke();
                        x = newX;
                        y = newY;

                        this.item.draw = paintCanvas.toDataURL('image/png');
                    }
                }

                paintCanvas.addEventListener('mousedown', startDrawing);
                paintCanvas.addEventListener('mousemove', drawLine);
                paintCanvas.addEventListener('mouseup', stopDrawing);
                paintCanvas.addEventListener('mouseout', stopDrawing);

                console.log('draw init')

                if (this.item.draw) {
                    let image = new Image();
                    image.src = this.item.draw;
                    context.drawImage(image, 0, 0);
                }

                this.loading = false
            }, 500)
        },

        handleShowAnswer(question) {
            question.is_show_answer = !question.is_show_answer
        }
    }
}
</script>
