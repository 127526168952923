import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Tests/Index";
import Show from "@/app/Pages/Tests/Show";
import Report from "@/app/Pages/Tests/Report";

export default {
    path: '/tests',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Testler',
    },
    children: [
        {
            name: 'app.tests.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Testler',
            },
        },
        {
            name: 'app.tests.report',
            path: ':id/report',
            component: Report,
            meta: {
                auth: true,
                title: 'Rapor',
            },
        },
        {
            name: 'app.tests.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Test',
            },
        },
    ]
}
