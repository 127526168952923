<template>
    <div v-loading="pageLoading">
        <template v-for="(item, index) in items(storePath(path))">
            <article class="box margin-top-30" :key="index">
                <div class="box-body single-topic">
                    <div class="media">
                        <avatar :name="item.user.name" :source="item.user.avatar" />
                    </div>
                    <div class="content">
                        <div class="head">
                            <div class="text">
                                <div class="name">{{ item.user.name }}</div>
                                <div class="description">{{ item.user.role }}</div>
                            </div>
                            <div class="date">
                                <icon source="clock-outline" />
                                {{ item.date }}
                            </div>
                        </div>
                        <div class="body">
                            <div v-if="item.parent">
                                <blockquote class="parent" v-html="viewer(item.parent)"></blockquote>
                            </div>
                            <div v-html="viewer(item.description)"></div>
                        </div>
                        <div class="footer">
                            <div class="left"></div>
                            <div class="right">
                                <el-tooltip content="Cevapla" placement="top">
                                    <div class="btn" @click="create(item.id)">
                                        <icon source="undo" />
                                    </div>
                                </el-tooltip>
                                <template v-if="account.id === item.user.id">
                                    <el-tooltip :content="$t('btn_edit')" placement="top">
                                        <div class="btn" @click="edit(item)">
                                            <icon source="square-edit-outline" />
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('btn_delete')" placement="top">
                                        <div class="btn" @click="destroy(item.id)">
                                            <icon source="trash-can-outline" />
                                        </div>
                                    </el-tooltip>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </template>

        <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />

        <el-dialog title="Cevap" :visible.sync="dialogVisible" width="60%" append-to-body
                   class="forum-add-new-topic-dialog">
            <el-form :model="form" label-position="top">
                <el-form-item :class="{ 'is-error': submitted && $v.form.content.$error }"
                              class="markdown-editor-form-item margin-bottom-0">
                    <editor :container="form" model="content" />
                </el-form-item>
            </el-form>

            <template slot="footer">
                <el-button type="primary" class="fluid" v-if="!form.id" :loading="loading" :disabled="loading"
                           @click="store">
                    Cevapla
                </el-button>
                <el-button type="success" class="fluid" v-if="form.id" :loading="loading" :disabled="loading"
                           @click="update">
                    {{ $t('btn_update') }}
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {DESTROY_CRUD_ITEM, GET_CRUD_ITEMS, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {minLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

const marked = require('marked');

export default {
    name: 'ForumComment',
    props: ['id'],
    data() {
        return {
            path: 'platform/misc/comments/',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,

            loading: false,
            submitted: false,
            form: {
                //
            },

            dialogVisible: false,
        }
    },
    validations: {
        form: {
            content: {required, minLength: minLength(10)},
        }
    },
    computed: mapGetters(['pagination', 'items', 'account']),
    mounted() {
        this.path = this.path + this.id + '/contents';

        this.index();
    },
    methods: {

        viewer(text) {
            return marked(text);
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create(parentId) {
            this.dialogVisible = true;
            this.form = {
                //
            }

            if (parentId) {
                this.form.parent_id = parentId
            }
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        edit(item) {
            this.dialogVisible = true;
            this.form = {
                id: item.id,
                content: item.description
            }
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        }
    }
}
</script>
