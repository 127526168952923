<template>
    <div class="box">
        <div class="box-head">
            <div class="title">{{ $t('dashboard.homework') }}</div>
            <div class="actions">
                <router-link to="/homeworks">
                    {{ $t('homeworks.filter.all') }}
                </router-link>
            </div>
        </div>
        <div class="box-body rows" v-loading="pageLoading">
            <div class="overflow-600-column">
                <template v-for="(item, index) in items">
                    <div class="row padding-left-0 padding-right-0 cursor-pointer" :key="index" @click="goRoute('/homeworks/' + item.id)">
                        <div class="content">
                            <div class="text">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="metas">
                                    <div class="meta">
                                        {{ item.teacher }}
                                    </div>
                                    <div class="meta">
                                        <icon source="calendar-outline" />
                                        {{ item.date }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'Homework',
    data() {
        return {
            path: 'platform/student/homeworks',
            pageLoading: false,
            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: null, page: 1, process: 'today'})
                .then((response) => {
                    this.pageLoading = false;
                    this.items = response;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        }
    }
}
</script>
