export const GET_UI = 'ui';
export const STORE_LOGIN = 'storeLogin';
export const STORE_REGISTER = 'storeRegister';
export const STORE_FORGOT_PASSWORD = 'storeForgotPassword';
export const STORE_RESET_PASSWORD = 'storeResetPassword';
export const GET_ACCOUNT = 'getAccount';
export const UPDATE_ACCOUNT = 'updateAccount';
export const CHANGE_AVATAR_ACCOUNT = 'changeAccountAvatar';

// Crud
export const GET_CRUD_ITEMS = 'getCrudItems';
export const GET_CRUD_ITEM = 'getCrudItem';
export const STORE_CRUD_ITEM = 'storeCrudItem';
export const UPDATE_CRUD_ITEM = 'updateCrudItem';
export const DESTROY_CRUD_ITEM = 'destroyCrudItem';

// Checkout
export const GET_CHECKOUT_QUANTITY = 'getCheckoutQuantity';
export const GET_CHECKOUT_SUMMARY = 'getCheckoutSummary';
export const STORE_CHECKOUT_ADD_TO_BASKET = 'storeCheckoutAddToBasket';
export const DESTROY_CHECKOUT_ADD_TO_BASKET = 'destroyCheckoutAddToBasket';
export const TRUNCATE_CHECKOUT_ADD_TO_BASKET = 'truncateCheckoutAddToBasket';
export const STORE_CHECKOUT_ADDRESS = 'storeCheckoutAddress';
export const GET_CHECKOUT_COUPON = 'getCheckoutCoupon';
export const DESTROY_CHECKOUT_COUPON = 'destroyCheckoutCoupon';