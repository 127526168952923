<template>
    <div class="app-wrapper" :class="$route.meta.bodyClass" v-if="ui">
        <div id="Header" v-if="isMobile">
            <div class="header-wrp header-mobile-wrp" >
                <MobileMenu :active="true" />
                <div class="logo">
                    <router-link to="/dashboard">
                        <img :src="publicFilePath(ui.view.logo_light)" alt="logo">
                    </router-link>
                </div>
                <div class="items">
                    <BasketHeader v-if="ui.permissions.has_access_shop"/>
                </div>
            </div>
        </div>

        <nav id="Sidebar" v-if="!isMobile && visibleInformation()">
            <router-link to="/dashboard" class="item logo">
                <img :src="publicFilePath(ui.view.sidebar_logo)" alt="logo">
            </router-link>
            <div class="menu">
                <div class="items top" >
                    <div class="items-container">
                        <AppMenu :isSidebar="true" />
                        <el-tooltip :content="$t('menu.shop')" placement="right">
                            <router-link :to="'/shop/' + ui.shop_categories[0].slug" class="item" v-if="lodash.size(ui.shop_categories)">
                                <svg-icon src="shop"/>
                            </router-link>
                        </el-tooltip>
                    </div>
                </div>
                <div class="items actions">
                    <div class="items-container">
                        <el-tooltip :content="$t('menu.messages')" placement="right">
                            <router-link to="/misc/messages" class="item" v-if="ui.permissions.has_access_talk">
                                <div class="dot-badge-container">
                                    <icon source="message-text-outline" />
                                    <div class="dot-badge" v-if="account && account.counter.chat"></div>
                                </div>
                            </router-link>
                        </el-tooltip>
                        <el-tooltip content="Sözlük" placement="right">
                            <router-link to="/dictionary" class="item" v-if="ui.permissions.has_access_dictionary">
                                <svg-icon src="app-dictionary-white"/>
                            </router-link>
                        </el-tooltip>
                        <el-tooltip content="İpucu Havuzu" placement="right">
                            <router-link to="/clue-bank" class="item" v-if="ui.permissions.has_access_clue_bank">
                                <svg-icon src="app-clue-white"/>
                            </router-link>
                        </el-tooltip>
                    </div>
                </div>
                <div class="items footer">
                    <div class="items-container">
                        <FeedbackHeader />
                    </div>
                </div>
            </div>
        </nav>
        <main id="Content" :class="{fluid : !visibleInformation()}">
            <router-view :key="$route.fullPath" />
        </main>
        <aside id="Information" v-if="visibleInformation()">
            <header id="InformationHeader">
                <div class="items">
                    <BasketHeader class="item" v-if="ui.permissions.has_access_shop" />
                    <NotificationHeader class="item" />
                </div>
                <AccountHeader />
            </header>
            <div class="main-content">
                <Schedule class="margin-top-20" />
                <Homework class="margin-top-20" />
            </div>
        </aside>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import { isMobile, isTablet, isMobileSafari } from 'mobile-device-detect';
import MobileMenu from "@/app/Layouts/Parts/MobileMenu";
import AppMenu from "@/app/Layouts/Parts/AppMenu";
import NotificationHeader from "@/app/Layouts/Parts/Notification";
import AccountHeader from "@/app/Layouts/Parts/Account";
import BasketHeader from "@/app/Layouts/Parts/Basket";
import FeedbackHeader from "@/app/Layouts/Parts/Feedback";
import Schedule from "@/app/Layouts/Parts/Schedule.vue";
import Homework from "@/app/Pages/Homeworks/Homework.vue";

export default {
    name: 'PageLayout',
    components: {
        MobileMenu,
        AppMenu,
        FeedbackHeader,
        BasketHeader,
        AccountHeader,
        NotificationHeader,
        Schedule,
        Homework,
    },
    computed: mapGetters(['ui', 'account']),
    data() {
        return {
            isMobile : false,
        }
    },
    mounted() {
        if (isMobile || isTablet) {
            this.isMobile = true
        }
    },
    methods : {
        visibleInformation() {

            if (this.isMobile) {
                return false
            }

            if (this.$route.name === 'app.misc.messages.show') {
                return false;
            }

            if (this.$route.name === 'app.misc.talks.show') {
                return false;
            }

            if (this.$route.name === 'app.dictionary.show') {
                return true
            }

            if (!this.$route.params.id) {
                return true
            }
        }
    }
}
</script>
