import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/WordLists/Index";
import Show from "@/app/Pages/WordLists/Show";

export default {
    path: '/word-lists',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Kelime Listeleri',
    },
    children: [
        {
            name: 'app.word_lists.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Kelime Listeleri',
            },
        },
        {
            name: 'app.word_lists.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Kelime Listesi',
            },
        },
    ]
}
