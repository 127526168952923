export const en = {
    error: {
        title: 'Oops we can’t find what you are looking for :(',
        access: 'Sorry, you do not have permission to access this page :(',
        maintenance_title: 'We are updating our services to serve you better...',
    },

    notification: {
        warning_title: 'Warning!',
        destroy: 'The data you selected will be permanently deleted. Do you confirm?',
        destroy_cancel: 'Deletion aborted.',
        sending_cancel: 'The sending process has been canceled.',
    },

    empty: {
        not_found: 'Sorry, your search didn\'t match any results !',
        building: 'Sorry, this feature is currently under construction.',
        today_lesson: 'Sorry, you don\'t have any lessons today. ',
        range_lesson: 'Sorry, you do not have a course between the dates you selected.',
    },

    menu: {
        dashboard: 'Dashboard',
        lessons: 'Lessons',
        classrooms: 'Classrooms',
        books: 'Books',
        readings: 'Readings',
        tests: 'Tests',
        exams: 'Exams',
        word_list: 'Word List',
        videos: 'Videos',
        listenings: 'Listenings',
        documents: 'Documents',
        shop: 'Shop',
        forum: 'Forum',
        homework: 'Homework',
        chat: 'Messages',
        private_reports: 'Reports',
    },

    auth: {
        remember_me: 'Remember me!',
        forgot_password: 'Forgot password',
        dont_have_account : 'Don\'t have an account?',
    },

    account: {
        menu: {
            my_account: 'Account',
            my_orders: 'Orders',
            my_address: 'Addresses',
            my_discount : 'Discounts',
            my_notification: 'Notification Settings',
            logout: 'Log Out!',
        },

        my_profile : 'Profile Information',
        my_password : 'Password Information',
        my_addresses : 'My Addresses',
        create_address : 'Add New Address',
        address_detail : 'My Address Information',
        notification_title : 'Notification Settings',

        notifications : {
            titles : {
                payments : 'Payment',
                services : 'Services',
                news : 'Announcements',
                campaigns : 'Campaigns',
            }
        }
    },

    notifications : {
        title : 'Notifications',
        all : 'All Notifications',
        detail : 'Notificaiton Information',
    },

    dashboard: {
        title: 'Dashboard',
        classroom_count: 'Total Classroom',
        lesson_count: 'Total Lesson',
        student_count: 'Total Student',
    },

    feeds: {
        title: 'Student Feeds',
        action_types: {
            lesson: 'lesson',
            test: 'test',
            reading: 'reading',
            book: 'book',
            forum: 'forum',
            homework: 'homework',
        },
        actions: {
            watch: 'watched',
            solved: 'solved',
            studied: 'studied',
            joined: 'joined',
            completed: 'completed',
        },
        in_class: 'in class ',
        named: 'named',
    },

    lessons: {
        title: 'Lessons',
        detail: 'Lesson Information',
        actions: {
            live: 'Start Lesson',
            recording: 'Preparing lesson records...',
            record: 'Watch',
            countdown: {
                days: 'da.',
                hours: 'h.',
                minutes: 'min.',
                seconds: 'sec.',
                after: 'later.',
            }
        },
        feedback : {
            button : 'Add New Feedback',
            title : 'Feedback',
        }
    },

    schedule: {
        title: 'Lesson Schedule'
    },

    classrooms: {
        title: 'Classrooms',
        detail: 'Classroom Information',
        education_start_and_end_date: 'Training Start and End Date',
        stats: {
            student_count: '{count} Student',
            lesson_count: '{count} Lesson',
        },
        students: {
            title: 'Students',
            detail: 'Student Information',

            newsletters : {
                title : 'Newsletters',
                detail : 'Newsletter',
                create : 'Add Newsletter'
            }
        },
        homeworks: {
            title: 'Homeworks',
            detail: 'Homework Information',
            create: 'New Homework Added',
            all: 'All Homework',
            homework: 'Homework',
            students: 'Students',
            completed: 'Completed',
            not_started: 'Did Not Start',
        },
        forums: {
            title: 'Forums',
            detail: 'Forum Information',
            create: 'New Forum Added',
            all: 'All Forum',
            stats: {
                student_count: '{count} Student',
                comment_count: '{count} Answer',
            },
        },
    },

    // inputs
    inputs: {
        choice: 'Your Choice',
        title: 'Title',
        description: 'Description',
        start_at: 'Start Date',
        end_at: 'End Date',
        classrooms: 'Classes',
        files: 'Files',
        homework: 'Homework',
        your_message: 'Your Message...',
        your_email: 'Your Email Address',
        your_password: 'Your Password',
        language: 'Language',
        sms: 'Sms',
        email: 'E-Mail',
        mobile: 'Mobil',
        username: 'Username',
        birthday: 'Date of Birth',
        password : 'Password',
        your_new_password : 'Your New Password',
        your_new_password_repeat : 'Your New Password (Again)',
        main_address: 'Default Contact Address',
        address: 'Address',
        address_title: 'Address Title',
        first_name: 'Name',
        last_name: 'LAst Name',
        identification_no: 'Identification Number',
        phone: 'Phone',
        country: 'Country',
        city: 'City',
        town: 'Town',
        address_1: 'Address 1',
        address_2: 'Address 2',
        subject: 'Subject',
        translate: 'Translate',
        publish_at: 'Release Date',
    },

    // Misc
    misc: {
        pagination_result: 'A total of <b>{total}</b> results were found.',
        welcome: 'Welcome!',
        or: 'Or',
        yes: 'Yes',
        no: 'No',
        apps: 'Apps',
    },

    // Buttons
    btn_login: 'Sign in!',
    btn_register: 'Register',
    btn_send: 'Submit',
    btn_save: 'Save',
    btn_update: 'Update',
    btn_change: 'Change',
    btn_edit: 'Edit',
    btn_delete: 'Delete',
    btn_back: 'Back',
    btn_upload: 'Upload',
    btn_create: 'Create',
    btn_next: 'Next',
    btn_search: 'Search',
    btn_add: 'Add',
    btn_select: 'Select',
    btn_print: 'Print',
    btn_add_file: 'Add File',
    btn_view: 'View',
    btn_close: 'Close',
    btn_facebook_login: 'Continue with Facebook',
    btn_google_login: 'Continue with Google',
    btn_facebook_register: 'Register with Facebook',
    btn_google_register: 'Register with Google',
};
