// Utils
import lodash from 'lodash';
import moment from 'moment';

// Vue
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 3. party
import Vuelidate from 'vuelidate'
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale'
import ElementUILang from 'element-ui/lib/locale/lang/tr-TR'
import VueTheMask from 'vue-the-mask'
import VuePageTransition from 'vue-page-transition'
import VueYoutube from 'vue-youtube'
import Editor from 'v-markdown-editor'

// Misc
import "./utils/RegisterServiceWorker";
import store from './store'
import router from './routes/'
import {Languages} from './resources/lang';
import './components'
import ApiService from "./services/api.service";
import Config from "./config/app";
import MetaTitle from './mixins/MetaTitle'

// Set
ApiService.init();
ElementUILocale.use(ElementUILang);

// Init
Vue.use(VueI18n);
Vue.use(ElementUI, {ElementUILocale});
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VuePageTransition);
Vue.use(VueYoutube);
Vue.use(Editor);

Vue.mixin(MetaTitle)

require('./utils/Urlify')

// Config
Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.prototype.lodash = lodash;
Vue.prototype.moment = moment;

Vue.mixin({
	data: () => {
		return {
			datePickerOption: {
				firstDayOfWeek: 1,
			},
			confirmOption: {
				confirmButtonText: 'Onayla',
				cancelButtonText: 'Iptal',
				type: 'warning'
			},
			predefineColors: [
				'#ff4500',
				'#ff8c00',
				'#ffd700',
				'#90ee90',
				'#00ced1',
				'#1e90ff',
				'#c71585',
				'#ffffff',
				'#5c50b9',
			],
			datePickerOptions: {
				firstDayOfWeek: 1,
			},
			genders: [
				{
					id: 'e',
					title: 'Erkek'
				},
				{
					id: 'k',
					title: 'Kadin'
				}
			]
		}
	},
	methods: {
		openBlankWindow(link) {
			return window.open(link, '_blank');
		},

		elementSize(items) {
			return this.lodash.size(items)
		},

		goRoute(link) {
			if (this.$route.path === link) {
				return null;
			}

			return this.$router.push(link);
		},

		goRouteExternal(link) {
			window.location = link;
		},

		formatBytes(bytes, decimals = 2) {
			if (bytes === 0) return '0 Bytes';

			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},

		formatSecondTime(time) {
			var sec_num = parseInt(time, 10); // don't forget the second param
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
			var seconds = sec_num - (hours * 3600) - (minutes * 60);

			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}

			if (!parseInt(hours)) {
				return minutes + ':' + seconds;
			}

			return hours + ':' + minutes + ':' + seconds;
		},

		cloneObjectData(data) {
			return JSON.parse(JSON.stringify(data));
		},

		publicFilePath(path) {
			if (!path) {
				return '';
			}

			if ((path).slice(0, 4) === 'data') {
				return path;
			}

			if ((path).slice(0, 4) === 'http') {
				return path;
			}

			return this.$store.state.ui.ui.brand.s3_path + path;
		},

		storePath(payload) {
			let paths = payload.split('/')

			return paths.join('_');
		},

		cardMonths() {
			return [
				{
					id: '01',
					title: '01'
				},
				{
					id: '02',
					title: '02'
				},
				{
					id: '03',
					title: '03'
				},
				{
					id: '04',
					title: '04'
				},
				{
					id: '05',
					title: '05'
				},
				{
					id: '06',
					title: '06'
				},
				{
					id: '07',
					title: '07'
				},
				{
					id: '08',
					title: '08'
				},
				{
					id: '09',
					title: '09'
				},
				{
					id: '10',
					title: '10'
				},
				{
					id: '11',
					title: '11'
				},
				{
					id: '12',
					title: '12'
				}
			]
		},

		cardYears() {
			let items = [];

			let today = (new Date).getFullYear();

			let i;
			for (i = 0; i < 20; i++) {
				items.push({
					id: today + i,
					title: today + i,
				})
			}

			return items;
		},

		cardInstallments() {
			let items = [];

			items.push({
				id: 1,
				title: this.$t('inputs.single_installment')
			})

			let i = 1;

			for (i = 1; i < 12; i++) {
				items.push({
					id: parseInt(i + 1),
					title: (i + 1) + ' ' + this.$t('inputs.installment')
				})
			}

			return items;
		},

		imageLoad(url) {
			let status = null;
			let image = new Image();

			image.src = url;

			image.onload = () => {
				status = url;
			};

			image.onerror = () => {
				status = null
			};

			return status;
		},

		findNotificationLink(type, id) {

			switch (type) {
				case 'announcement':
					return '/misc/announcements/' + id
				case 'lessons':
					return '/lessons/' + id
				case 'education_tests':
					return '/tests/' + id
				case 'education_documents':
					return '/documents/' + id
				case 'education_books':
					return '/books/' + id
			}

			return '/dashboard'
		},

		findProductType(type) {
			switch (type) {
				case 'weekend':
					return 'Hafta Sonu';
				case 'weekdays':
					return 'Hafta İçi';
				case 'night':
					return 'Gece';
				case 'day':
					return 'Gündüz';
				case 'live_and_record':
					return 'Canlı ve Kayıttan';
				case 'live':
					return 'Canlı';
				case 'record':
					return 'Kayıttan';
				default :
					return type;
			}
		},

		findProductIcon(type) {

			switch (type) {
				case 'group':
					return 'message-video';
				case 'package':
					return 'archive-outline';
				case 'physical':
					return 'bookshelf';
				case 'digital':
					return 'gesture-tap-button';
				default :
					return type;
			}
		},

		filePreviewIcon(type) {
			// video
			if (type === 'video') {
				return 'file-video-outline';
			}

			// audio
			if (type === 'audio') {
				return 'file-upload-outline';
			}

			// image
			if (type === 'image') {
				return 'file-image-outline';
			}

			return 'file-document-outline';
		},

		linkify(inputText) {
			let replacedText, replacePattern1, replacePattern2, replacePattern3;

			//URLs starting with http://, https://, or ftp://
			replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
			replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

			//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
			replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
			replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

			//Change email addresses to mailto:: links.
			replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
			replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

			return replacedText;
		},

		contractDialog(index) {
			this.$store.commit(SET_UI_CONTRACT, {index : index, visible : true})
		},

		initMathJax(element) {
			this.lodash.forEach(element, (math, index) => {
				let value = element[index].outerHTML.trim()

				element[index].outerHTML = window.MathJax.mathml2chtml(value).outerHTML;
			});

			window.MathJax.startup.document.clear();
			window.MathJax.startup.document.updateDocument();
		},
	}
});

// App
import Bootstrap from './Bootstrap.vue'
import {SET_UI_CONTRACT} from "@/store/mutations.type";

new Vue({
	router,
	store,
	i18n: new VueI18n({
		locale: 'tr-TR',
		silentTranslationWarn: true,
		messages: Languages,
		dateTimeFormats: {
			'tr-TR': {
				short: {
					year: 'numeric', month: 'short', day: 'numeric'
				},
				long: {
					year: 'numeric', month: 'short', day: 'numeric',
					weekday: 'short', hour: 'numeric', minute: 'numeric'
				}
			}
		},
		numberFormats: {
			'en-Us': {
				currency: {
					style: 'currency', currency: 'TRY', symbol: '₺'
				}
			},
			'en': {
				currency: {
					style: 'currency', currency: 'TRY', symbol: '₺'
				}
			},
			'tr-TR': {
				currency: {
					style: 'currency', currency: 'TRY', symbol: '₺'
				}
			},
			'tr': {
				currency: {
					style: 'currency', currency: 'TRY', symbol: '₺'
				}
			}
		}
	}),
	replace: false,
	render: h => h(Bootstrap),
}).$mount('#Bootstrap');
