<template>
    <div class="book-page-content">
        <template v-if="items">
            <div class="book-page-content-items">
                <template v-for="(item, index) in items">
                    <div class="book-page-content-item" :key="index">
                        <div class="body">
                            <TestContentType :response="item.row" v-if="item.type === 'test'" />
                            <ReadingContentType :response="item.row" v-if="item.type === 'reading'" />
                            <WordListContentType :response="item.row" v-if="item.type === 'wordlist'" />
                            <VideoContentType :response="item.row" v-if="item.type === 'video'" />
                            <ListeningContentType :response="item.row" v-if="item.type === 'listening'" />
                            <DocumentContentType :response="item.row" v-if="item.type === 'document'" />
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import TestContentType from "@/app/Pages/Books/_contents/Test";
import VideoContentType from "@/app/Pages/Books/_contents/Video";
import WordListContentType from "@/app/Pages/Books/_contents/WordList";
import ReadingContentType from "@/app/Pages/Books/_contents/Reading";
import ListeningContentType from "@/app/Pages/Books/_contents/Listening";
import DocumentContentType from "@/app/Pages/Books/_contents/Document";

export default {
    name: 'Exercise',
    components: {
        TestContentType,
        ReadingContentType,
        WordListContentType,
        VideoContentType,
        ListeningContentType,
        DocumentContentType,
    },
    data() {
        return {
            path: 'platform/student/books',
            pageLoading: true,
            items: null,
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.path = this.path + '/' + this.$route.params.id + '/exercises';

        this.show();
    },

    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.exerciseId, unit_id: this.$route.params.unitId,  section_id : this.$route.params.sectionId})
                .then(() => {
                    this.pageLoading = false;
                    this.items = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.items = null;
                })
        }
    }
}
</script>
