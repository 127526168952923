<template>
    <div>
        <empty alias="discount" title="discount"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'DiscountAccount',
    data() {
        return {
            path: 'platform/account/discounts',
            filter: {
                //
            },
            page: 1,

            pageLoading: false,
            loading: false,
            submitted: false,
            dialogVisible: false,
            form: {
                //
            },
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        //
    }
}
</script>
