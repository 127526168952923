<template>
    <el-popover placement="bottom" width="260" trigger="click" popper-class="popover-menu">
        <div class="item account" slot="reference">
            <template v-if="account">
                <div class="text">
                    <div class="welcome">Hosgeldin,</div>
                    <div class="name">{{ account.name }}</div>
                </div>
                <avatar :name="account.name" :source="account.avatar" class="media" />
            </template>
        </div>
        <div class="popover-menu-container">
            <div class="popover-menu-item">
                <router-link to="/account/me">
                    <div class="media">
                        <icon source="account-outline" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.my_account') }}
                    </div>
                </router-link>
            </div>
            <div class="popover-menu-item">
                <router-link to="/account/orders">
                    <div class="media">
                        <icon source="cart-outline" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.my_orders') }}
                    </div>
                </router-link>
            </div>
            <div class="popover-menu-item">
                <router-link to="/account/addresses">
                    <div class="media">
                        <icon source="map-outline" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.my_address') }}
                    </div>
                </router-link>
            </div>
            <div class="popover-menu-item">
                <router-link to="/account/discounts">
                    <div class="media">
                        <icon source="sale" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.my_discount') }}
                    </div>
                </router-link>
            </div>
            <div class="popover-menu-item">
                <router-link to="/account/notifications">
                    <div class="media">
                        <icon source="bell-ring-outline" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.my_notification') }}
                    </div>
                </router-link>
            </div>
            <div class="popover-menu-divider"></div>
            <div class="popover-menu-item active last">
                <router-link to="/account/logout">
                    <div class="media text-red">
                        <icon source="power-standby" />
                    </div>
                    <div class="text">
                        {{ $t('account.menu.logout') }}
                    </div>
                </router-link>
            </div>
        </div>
    </el-popover>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'AccountHeader',
    data() {
        return {
            //
        }
    },
    computed: mapGetters(['account', 'ui']),
    mounted() {
        this.$store.dispatch(GET_ACCOUNT);
    },
    methods: {
        //
    }
}
</script>
