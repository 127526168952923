<template>
    <div class="message-page talk-page" v-loading="pageLoading">
        <aside class="contacts">
            <div class="head">
                <el-form :model="filter" label-position="top" @submit.native.prevent="search">
                    <el-form-item class="margin-bottom-0">
                        <el-input v-model="filter.search" prefix-icon="el-icon-search" :placeholder="$t('messages.search')" />
                    </el-form-item>
                    <el-button native-type="submit" style="display: none"></el-button>
                </el-form>
            </div>
            <div class="body">
                <div class="items">
                    <template v-for="(item, index) in searchItems">
                        <div class="item" :key="index" :class="{active : index === roomIndex}" @click="handleSelectedRoom(index, item)">
                            <div class="text">
                                <div class="text-head">
                                    <div class="name">{{ item.title }}</div>
                                    <div class="date">{{ item.message.date }}</div>
                                </div>
                                <div class="message">
                                    {{ item.message.text }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </aside>

        <div class="not-selected" v-if="!room">
            <img src="/img/empty/message.svg" alt="empty">
        </div>
        <Message :id="room.id" :back="back" :key="room.id" v-if="room" />
    </div>
</template>

<script>
import ApiService from "@/services/api.service";
import {mapGetters} from "vuex";
import {SET_UI_VISIBLE_PLUGIN} from "@/store/mutations.type";
import Message from "@/app/Pages/Misc/Talks/Message";

export default {
    name: 'Index',
    components: {Message},
    computed: mapGetters(['account', 'ui']),
    data() {
        return {
            path: 'platform/misc/talks',
            pageLoading : false,
            filter: {
                //
            },

            items: null,
            searchItems : null,

            roomIndex : null,
            room : null
        }
    },
    mounted() {
        this.$store.commit(SET_UI_VISIBLE_PLUGIN, false)

        this.index()
    },
    methods: {
        search() {
            if (!this.filter.search) {
                this.searchItems = this.items;
                return false;
            }

            this.searchItems = this.lodash.filter(this.items, (o) => {
                let name = URLify(o.title);
                let search = URLify(this.filter.search);
                return name.includes(search);
            })
        },

        index() {
            this.pageLoading = true;

            ApiService.setHeader();
            ApiService.get(this.path)
                .then(({data}) => {
                    this.items = data.data;
                    this.searchItems = data.data;
                    this.pageLoading = false;
                })
        },

        handleSelectedRoom(index, room) {
            this.roomIndex = index;
            this.room = room;
            this.room.is_read = true;
        },

        back() {
            this.roomIndex = null;
            this.room = null;
        }
    }
}
</script>
