<template>
    <div class="multiple-reading page-body">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in response.pages" :key="index">
                <div class="multiple-reading-page-item">
                    <img :src="item.image" alt="cover">

                    <div class="caption" :class="{title : index === 0}" v-if="item.description">
                        <div class="text">
                            <div v-html="item.description"></div>
                        </div>
                    </div>

                    <div class="sound" v-if="item.sound && activeIndex === index">
                        <audio :ref="'PageSound'+index" :src="item.sound" autoplay />
                    </div>
                </div>
            </swiper-slide>

            <div class="swiper-navigation">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'

export default {
    name: 'MultipleReading',
    props : ['response'],
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOptions: {
                direction: "vertical",
                slidesPerView: 1,
                spaceBetween: 30,
                grabCursor: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 20000,
                },
                effect: 'fade',
                mousewheel: true,
                keyboard:true,
            },
            activeIndex : 0
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.mySwiper.swiperInstance.on('slideChange', (event) => {
               this.activeIndex = parseInt(this.$refs.mySwiper.swiperInstance.activeIndex);
            });
        }, 1000)
    },
    methods: {
        //
    }
}
</script>
