<template>
    <div class="column-wrp" v-loading="pageLoading">
        <div class="head">
            <div class="title">{{ $t('auth.reset_password') }}</div>
        </div>
        <el-form :model="form" label-position="top" ref="AuthForm" @submit.native.prevent="store">
            <el-form-item>
                <el-input v-model="form.email" type="email" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_email')" disabled />
            </el-form-item>

            <el-form-item :label="$t('inputs.your_new_password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                <el-input type="password" v-model="form.password" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_new_password')" show-password />
            </el-form-item>

            <el-form-item :label="$t('inputs.your_new_password_repeat')" :class="{ 'is-error': submitted && $v.form.password_confirmation.$error }">
                <el-input type="password" v-model="form.password_confirmation" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_new_password_repeat')" show-password />
            </el-form-item>

            <el-button native-type="submit" class="fluid" type="primary" :loading="loading" :disabled="loading">
                {{ $t('btn_send') }}
            </el-button>
        </el-form>
    </div>
</template>

<script>
import {maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {STORE_RESET_PASSWORD} from "@/store/actions.type";

export default {
    name: 'ResetPasswordAuth',
    data() {
        return {
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                token: null,
                email : null
            }
        }
    },
    validations: {
        form: {
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password_confirmation: {required, sameAsPassword: sameAs('password')},
        }
    },
    computed: mapGetters(['ui']),
    mounted() {
        this.form.token = this.$route.query.token;
        this.form.email = this.$route.query.email;
    },
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(STORE_RESET_PASSWORD, this.form)
                .then(() => {
                    this.pageLoading = true;

                    setTimeout(() => {
                        window.location = '#/auth/login'
                    }, 2000)
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
