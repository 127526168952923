<template>
    <page :title="$route.meta.title">
        <div class="page reading-page" v-loading="pageLoading">
            <div class="page-body">
                <div class="box">
                    <empty alias="reading" title="report" v-if="!pageLoading && !elementSize(items(storePath(path)))" />
                    <div class="box-body rows">
                        <template v-for="(item, index) in items(storePath(path))">
                            <a class="row cursor-pointer" :href="getUrl(item.id)" target="_blank" >
                                <div class="content">
                                    <div class="media">
                                        <svg-icon src="app-words" />
                                    </div>
                                    <div class="text">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="metas">
                                            <div class="meta">
                                                <icon source="calendar-outline" />
                                                {{ item.date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" />
                                </div>
                            </a>
                        </template>
                    </div>
                </div>
                <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
            </div>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'Index',
    data() {
        return {
            path: 'platform/student/reports',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        handleSelectedProduct(productIds) {
            this.filter.products = productIds;
            this.page = 1;
            this.index();
        },

        handleSelectedTab(value) {
            this.filter.tab = value;
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, products : this.filter.products, title : this.filter.title})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        getUrl (id) {
            return this.$store.state.ui.ui.brand.web_site_url + 'exports/users/individual-assessment-reports/' + id;
        }
    }
}
</script>
