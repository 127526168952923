import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Exams/Index";
import Show from "@/app/Pages/Exams/Show";
import Start from "@/app/Pages/Exams/Start";
import Report from "@/app/Pages/Exams/Report";

export default {
    path: '/exams',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Denemeler',
    },
    children: [
        {
            name: 'app.exams.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Denemeler',
            },
        },
        {
            name: 'app.exams.start',
            path: ':id/start',
            component: Start,
            meta: {
                auth: true,
                title: 'Deneme',
            },
        },
        {
            name: 'app.exams.report',
            path: ':id/report',
            component: Report,
            meta: {
                auth: true,
                title: 'Rapor',
            },
        },
        {
            name: 'app.exams.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Deneme',
            },
        },
    ]
}
