<template>
    <div class="action">
        <template v-if="lesson.status === 'wait'">
            <LessonCountdown :now="lesson.now_unix" :start="lesson.start_unix" :link="'/lessons/' + lesson.id + '/live'" />
        </template>

        <template v-if="lesson.status === 'live'">
            <el-tooltip :content="$t('lessons.actions.live')">
                <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" @click="goRoute('/lessons/' + lesson.id + '/live')" />
            </el-tooltip>
        </template>

        <template v-if="lesson.status === 'recording'">
            <div class="wait">
                {{ $t('lessons.actions.recording') }}
            </div>
        </template>

        <template v-if="lesson.status === 'record'">
            <el-tooltip :content="$t('lessons.actions.record')">
                <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" @click="goRoute('/lessons/' + lesson.id)" />
            </el-tooltip>
        </template>
    </div>
</template>

<script>
import LessonCountdown from "@/app/Pages/Lessons/_parts/Countdown";

export default {
    name: 'LessonAction',
    components: {LessonCountdown},
    props: ['lesson'],
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
