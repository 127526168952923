<template>
    <div class="box">
        <div class="box-head">
            <div class="title">{{ $t('account.my_password') }}</div>
        </div>
        <div class="box-body">
            <el-form :model="form" label-position="top" @submit.native.prevent="store">
                <el-form-item :label="$t('inputs.your_password')" :class="{ 'is-error': submitted && $v.form.old_password.$error }">
                    <el-input v-model="form.old_password" :placeholder="$t('inputs.your_password')" type="password" prefix-icon="el-icon-key" show-password/>
                </el-form-item>
                <el-form-item :label="$t('inputs.your_new_password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                    <el-input v-model="form.password" :placeholder="$t('inputs.your_new_password')" type="password" prefix-icon="el-icon-key" show-password/>
                </el-form-item>
                <el-form-item :label="$t('inputs.your_new_password_repeat')" :class="{ 'is-error': submitted && $v.form.password_confirmation.$error }" class="margin-bottom-0">
                    <el-input v-model="form.password_confirmation" :placeholder="$t('inputs.your_new_password_repeat')" prefix-icon="el-icon-key" type="password" show-password />
                </el-form-item>
            </el-form>
        </div>

        <div class="box-footer">
            <el-button class="fluid" type="primary" icon="el-icon-check" :loading="loading" :disabled="loading" @click="update">
                {{ $t('btn_update') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import {maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {UPDATE_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'PasswordAccount',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            }
        }
    },
    validations: {
        form: {
            old_password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password_confirmation: {required, sameAsPassword: sameAs('password')},
        }
    },
    methods: {
        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.process = 'password';

            this.$store
                .dispatch(UPDATE_ACCOUNT, this.form)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
