import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/LevelingExams/Index";
import Start from "@/app/Pages/LevelingExams/Start";
import Report from "@/app/Pages/LevelingExams/Report";

export default {
    path: '/leveling-exams',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Seviye Tespit Sınavı',
    },
    children: [
        {
            name: 'app.leveling_exams.index',
            path: '',
            component: Index,
            meta: {
                auth: false,
                title: 'Seviye Tespit Sınavı',
            },
        },
        {
            name: 'app.leveling_exams.report',
            path: ':id/report',
            component: Report,
            meta: {
                auth: true,
                title: 'Rapor - Seviye Tespit Sınavı',
            },
        },
        {
            name: 'app.leveling_exams.show',
            path: ':id',
            component: Start,
            meta: {
                auth: true,
                title: 'Seviye Tespit Sınavı',
            },
        },
    ]
}
