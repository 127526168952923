<template>
    <div class="box schedule" v-loading="pageLoading">
        <template v-if="response">
            <div class="box-head">
                <div class="title">Dersler</div>
                <div class="actions">
                    <div class="navigation">
                        <div class="nav-title">
                            {{ response.date_view }}
                        </div>
                        <el-date-picker
                            ref="SchedulePicker"
                            @change="index"
                            v-model="currentDate"
                            format="dd MMMM yyyy"
                            type="week"
                            :clearable="false"
                            :picker-options="datePickerOptions"
                            placement="left"
                        />
                    </div>
                </div>
            </div>
            <div class="box-body">
                <div class="days">
                    <template v-for="(item, index) in response.items">
                        <div class="day" :key="index" :class="{active : item.is_active}">
                            <div class="number">
                                <div class="text">{{ item.title }}</div>
                                <div class="title">{{ item.day }}</div>
                                <div class="active" v-if="item.is_active">Bugün</div>
                            </div>
                            <div class="events">
                                <template v-for="(event, eventIndex) in item.events">
                                    <div class="event" :key="eventIndex" @click="goRoute('/lessons/' + event.id)">
                                        <div class="name">{{ event.title }}</div>
                                        <div class="metas">
                                            <div class="meta teacher">
                                                {{ event.teacher }}
                                            </div>
                                            <div class="meta time">
                                                {{ event.times[0] }} - {{ event.times[1] }}
                                            </div>
                                            <div class="meta duration">
                                                {{ event.duration }} dk.
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: 'Index',
    data() {
        return {
            path: 'platform/student/misc/schedules',
            pageLoading: true,
            response: null,
            currentDate: null
        }
    },
    created() {
        this.index();
    },
    methods: {

        setCurrentWeek() {
            this.currentDate = null;
            this.index();
        },

        index(currentDate) {
            this.pageLoading = true;

            let date = null;

            if (currentDate) {
                date = this.moment(currentDate).format('YYYY-MM-DD')
            }

            ApiService.setHeader();
            ApiService.get(this.path, {params: {date: date}})
                .then(({data}) => {
                    this.response = data.data;
                    this.currentDate = data.data.date;
                    this.pageLoading = false;
                })
        },
    }
}
</script>
