<template>
    <page :title="$route.meta.title">
        <template #actions>
            <div class="breadcrumb-wrp">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/dashboard' }">
                        {{ $t('menu.dashboard') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/account/me' }" v-if="$route.path !== '/account/me'">
                        {{ $t('account.menu.my_account') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        {{ $route.meta.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </template>

        <div class="page account" v-if="account">
            <div class="page-wrp">
                <div class="page-content">
                    <div class="account-head">
                        <route-dropdown :items="accountMenus" />
                    </div>
                    <div class="page-body is-head">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {CHANGE_AVATAR_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'AccountLayout',
    computed: mapGetters(['account', 'ui']),
    data() {
        return {
            loading: false,
            accountMenus : [
                {
                    link : '/account/me',
                    icon : 'account-outline',
                    lang : 'account.menu.my_account'
                },
                {
                    link : '/account/orders',
                    icon : 'cart-outline',
                    lang : 'account.menu.my_orders'
                },
                {
                    link : '/account/addresses',
                    icon : 'map-outline',
                    lang : 'account.menu.my_address'
                },
                {
                    link : '/account/discounts',
                    icon : 'sale',
                    lang : 'account.menu.my_discount'
                },
                {
                    link : '/account/notifications',
                    icon : 'bell-ring-outline',
                    lang : 'account.menu.my_notification'
                },
            ],
        }
    },
    mounted() {
        //
    },
    methods : {
        handleChangeAvatar() {
            let formData = new FormData();
            formData.append('avatar_file', this.$refs.file.files[0]);

            this.$store
                .dispatch(CHANGE_AVATAR_ACCOUNT, formData)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
