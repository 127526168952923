<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/exams' }">
                            {{ $t('menu.exams') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page page-test page-test-report" >
                <div class="page-body">
                    <div class="test-stats">
                        <el-row :gutter="24">
                            <el-col :span="6" :xs="24">
                                <div class="stat">
                                    <div class="text">
                                        <div class="count">{{ response.correct_count }}</div>
                                        <div class="title">Doğru</div>
                                    </div>
                                    <div class="media bg-green-opack text-green">
                                        <icon source="check-circle-outline" />
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6" :xs="24">
                                <div class="stat">
                                    <div class="text">
                                        <div class="count">{{ response.wrong_count }}</div>
                                        <div class="title">Yanlış</div>
                                    </div>
                                    <div class="media bg-red-opack text-red">
                                        <icon source="alert-circle-outline" />
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6" :xs="24">
                                <div class="stat">
                                    <div class="text">
                                        <div class="count">{{ response.empty_count }}</div>
                                        <div class="title">Boş</div>
                                    </div>
                                    <div class="media bg-grey-opack text-grey">
                                        <icon source="checkbox-blank-circle-outline" />
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="6" :xs="24">
                                <div class="stat">
                                    <div class="text">
                                        <div class="count">{{ formatSecondTime(response.time) }}</div>
                                        <div class="title">Süre</div>
                                    </div>
                                    <div class="media bg-blue-opack text-blue">
                                        <icon source="clock-outline" />
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="box margin-bottom-20 test-report-tabs">
                        <div class="titles">
                            <div class="tab" :class="{active : contentTab === 'questions'}" @click="contentTab = 'questions'">
                                Cevap Anahtarı
                            </div>
                            <div class="tab" :class="{active : contentTab === 'topics'}" @click="contentTab = 'topics'">
                                Konulara Göre Başarı Oranı
                            </div>
                        </div>
                        <div class="actions" v-if="contentTab === 'questions'">
                            <div class="action" :class="{active : prefixTab === 'all'}" @click="handleChangePrefixTab('all')">
                                Tümü
                            </div>
                            <div class="action" :class="{active : prefixTab === 'correct'}" @click="handleChangePrefixTab('correct')">
                                Doğrular
                            </div>
                            <div class="action" :class="{active : prefixTab === 'wrong'}" @click="handleChangePrefixTab('wrong')">
                                Yanlışlar
                            </div>
                            <div class="action" :class="{active : prefixTab === 'empty'}" @click="handleChangePrefixTab('empty')">
                                Boşlar
                            </div>
                        </div>

                        <div class="actions" v-if="contentTab === 'topics'">
                            <div class="action" :class="{active : contentSortTab === 'best'}" @click="handleChangeContentSortTab('best')">
                                En Başarılı
                            </div>
                            <div class="action" :class="{active : contentSortTab === 'bad'}" @click="handleChangeContentSortTab('bad')">
                                En Kötü
                            </div>
                        </div>
                    </div>

                    <div class="box" v-if="contentTab === 'questions'">
                        <div class="box-body">
                            <div class="question-head-prefixs-wrp">
                                <div class="question-head-prefixs">
                                    <template v-for="(item, index) in questions">
                                        <div class="question-head-prefix" :key="index" :class="questionClass(index, item)"
                                             @click="handleChangeQuestion(index)">
                                            {{ item.prefix }}
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="question-wrp" v-if="question">
                                <div class="qeustion-wrp-head">
                                    <div class="metas">
                                        <el-tooltip content="Soruyu yanıtlama süreniz" placement="top">
                                            <div class="meta time">
                                                <i class="el-icon-time"></i> {{ formatSecondTime(question.answer.time) }}
                                            </div>
                                        </el-tooltip>
                                        <el-tooltip :content="'Bu soru %'+question.answer.rate+' oranında doğru cevaplandı'" placement="top">
                                            <div class="meta solve">
                                                %{{ question.answer.rate }}
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div class="qeustion-wrp-head-actions">
                                        <div class="action" :class="{active : question.action === 'question'}" @click="question.action = 'question'">
                                            Soru
                                        </div>
                                        <div class="action" :class="{active : question.action === 'video'}" @click="question.action = 'video'" v-if="question.question.video">
                                            Videolu Çözümü
                                        </div>
                                        <div class="action" :class="{active : question.action === 'feedback'}" @click="question.action = 'feedback'">
                                            Soru Sor
                                        </div>
                                    </div>
                                </div>

                                <div class="question-row" v-if="question.action === 'question'">
                                    <div class="question-container">
                                        <div class="question-instruction" v-if="question.instruction" v-html="question.instruction.description"></div>
                                        <div class="question-content" v-if="question.content.description" v-html="question.content.description"></div>
                                        <div class="question">
                                            <div class="prefix">
                                                {{ question.prefix }}
                                            </div>
                                            <div class="description">
                                                <div class="fluid" v-html="question.question.description"></div>
                                            </div>
                                        </div>
                                        <div class="options">
                                            <template v-for="(option, optionIndex) in question.options">
                                                <div class="option" :key="optionIndex">
                                                    <div class="prefix"
                                                         :class="{active : option.is_selected, correct : option.is_correct}">
                                                        {{ option.prefix }})
                                                    </div>
                                                    <div class="option-description">
                                                        <div class="option-description-wrp"
                                                             v-html="option.description"></div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="video-answer" v-if="question.action === 'video'">
                                    <video :src="question.question.video" ref="VideoPlayer" height="360" width="640" controls controlsList="nodownload" oncontextmenu="return false;"></video>
                                </div>

                                <div class="feedback" v-if="question.action === 'feedback'">
                                    <FeedbackQuestion :question="question" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box" v-if="contentTab === 'topics'">
                        <div class="box-body">
                            <div class="topics">
                                <el-row :gutter="24">
                                    <template v-for="(topic, index) in topics">
                                        <el-col :span="12" :xs="24" :key="index">
                                            <div class="topic">
                                                <div class="text">
                                                    <div class="title">
                                                        {{ topic.title }}
                                                    </div>
                                                    <div class="metas">
                                                        <div class="meta">
                                                            Soru Sayısı: {{ topic.count }}
                                                        </div>
                                                        <div class="meta">
                                                            <i class="el-icon-circle-check text-green"></i> Doğru:
                                                            {{ topic.correct }}
                                                        </div>
                                                        <div class="meta">
                                                            <i class="el-icon-circle-close text-red"></i> Yanlış:
                                                            {{ topic.wrong }}
                                                        </div>
                                                        <div class="meta">
                                                            <i class="el-icon-remove-outline"></i> Boş: {{ topic.empty }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="rate">
                                                    %{{ topic.rate }}
                                                </div>
                                            </div>
                                        </el-col>
                                    </template>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import FeedbackQuestion from "@/app/Pages/Tests/_parts/Feedback";

export default {
    name: 'Report',
    components: {FeedbackQuestion},
    data() {
        return {
            path: 'platform/student/tests',
            pageLoading: true,
            response: null,

            prefixTab: 'all',
            questions: [],
            questionTabIndex: null,
            question: null,

            contentTab: 'questions',
            contentSortTab: 'best',
            topics: [],
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id, process : 'report'})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));

                    this.questions = this.response.questions;
                    this.topics = this.response.topics;
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        handleChangeContentSortTab(value) {
            this.contentSortTab = value;

            if (value === 'best') {
                return this.topics = this.response.topics
            }

            this.topics = this.lodash.sortBy(this.response.topics, [(o) => {
                return o.rate;
            }]);
        },

        handleChangePrefixTab(value) {
            this.prefixTab = value;
            this.question = null;
            this.questionTabIndex = null;

            if (this.prefixTab === 'all') {
                this.questions = this.response.questions;
            } else {
                this.questions = this.lodash.filter(this.response.questions, (item) => {
                    return item.answer.status === value
                });
            }
        },

        questionClass(index, question) {
            let text = '';

            text += question.answer.status;

            if (index === this.questionTabIndex) {
                text += ' active '
            }

            return text;
        },

        handleChangeQuestion(index) {
            this.questionTabIndex = index;
            this.question = this.questions[index];
        },
    }
}
</script>
