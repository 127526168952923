<template>
    <section class="shop-page-detail" v-loading="pageLoading">
        <div v-if="response">
            <div class="product-row">
                <div class="product-row-head">
                    <div class="media">
                        <icon :source="findProductIcon(response.type)" />
                    </div>
                    <div class="text">
                        <div class="title">
                            {{ response.title }}
                        </div>
                        <template v-if="response.product.stock_status.id === ui.settings.default_stock_status_id">
                            <div class="metas" v-if="response.type === 'group'|| response.type === 'package'">
                                <div class="meta">
                                    <div class="name">
                                        {{ response.type !== 'package' ? 'Eğitmen' : 'Eğitim'}}
                                    </div>
                                    <div class="description">{{ response.teacher_count }}</div>
                                </div>
                                <div class="meta">
                                    <div class="name">Kullanım Süresi</div>
                                    <div class="description">
                                        {{ response.product.lesson_usage_time }} ay
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <Group :response="response" v-if="response.type === 'group'" />
                <Package :response="response" v-if="response.type === 'package'" />
                <Digital :response="response" v-if="response.type === 'digital'" />
                <Physical :response="response" v-if="response.type === 'physical'" />
            </div>

            <Tab :response="response" v-if="response.type !== 'physical' && response.type !== 'digital'" />
        </div>
    </section>
</template>

<script>
import Group from "@/app/Pages/Shop/_types/Group";
import Package from "@/app/Pages/Shop/_types/Package";
import Digital from "@/app/Pages/Shop/_types/Digital";
import Physical from "@/app/Pages/Shop/_types/Physical";
import Tab from "@/app/Pages/Shop/_parts/Tab";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Show',
    components: {Tab, Physical, Digital, Package, Group},
    data() {
        return {
            path: 'platform/student/shop',
            pageLoading: true,
            response: null,
        }
    },
    computed: mapGetters(['item', 'ui']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        }
    }
}
</script>
