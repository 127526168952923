<template>
    <div class="product-row-digital">
        <div class="product-row-box">
            <div class="column media">
                <div class="cover single">
                    <img :src="response.cover" alt="cover">
                </div>
            </div>
            <div class="column content">
                <div class="product-row-short-description" v-html="response.short_description "></div>
                <div class="product-row-features margin-top-20">
                    <template v-for="(feature, index) in response.features">
                        <div class="feature" :key="index">
                            <div class="media">
                                <img :src="feature.image" alt="feature">
                            </div>
                            <div class="text">
                                <div class="title">{{ feature.title }}</div>
                                <div class="description">{{ feature.description }}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="product-row-products"></div>
                <div class="product-amount-box margin-top-20">
                    <div class="text">
                        <div class="title">
                            <span>Toplam</span> Fiyat
                        </div>
                        <div class="description">
                            Ücretsiz Kargo
                        </div>
                    </div>
                    <div class="price-box">
                        <Amount :item="response.product.amount" :quantity="1" />
                    </div>
                </div>
                <div class="product-add-basket-box">
                    <div class="column">
                        <SuggestionDialog :items="response.suggestions" />
                    </div>
                    <div class="column add-basket-btn">
                        <add-to-basket :item="response" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuggestionDialog from "@/app/Pages/Shop/_parts/SuggestionDialog";
import Amount from "@/app/Pages/Shop/_parts/Amount";

export default {
    name: 'Digital',
    props: ['response'],
    components: {Amount, SuggestionDialog},
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
