<template>
    <page title="Sözlük">
        <div class="page dictionary-page-wrp">
            <div class="dictionary-page">
                <DictionaryHead />

                <div class="word-of-the-day">
                    <div class="dictionary-page-box-title">
                        <span>Günün</span> Kelimesi
                    </div>

                    <swiper ref="mySwiper" :options="swiperOptions">
                        <template v-for="(word, index) in items">
                            <swiper-slide :key="index">
                                <div class="word" @click="goRoute('/dictionary/' + word.title)">
                                    <div class="word-head">
                                        <div class="medias">
                                            <el-tooltip content="Dinle">
                                                <div class="media">
                                                    <icon source="volume-high" />
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip content="İzle">
                                                <div class="media">
                                                    <icon source="play" />
                                                </div>
                                            </el-tooltip>
                                            <div class="text">
                                                <div class="titles">
                                                    <div class="name">Dog {{ index }}</div>
                                                    <div class="type">v</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="word-body">
                                        <div class="translate">kopek</div>
                                        <div class="sentence">gizli oylama sistemi veya durumu</div>
                                        <div class="sentence">gizli oylama sistemi veya durumu</div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </template>

                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>

                <div class="history">
                    <div class="dictionary-page-box-title">
                        <span>Son </span> Aranan Kelimeler
                    </div>
                    <div class="word-list-rows margin-top-20">
                        <el-row :gutter="24">
                            <template v-for="(word, index) in items">
                                <el-col :span="12" :xs="24" :key="index">
                                    <Word :item="word" :key="word.id" @click="goRoute('/dictionary/' + word.title)" />
                                </el-col>
                            </template>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
import DictionaryHead from "@/app/Pages/Dictionary/_parts/Head";
import Word from "@/app/Pages/WordLists/_parts/Word";

export default {
    name: 'Index',
    components: {
        Word,
        DictionaryHead,
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOptions: {
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                },
                effect: 'coverflow',
                grabCursor: true,
                loop: true,
                centeredSlides: true,
                keyboard: true,
                slidesPerView: 'auto',
                speed: 500,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 94,
                    depth: 45,
                    modifier: 3,
                    slideShadows: false
                },
                simulateTouch: true,
            },
            items: [
                {
                    title: 'pika',
                    type: 'pika',
                    media: {
                        //
                    }
                },
                {
                    title: 'aaa',
                    type: 'pika',
                    media: {
                        //
                    }
                },
                {
                    title: 'cccc',
                    type: 'pika',
                    media: {
                        //
                    }
                }
            ],
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
