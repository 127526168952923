<template>
    <div>
        <markdown-editor v-model="container[model]" toolbar="bold italic heading | image link | numlist bullist code quote" />
        <div class="markdown-preview" v-html="viewer()"></div>
    </div>
</template>

<script>

const marked = require('marked');

export default {
    name: 'Editor',
    props: ['container', 'model'],
    methods: {
        viewer() {
            if (!this.container[this.model]) {
                return '';
            }

            return marked(this.container[this.model]);
        }
    },
};
</script>
