<template>
    <div class="auth" v-if="ui">
        <div class="auth-box">
            <div class="column left">
                <div class="logo">
                    <router-link to="/">
                        <img :src="publicFilePath(ui.view.logo_light)" alt="logo">
                    </router-link>
                </div>
                <div class="img-wrp">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <template v-for="banner in ui.view.auth_sliders">
                            <swiper-slide :key="banner" v-if="banner">
                                <img :src="banner" alt="slider">
                            </swiper-slide>
                        </template>
                    </swiper>
                    <div class="caption" v-html="ui.view.text_platform_auth"></div>
                </div>
            </div>
            <div class="column right">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'

export default {
    name: 'AuthLayout',
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    computed: mapGetters(['ui']),
    data() {
        return {
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                },
                effect: 'fade',
            }
        }
    }
}
</script>
