<template>
    <div class="basket-item " @click="index" v-loading="loading">
        <el-tooltip :content="$t('shop.basket.title')" placement="bottom">
            <div class="mobile-item dot-badge-container">
                <icon source="shopping-outline" />
                <div class="dot-badge">{{ quantity }}</div>
            </div>
        </el-tooltip>

        <el-drawer :title="$t('shop.basket.title')" :visible.sync="visible" append-to-body width="50%">
            <div class="popover-basket">
                <div class="popover-notification-container" v-loading="pageLoading">
                    <div class="body">
                        <empty alias="basket" title="basket" size="popover" v-if="summary && !elementSize(summary.products) && !pageLoading" />
                        <div v-if="summary && elementSize(summary.products)">
                            <template v-for="(item, index) in summary.products">
                                <div class="item" :key="index">
                                    <div class="content">
                                        <div class="media">
                                            <icon :source="findProductIcon(item.type)" />
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ item.title }}</div>
                                            <div class="description" v-if="item.variant_type">
                                                <template v-if="item.variant_type === 'live_and_record'">
                                                    <span>Canlı</span> ve Kayıttan
                                                </template>
                                                <template v-if="item.variant_type === 'record'">
                                                    Sadece <span>Kayıttan</span>
                                                </template>
                                            </div>
                                            <div class="description" v-if="!item.variant_type">
                                                {{ item.quantity }} Adet
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-column">
                                        <el-tooltip :content="$t('btn_delete')" placement="left">
                                            <el-button plain size="small" type="danger" icon="el-icon-delete" @click="destroy(item.id)" />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="footer" v-if="summary && elementSize(summary.products)">
                        <div class="info">
                            <div class="name">{{ $t('inputs.sub_total') }}</div>
                            <div class="total">{{ $n(summary.total, 'currency') }}</div>
                        </div>

                        <el-button plain type="green" class="fluid" @click="goRoute('/checkout/summary')">
                            {{ $t('shop.basket.btn') }}
                        </el-button>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
    DESTROY_CHECKOUT_ADD_TO_BASKET,
    GET_CHECKOUT_QUANTITY,
    GET_CHECKOUT_SUMMARY
} from "@/store/actions.type";

export default {
    name: 'BasketHeader',
    data() {
        return {
            loading: false,
            pageLoading: false,
            visible : false
        }
    },
    computed: mapGetters(['account', 'quantity', 'summary']),
    mounted() {
        this.$store.dispatch(GET_CHECKOUT_QUANTITY)
    },
    methods: {
        index() {
            this.loading = true;

            this.$store
                .dispatch(GET_CHECKOUT_SUMMARY)
                .then(() => {
                    this.loading = false;
                    this.visible = true
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.pageLoading = true;

                    this.$store
                        .dispatch(DESTROY_CHECKOUT_ADD_TO_BASKET, id)
                        .then(() => {
                            this.index();
                        })
                        .catch(() => {
                            this.pageLoading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        }
    }
}
</script>
