<template>
    <div class="wordy-app">
        <div class="app-type-image">
            <div class="items" >
                <template v-for="(word, index) in response.words">
                    <div class="item" :class="{front : index === activeIndex, back : index === (activeIndex + 1)}" :key="index" @mouseover="mouseIn" @mouseleave="mouseOut">
                        <audio :ref="'AudioPlayer' + index" controls :src="word.media.sound" style="display: none"></audio>
                        <div class="item-card">
                            <div class="media">
                                <img :src="word.media.image" alt="image">
                                <div class="progress-bg"></div>
                                <div class="progress" :style="{width : (activeIndex === index ? (20 * time) : 0) + '%'}"></div>
                            </div>
                            <div class="text">
                                <div class="title">
                                    {{ word.title }} <div class="type">adj</div>
                                </div>
                                <div class="description">
                                    {{ word.translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="navigation">
                <div class="column prev" @click="prevBtn">
                    <icon source="chevron-left"></icon>
                </div>
                <div class="column next" @click="nextBtn">
                    <icon source="chevron-right"></icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageAppWordy',
    props: ['response', 'next'],
    data() {
        return {
            activeIndex : 0,
            time : 0,
            timer : null,
            pause : false
        }
    },
    mounted() {
        this.timer = setInterval(() => {

            if (this.time >= 5) {
                this.time = 0;
                this.nextBtn();
            }

            if (!this.pause) {
                this.time++;
            }
        }, 1000)

        this.play();
    },
    methods: {
        prevBtn() {
            let index = this.activeIndex - 1

            if (!this.response.words[index]) {
                return false;
            }

            this.activeIndex--
            this.time = 0;

            return this.play()
        },

        nextBtn() {
            let index = this.activeIndex + 1

            if (!this.response.words[index]) {
                return this.next('intro-image-app');
            }

            this.activeIndex++;

            this.time = 0;

            return this.play()
        },

        play() {
           setTimeout(() => {
               let word = this.response.words[this.activeIndex];

               if (!word.media.sound) {
                   return window.speechSynthesis.speak(new SpeechSynthesisUtterance(word.title));
               }

               this.$refs['AudioPlayer' + this.activeIndex][0].play()
           }, 1000)
        },

        mouseIn() {
            this.pause = true;
        },

        mouseOut() {
            this.pause = false;
        }
    }
}
</script>
