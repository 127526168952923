<template>
    <div class="player">
        <template v-if="lesson.record.type === 'iframe'">
            <iframe allow="camera; microphone" allowfullscreen :src="lesson.record.link" frameborder="0"></iframe>
        </template>
        <template v-if="lesson.record.type === 'mp4'">
            <div class="cover" v-show="visibleCover">
                <img src="/img/lesson-video-cover.jpg" alt="cover">
                <div class="caption-wrp">
                    <div class="play-btn" @click="handleClickPlay">
                        <icon source="play" />
                    </div>
                </div>
            </div>
            <div class="content">
                <video ref="VideoPlayer" :src="lesson.record.link" controls controlsList="nodownload" oncontextmenu="return false;"></video>
            </div>
        </template>
        <template v-if="lesson.record.type === 'hls'">
            <div class="cover" v-show="visibleCover">
                <img src="/img/lesson-video-cover.jpg" alt="cover">
                <div class="caption-wrp">
                    <div class="play-btn" @click="handleClickHlsPlay">
                        <icon source="play" />
                    </div>
                </div>
            </div>
            <div class="content">
                <video ref="VideoPlayer" controls controlsList="nodownload" oncontextmenu="return false;"></video>
            </div>
        </template>
    </div>
</template>

<script>
import Hls from 'hls.js'

export default {
    name: 'LessonPlayer',
    props: ['lesson'],
    data() {
        return {
            visibleCover: true,
        }
    },
    methods: {
        handleClickPlay() {
            this.visibleCover = false;

            if (this.$refs.VideoPlayer) {
                this.listenerVideoPlayer();
            }
        },

        listenerVideoPlayer() {
            this.$refs.VideoPlayer.play();
        },

        handleClickHlsPlay() {
            this.visibleCover = false;
            this.$refs.VideoPlayer.play();

            if (Hls.isSupported()) {
                let video = this.$refs.VideoPlayer;
                let hls = new Hls();
                hls.attachMedia(video);
                hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                    hls.loadSource(this.lesson.record.link);
                });
            } else if (this.$refs.VideoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
                this.$refs.VideoPlayer.src = this.lesson.record.link;
            }
        },
    }
}
</script>
