<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/word-lists' }">
                            {{ $t('menu.word_list') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item v-if="response">
                            {{ response.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page" >
                <div class="page-body">
                    <IntroWordy :response="response" :next="next" v-if="tab === 'intro'" />
                    <ImageAppWordy :response="response" :next="next" v-if="tab === 'image-app'" />
                    <IntroImageApp :response="response" :next="next" v-if="tab === 'intro-image-app'" />
                </div>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import IntroWordy from "@/app/Pages/WordLists/_parts/Intro";
import ImageAppWordy from "@/app/Pages/WordLists/_parts/ImageApp";
import IntroImageApp from "@/app/Pages/WordLists/_parts/IntroImageApp";

export default {
    name: 'Show',
    components: {
        IntroImageApp,
        ImageAppWordy,
        IntroWordy,
    },
    data() {
        return {
            path: 'platform/student/word-list',
            pageLoading: true,
            response: null,
            tab : 'intro'
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },
        next(value) {
            this.tab = value;
        }
    }
}
</script>
