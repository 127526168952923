<template>
    <div class="print-box" v-if="elementSize(items)" @click="dialogVisible = true" :class="{active : total}">
        <div class="text">
            <template v-if="!total">
                {{ title }}
            </template>
            <template v-if="total">
                {{ count }}x Ürün Seçildi + {{ $n(total, 'currency') }}
            </template>
        </div>
        <div class="action">
            <icon source="plus" />
        </div>

        <el-dialog :title="title" :visible.sync="dialogVisible" width="80%" append-to-body class="suggestion-dialog">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <div class="products" v-if="type !== 'package'">
                <template v-for="(item, index) in items">
                    <div class="item" :key="index">
                        <div class="content">
                            <div class="media">
                                <icon :source="findProductIcon(item.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <div class="metas">
                                    <template v-for="(feature, featureIndex) in item.features">
                                        <el-tooltip :content="feature.title">
                                            <div class="meta" :key="featureIndex">
                                                <img :src="feature.image" alt="feature">
                                                {{ feature.description }}
                                            </div>
                                        </el-tooltip>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="action">
                            <el-input-number v-model="item.product.quantity" :step="1" :min="0" class="product-quantity"
                                             @change="calculate" />
                        </div>
                        <div class="price">
                            <Amount :item="item.product.amount" :quantity="item.product.quantity" />
                        </div>
                    </div>
                </template>
            </div>

            <div class="products" v-if="type === 'package'">
                <template v-for="(item, index) in items">
                    <div class="item" :key="index">
                        <div class="content">
                            <div class="media">
                                <icon :source="findProductIcon(item.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <template v-for="(group, featureIndex) in item.features">
                                    <div class="metas" :key="featureIndex">
                                        <template v-for="(feature, featureItemIndex) in group.items">
                                            <el-tooltip :content="feature.title">
                                                <div class="meta" :key="featureItemIndex">
                                                    <img :src="publicFilePath(feature.image)" alt="feature">
                                                    {{ feature.description }}
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="action">
                            <el-input-number v-model="item.quantity" :step="1" :min="0" class="product-quantity" @change="packageCalculate" />
                        </div>
                        <div class="price">
                            <Amount :item="item.amount" :quantity="item.quantity" />
                        </div>
                    </div>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <div class="total">
                    <div class="title">Eklenecek Tutar</div>
                    <div class="price">
                        {{ $n(total, 'currency') }}
                    </div>
                </div>
                <div class="btn">
                    <el-button icon="el-icon-check" type="primary" class="fluid" @click="store">
                        Ürünleri Seç
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Amount from "@/app/Pages/Shop/_parts/Amount";

export default {
    name: 'SuggestionDialog',
    components: {Amount},
    props: {
        title: {
            default: 'Basılı ürünlerden seç'
        },
        type: {
            default: 'default'
        },
        items: {}
    },
    data() {
        return {
            dialogVisible: false,
            total: 0,
            count: 0,
        }
    },
    mounted() {
        if (this.type === 'package') {
            this.packageCalculate();
        } else {
            this.calculate();
        }
    },
    methods: {
        store() {
            this.dialogVisible = false;

            if (this.type === 'package') {
                this.packageCalculate();
            } else {
                this.calculate();
            }
        },

        calculate() {
            this.total = 0;
            this.count = 0;

            this.lodash.forEach(this.items, (item) => {

                let quantity = parseInt(item.product.quantity);

                this.total += quantity * parseFloat(item.product.amount.price);

                if (quantity) {
                    this.count += quantity;
                }
            })
        },

        packageCalculate() {
            this.total = 0;
            this.count = 0;

            this.lodash.forEach(this.items, (item) => {

                let quantity = parseInt(item.quantity);

                this.total += quantity * parseFloat(item.amount.price);

                if (quantity) {
                    this.count += quantity;
                }
            })
        }
    }
}
</script>
