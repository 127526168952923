<template>
    <page>
        <template #actions>
            <aside class="shop-page-navigation">
                <div class="box">
                    <div class="box-body">
                        <div class="items" :class="'size-' + elementSize(ui.shop_categories)">
                            <template v-for="(category, index) in ui.shop_categories">
                                <div class="item" :key="index">
                                    <router-link :to="'/shop/' + category.slug">
                                        <icon :source="category.icon" />
                                        <span>{{ category.title }}</span>
                                    </router-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </aside>
            <route-dropdown :items="ui.shop_categories" />
        </template>
        <div class="page shop-page" v-if="ui">
            <div class="page-wrp">
                <div class="page-content">
                    <div class="page-body is-head">
                        <router-view :key="$route.fullPath" />
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'AccountLayout',
    computed: mapGetters(['ui']),
}
</script>
