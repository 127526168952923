<template>
    <div class="page forum-page" v-loading="pageLoading">
       <template v-if="response">
           <div class="page-head">
               <div class="text">
                   <div class="title">{{ response.title }}</div>
               </div>
               <div class="actions">
                   <div class="category">
                       <span :style="{'background-color' : response.category.color}"></span> {{ response.category.title }}
                   </div>
                   <el-button icon="mdi mdi-comment-edit-outline" plain type="primary" size="small" @click="$refs.Comments.create()">
                       Cevapla
                   </el-button>
               </div>
           </div>
           <div class="page-body is-head">
               <article class="box">
                   <div class="box-body single-topic active">
                       <div class="media">
                           <avatar :name="response.user.name" :source="response.user.avatar" />
                       </div>
                       <div class="content">
                           <div class="head">
                               <div class="text">
                                   <div class="name">{{ response.user.name }}</div>
                                   <div class="description">{{ response.user.role }}</div>
                               </div>
                               <div class="date">
                                   <icon source="clock-outline" />
                                   {{ response.date }}
                               </div>
                           </div>
                           <div class="body" v-html="viewer(response.description)"></div>
                       </div>
                   </div>
               </article>

               <ForumComment ref="Comments" :id="response.id" />
           </div>
       </template>
    </div>
</template>

<script>
import Report from "@/app/Pages/Forums/_parts/Report";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import ForumComment from "@/app/Components/Comments/Forum";

const marked = require('marked');

export default {
    name: 'Topic',
    components: {
        ForumComment,
        Report
    },
    data() {
        return {
            path: 'platform/misc/forums',
            pageLoading: true,
            response: null
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.topicId})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        viewer(text) {
            return marked(text);
        },
    }
}
</script>
