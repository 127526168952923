<template>
    <div class="box">
        <div class="box-head">
            <div class="description">
                <span class="link font-weight-500 text-secondary cursor-pointer" @click="contractDialog('acik_riza')">Rıza Metni</span>
                kapsamında önemli kampanyalardan haberdar olmak için tercih ettiğiniz yöntemleri belirtebilirsiniz.
            </div>
        </div>
        <div class="box-body notifications" v-loading="pageLoading">
            <template v-for="(parent, parentIndex) in items(storePath(path))">
                <div class="notification-item" :key="parentIndex">
                    <div class="notification-item-head">
                        <div class="title">
                            {{ $t('account.notifications.titles.' + parent.id) }}
                        </div>
                        <div class="actions">
                            <div class="action">
                                Sms
                                <el-tooltip content="Bildirimler icin kayitli telefon numarama sms gonder.">
                                    <div>
                                        <icon source="information-outline" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="action">
                                E-Posta
                                <el-tooltip content="Bildirimler icin kayitli adresime eposta gonder.">
                                    <div>
                                        <icon source="information-outline" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="action">
                                Mobil
                                <el-tooltip content="Bildirimler icin uygulama uzerinden bana bildirim gonder.">
                                    <div>
                                        <icon source="information-outline" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="notification-item-body">
                        <template v-for="(item, index) in parent.items">
                            <div class="item" :key="index">
                                <div class="text">
                                    <div class="title">{{ item.title }}</div>
                                </div>
                                <div class="actions">
                                    <div class="action">
                                        <el-switch v-model="item.is_sms" active-color="#13ce66" />
                                    </div>
                                    <div class="action">
                                        <el-switch v-model="item.is_email" active-color="#13ce66" />
                                    </div>
                                    <div class="action">
                                        <el-switch v-model="item.is_mobile" active-color="#13ce66" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
        <div class="box-footer" v-if="!pageLoading">
            <el-button class="fluid" type="primary" icon="el-icon-check" @click="update" :loading="loading"
                       :disabled="loading">
                {{ $t('btn_save') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'NotificationAccount',
    data() {
        return {
            path: 'platform/account/notifications',
            filter: {
                //
            },
            page: 1,

            pageLoading: true,
            loading: false,
            submitted: false,
            dialogVisible: false,
            form: {
                //
            },
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.submitted = true;
            this.loading = true;
            this.form.notifications = [];
            this.form.path = this.path;
            this.form.id = 1;

            this.lodash.forEach(this.items(this.storePath(this.path)), (parent) => {
                this.lodash.forEach(parent.items, (item) => {
                    this.form.notifications.push({
                        alias: item.id,
                        is_sms: item.is_sms,
                        is_email: item.is_email,
                        is_mobile: item.is_mobile,
                    });
                })
            });

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
