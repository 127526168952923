<style lang="scss" rel="stylesheet/scss">
@import "./resources/scss/Bootstrap";
</style>

<template>
    <div id="Bootstrap" :class="mobilePageClass">
        <transition name="fade">
            <div class="preloader-container" v-if="$store.state.preloading">
                <div class="container">
                    <div class="dot dot-1"></div>
                    <div class="dot dot-2"></div>
                    <div class="dot dot-3"></div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        </transition>

        <router-view v-show="!$store.state.preloading" />

        <Contract />
    </div>
</template>

<script>
import { isMobile, isTablet, isMobileSafari } from 'mobile-device-detect';
import {GET_UI} from "@/store/actions.type";
import Contract from "@/app/Components/Contract";

export default {
    name: 'Bootstrap',
    components: {Contract},
    data() {
        return {
            mobilePageClass : false
        }
    },
    mounted() {
        this.$store.dispatch(GET_UI);

        if (localStorage.getItem('language')) {
            this.$i18n.locale = localStorage.getItem('language');
        } else {
            this.$i18n.locale = 'tr';
        }

        if (isMobile && isMobileSafari && !isTablet) {
            this.mobilePageClass += ' is-safari'
        }

        if (!this.$route.name) {
            window.location.reload();
        }
    }
}
</script>
