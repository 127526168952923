<template>
    <div>
        <div class="box" v-loading="pageLoading">
            <div class="box-head">
                <div class="title">
                    Daha Önce Sorulan Sorular
                </div>
                <div class="actions">
                    <el-tooltip content="Yeni Soru Sor">
                        <el-button type="primary" plain icon="el-icon-plus" size="small" @click="create" />
                    </el-tooltip>
                </div>
            </div>

            <empty alias="question" title="question" v-if="!pageLoading && !elementSize(items(storePath(path)))" />

            <div class="box-body rows">
                <template v-for="(item, index) in items(storePath(path))">
                    <div class="row" :key="index" >
                        <div class="content cursor-pointer" @click="edit(item)">
                            <div class="text">
                                <div class="title">
                                    {{ item.message }}
                                </div>
                                <div class="title">
                                    {{ item.answer }}
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <el-button plain circle class="is-icon" type="danger" icon="el-icon-delete" @click="destroy(item.id)" />
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />

        <el-dialog title="Soru" :visible.sync="dialogVisible" width="40%" append-to-body>
            <el-form :model="form" label-position="top" @submit.native.prevent="store">
                <el-form-item :class="{ 'is-error': submitted && $v.form.message.$error }" class="margin-bottom-0">
                    <el-input v-model="form.message" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_message')" type="textarea" rows="6" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button type="primary" class="fluid" size="large" @click="store" :loading="loading" :disabled="loading" v-if="!form.id">
                    {{ $t('btn_send') }}
                </el-button>

                <el-button type="success" class="fluid" size="large" @click="update" :loading="loading" :disabled="loading" v-if="form.id">
                    {{ $t('btn_update') }}
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import {DESTROY_CRUD_ITEM, GET_CRUD_ITEMS, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'FeedbackQuestion',
    props : ['question'],
    data() {
        return {
            path: 'platform/student/questions',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,

            loading: false,
            submitted: false,
            form: {
                message : null,
            },

            dialogVisible : false,
        }
    },
    validations: {
        form: {
            message: {required, minLength: minLength(10)},
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, question_id : this.question.id})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create() {
            this.form = {
                //
            }

            this.dialogVisible = true;
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;
            this.form.test_id = this.$route.params.id;
            this.form.question_id = this.question.id;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.form = {
                        //
                    }

                    this.dialogVisible = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        edit(item) {
            this.form = {
                id : item.id,
                message : item.message
            };

            this.dialogVisible = true;
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.pageLoading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path, question_id : this.question.id})
                        .then(() => {
                            this.pageLoading = false;
                            this.page = 1;
                        })
                        .catch(() => {
                            this.pageLoading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        }
    }
}
</script>
