import PageLayout from "@/app/Layouts/PageLayout";
import AccountLayout from "@/app/Layouts/AccountLayout";

// Pages
import Me from "@/app/Pages/Account/Me";
import Address from "@/app/Pages/Account/Address";
import Notification from "@/app/Pages/Account/Notification";
import Logout from "@/app/Pages/Account/Logout";
import Order from "@/app/Pages/Account/Order";
import Discount from "@/app/Pages/Account/Discount";

export default {
	path: '/account',
	component: PageLayout,
	meta: {
		auth: true,
		title: 'Hesabim',
	},
	children: [
		{
			path: '',
			component: AccountLayout,
			meta: {
				auth: true,
				title: 'Hesabim',
			},
			children: [
				{
					name: 'app.account.logout.index',
					path: 'logout',
					component: Logout,
					meta: {
						auth: true,
						title: 'Cikis',
						layout: 'content'
					},
				},
				{
					name: 'app.account.me.index',
					path: 'me',
					component: Me,
					meta: {
						auth: true,
						title: 'Hesap Bilgilerim',
						icon: 'account-outline',
					},
				},
				{
					name: 'app.account.orders.index',
					path: 'orders',
					component: Order,
					meta: {
						auth: true,
						title: 'Şiparişlerim',
						icon: 'cart-outline',
					},
				},
				{
					name: 'app.account.addresses.index',
					path: 'addresses',
					component: Address,
					meta: {
						auth: true,
						title: 'Adreslerim',
						icon: 'map-outline',
					},
				},
				{
					name: 'app.account.discounts.index',
					path: 'discounts',
					component: Discount,
					meta: {
						auth: true,
						title: 'Kupon ve İndirimlerim',
						icon: 'sale',
					},
				},
				{
					name: 'app.account.notifications.index',
					path: 'notifications',
					component: Notification,
					meta: {
						auth: true,
						title: 'Bildirim Ayarları',
						icon: 'bell-ring-outline',
					},
				}
			]
		}
	]
}
