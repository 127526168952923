<template>
    <div class="addresses">
        <div class="box">
            <div class="box-head">
                <div class="title">{{ $t('account.my_addresses') }}</div>
                <div class="actions">
                    <el-tooltip :content="$t('account.create_address')" placement="left">
                        <el-button plain size="small" type="primary" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </div>
            <empty alias="address" title="address" v-if="!pageLoading && !elementSize(items(storePath(path)))" />
            <div class="box-body rows" v-loading="pageLoading">
                <template v-for="(item, index) in items(storePath(path))">
                    <div class="row" :key="index" :class="{'tertiary' : item.is_main}">
                        <div class="content">
                            <div class="media">
                                <icon source="map-outline" />
                            </div>
                            <div class="text">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="metas">
                                    <div class="meta">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <el-tooltip :content="$t('btn_edit')">
                                <el-button icon="el-icon-edit" plain size="small" type="success" @click="edit(item)" />
                            </el-tooltip>
                            <el-tooltip :content="$t('btn_delete')">
                                <el-button icon="el-icon-delete" plain size="small" type="danger" @click="destroy(item.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />

        <AddressDrawer ref="AddressDrawer" :index="index" type="account" />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import AddressDrawer from "@/app/Pages/Account/_parts/AddressDrawer";

export default {
    name: 'AddressAccount',
    components: {AddressDrawer},
    data() {
        return {
            path: 'platform/account/addresses',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },
        search() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create() {
            this.$refs.AddressDrawer.create();
        },

        edit(item) {
            this.$refs.AddressDrawer.edit(item.updated);
        },

        destroy(id) {
            this.$refs.AddressDrawer.destroy(id);
        },
    }
}
</script>
