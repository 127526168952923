<template>
    <el-form :model="form" label-position="top" id="CreditCardForm">
        <el-row :gutter="24">
            <el-col :span="14" :xs="24">
                <div class="box-sub">
                    <div class="box-sub-title"> Kart Bilgileri</div>
                    <div class="box-sub-body">
                        <el-form-item :label="$t('inputs.full_name')"
                                      :class="{ 'is-error': submitted && $v.form.name.$error }">
                            <el-input v-model="form.name" prefix-icon="el-icon-user"
                                      :placeholder="$t('inputs.full_name')" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.card_number')"
                                      :class="{ 'is-error': submitted && $v.form.number.$error }">
                            <el-autocomplete v-model="form.number" :fetch-suggestions="getInstallments"
                                             :placeholder="$t('inputs.card_number')" prefix-icon="el-icon-bank-card"
                                             v-mask="'#### #### #### ####'" class="fluid"
                                             popper-class="installment-popover" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="8" :xs="12">
                                <el-form-item :label="$t('inputs.month')"
                                              :class="{ 'is-error': submitted && $v.form.month.$error }">
                                    <el-select v-model="form.month" :placeholder="$t('inputs.month')">
                                        <el-option v-for="item in cardMonths()" :key="item.id" :label="item.title"
                                                   :value="item.id" />
                                        <template slot="prefix">
                                            <i class="el-input__icon el-icon-date"></i>
                                        </template>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8" :xs="12">
                                <el-form-item :label="$t('inputs.year')"
                                              :class="{ 'is-error': submitted && $v.form.year.$error }">
                                    <el-select v-model="form.year" :placeholder="$t('inputs.year')">
                                        <el-option v-for="item in cardYears()" :key="item.id" :label="item.title"
                                                   :value="item.id" />
                                        <template slot="prefix">
                                            <i class="el-input__icon el-icon-date"></i>
                                        </template>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8" :xs="24">
                                <el-form-item :label="$t('inputs.card_cvv')"
                                              :class="{ 'is-error': submitted && $v.form.cvv.$error }">
                                    <el-input v-model="form.cvv" prefix-icon="el-icon-key"
                                              :placeholder="$t('inputs.card_cvv')" v-mask="'###'" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
            <el-col :span="10" :xs="24">
                <div class="box-sub" v-loading="loading">
                    <div class="box-sub-title">Taksit Seçenekleri</div>
                    <div class="box-sub-body">
                        <template v-if="!form.number">
                            Taksit sayısı için lütfen kart bilgilerinizi girin.
                        </template>

                        <template v-if="form.number">
                            <template v-if="elementSize(form.number) < 19">
                                Taksit sayısı için lütfen kart bilgilerinizi girin.
                            </template>
                            <template v-if="elementSize(form.number) === 19">
                                <template v-if="!elementSize(installments) && !loading">
                                    Uzgunum kart bilgilerinize uygun taksit secenegi bulunamadi.
                                </template>
                                <template v-if="elementSize(installments) && !loading">
                                    <div class="installments" v-if="installment">
                                        <template v-for="(item, index) in installments">
                                            <div class="installment" :key="index"
                                                 :class="{active : item.id === installment.id}"
                                                 @click="handleChangeInstallment(item)">
                                                <div class="column media">
                                                    <icon source="radiobox-blank" v-if="item.id !== installment.id" />
                                                    <icon source="radiobox-marked" v-if="item.id === installment.id" />
                                                </div>
                                                <div class="column title">{{ item.title }}</div>
                                                <div class="column paid">
                                                    {{ $n(item.paid ? item.paid : item.total, 'currency') }}
                                                </div>
                                                <div class="column total">{{ $n(item.total, 'currency') }}</div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import ApiService from "@/services/api.service";
import {SET_CHECKOUT_INSTALLMENT} from "@/store/mutations.type";
import {minLength, maxLength, required} from "vuelidate/lib/validators";

export default {
    name: 'CardPaymentMethod',
    props: ['plugin', 'summary', 'store'],
    data() {
        return {
            loading: false,
            submitted: false,
            form: {},
            installments: [],
            installment: null,
        }
    },
    validations: {
        form: {
            name: {required},
            number: {required, minLength: minLength(19), maxLength: maxLength(19)},
            month: {required, minLength: minLength(2), maxLength: maxLength(2)},
            year: {required, minLength: minLength(4), maxLength: maxLength(4)},
            cvv: {required, minLength: minLength(3), maxLength: maxLength(3)},
        }
    },
    mounted() {
        this.$store.commit(SET_CHECKOUT_INSTALLMENT, null);
        this.summary();
    },
    methods: {
        getInstallments(queryString, cb) {
            if (!this.elementSize(queryString)) {
                return cb([]);
            }

            this.loading = true;

            ApiService.get('platform/student/checkout/installments?number=' + queryString + '&plugin_id=' + this.plugin.id+ '&coupon_id=' + localStorage.getItem('coupon'))
                .then(({data}) => {
                    this.loading = false;
                    this.installments = data.data;

                    if (this.elementSize(this.installments)) {
                        this.installment = this.installments[0];
                        this.$store.commit(SET_CHECKOUT_INSTALLMENT, this.installment);
                        this.summary();
                    }
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        handleChangeInstallment(item) {
            this.installment = item;
            this.$store.commit(SET_CHECKOUT_INSTALLMENT, item)
            this.summary();
        },

        validation() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.form.installment_no = this.installment.id;
            this.form.payment_plugin_id = this.plugin.id;
            this.form.type = this.plugin.name;

            return this.form;
        }
    }
}
</script>
