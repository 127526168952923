<template>
    <div class="product-row-physical">
        <div class="product-row-box">
            <div class="column media">
                <div class="cover single">
                    <img :src="response.cover" alt="cover">

                    <div class="caption" @click="dialogVisible = true" v-if="elementSize(response.images)">
                        <el-button type="primary" >
                            İçindekiler
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="column content">
                <div class="product-row-short-description" v-html="response.short_description"></div>
                <div class="product-row-features margin-top-20">
                    <template v-for="(feature, index) in response.features">
                        <div class="feature" :key="index">
                            <div class="media">
                                <img :src="feature.image" alt="feature">
                            </div>
                            <div class="text">
                                <div class="title">{{ feature.title }}</div>
                                <div class="description">{{ feature.description }}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="product-row-products"></div>
                <div class="product-amount-box margin-top-20">
                    <div class="text">
                        <div class="title">
                            <span>Toplam</span> Fiyat
                        </div>
                        <div class="description">
                            Ücretsiz Kargo
                        </div>
                    </div>
                    <div class="price-box">
                        <Amount :item="response.product.amount" :quantity="1" />
                    </div>
                </div>
                <div class="product-add-basket-box">
                    <div class="column">
                        <SuggestionDialog title="Dijital ürünlerden seç" :items="response.suggestions" />
                    </div>
                    <div class="column add-basket-btn">
                        <add-to-basket :item="response" />
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :title="response.title" :visible.sync="dialogVisible" width="70%" append-to-body class="directory-dialog">
            <div class="main-description" v-html="response.short_description"></div>
            <div class="body">
                <div class="left">
                    <template v-for="(item, index) in response.images">
                        <div class="tr" :key="index" :class="{active : directory && item.id === directory.id}" @click="directory = item">
                            <div class="title">{{ item.title }}</div>
                            <div class="description">{{ item.description }}</div>
                        </div>
                    </template>
                </div>
                <div class="right" v-if="directory.path">
                    <div class="image active">
                        <img :src="directory.path" alt="directory image">
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SuggestionDialog from "@/app/Pages/Shop/_parts/SuggestionDialog";
import Amount from "@/app/Pages/Shop/_parts/Amount";
export default {
    name: 'Physical',
    props: ['response'],
    components: {Amount, SuggestionDialog},
    data() {
        return {
            dialogVisible : false,
            directory : {
                //
            },
        }
    },
    mounted() {
        if (this.response.images) {
            this.directory = this.response.images[0];
        }
    },
    methods: {
        //
    }
}
</script>
