import PageLayout from "@/app/Layouts/PageLayout";
import Layout from "@/app/Layouts/ShopLayout";

import Index from "@/app/Pages/Shop/Index";
import Show from "@/app/Pages/Shop/Show";
export default {
    path: '/shop',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Mağaza',
    },
    children: [
        {
            path: '',
            component: Layout,
            meta: {
                auth: true,
                title: 'Mağaza',
            },
            children: [
                {
                    name: 'app.shop.index',
                    path: ':slug',
                    component: Index,
                    meta: {
                        auth: true,
                        title: 'Mağaza',
                    },
                },
                {
                    name: 'app.shop.show',
                    path: ':slug/:id',
                    component: Show,
                    meta: {
                        auth: true,
                        title: 'Mağaza',
                    },
                },

            ]
        }
    ]
}
