<template>
    <div class="page forum-page" v-loading="pageLoading">
        <div class="page-head">
            <div class="text">
                <div class="media">
                    <icon source="forum-outline" />
                </div>
                <div class="title">
                    {{ $route.meta.title }} >
                    <template v-for="(item, index) in ui.forum_categories">
                        <span v-if="item.id == $route.params.categoryId"  :key="index">
                            {{ item.title }}
                        </span>
                    </template>
                </div>
            </div>
            <div class="actions">
                <el-dropdown trigger="click" @command="handleClickCategory">
                    <span class="el-dropdown-link">
                       Kategoriler <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="category-popover-item" :command="item.id" v-for="(item, index) in ui.forum_categories" :key="index">
                            <span :style="{'background-color' : item.color}"></span> {{ item.title }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <NewTopic />
            </div>
        </div>
        <div class="page-body is-head">
            <div class="box">
                <empty alias="forum" title="forum" v-if="!pageLoading && !elementSize(items(storePath(path)))" />

                <div class="box-body topics">
                    <template v-for="(item, index) in items(storePath(path))">
                        <div class="topic" :key="index">
                            <div class="left">
                                <div class="title" @click="goRoute('/forums/' + item.category.id + '/' + item.id)">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="right">
                                <div class="user">
                                    <el-tooltip :content="item.user.name">
                                        <avatar :name="item.user.name" :source="item.user.avatar" />
                                    </el-tooltip>
                                </div>
                                <div class="replies">
                                    {{ item.comment_count }}
                                </div>
                                <div class="date">
                                    {{ item.date }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
        </div>
    </div>
</template>

<script>
import NewTopic from "@/app/Pages/Forums/_parts/NewTopic";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'Category',
    title () {
        return `${this.metaTitle()}`
    },
    components: {
        NewTopic
    },
    data() {
        return {
            path: 'platform/misc/forums',
            page: 1,
            pageLoading: true,
        }
    },
    computed: mapGetters(['pagination', 'items', 'ui']),
    mounted() {
        this.index();
    },
    methods: {
        metaTitle() {

            let category = null;

            this.lodash.forEach(this.ui.forum_categories, (item) => {
                if (item.id == this.$route.params.categoryId) {
                    category = item
                }
            })

            return category.title
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        handleClickCategory(command) {
            return this.goRoute('/forums/' + command)
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, category : this.$route.params.categoryId})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
    }
}
</script>
