import PageLayout from "@/app/Layouts/CheckoutLayout";
import Summary from "@/app/Pages/Checkout/Summary";
import Address from "@/app/Pages/Checkout/Address";
import Payment from "@/app/Pages/Checkout/Payment";
import Success from "@/app/Pages/Checkout/Success";

export default {
	path: '/checkout',
	component: PageLayout,
	meta: {
		auth: true,
		title: 'Sepet',
	},
	children: [
		{
			name: 'app.checkout.summary.index',
			path: 'summary',
			component: Summary,
			meta: {
				auth: true,
				title: 'Sepet',
			},
		},
		{
			name: 'app.checkout.address.index',
			path: 'address',
			component: Address,
			meta: {
				auth: true,
				title: 'Adres',
			},
		},
		{
			name: 'app.checkout.payment.index',
			path: 'payment',
			component: Payment,
			meta: {
				auth: true,
				title: 'Ödeme',
			},
		},
		{
			name: 'app.checkout.success.index',
			path: 'success',
			component: Success,
			meta: {
				auth: true,
				title: 'Sipariş Tamamlandı',
			},
		},
	]
}
