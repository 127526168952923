<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/documents' }">
                            {{ $t('menu.documents') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page document-page" >
                <template >
                    <div class="page-body is-head is-footer">
                        <div class="page-description" v-html="response.description"></div>
                        <div class="box pdfViewer margin-top-20" v-loading="documentLoading">
                            <pdf :src="response.src" :page="pdf.page" @num-pages="pdf.total = $event" @page-loaded="documentLoading = false" />
                        </div>
                    </div>
                    <div class="page-footer">
                        <div class="btn">
                            <el-button type="primary" icon="el-icon-download" @click="openBlankWindow(response.src)">
                                İndir
                            </el-button>
                        </div>

                        <div class="navigation">
                            <el-tooltip :content="$t('btn_nav_prev')" placement="top">
                                <div class="nav prev" @click="prevPage">
                                    <icon source="chevron-left" />
                                </div>
                            </el-tooltip>
                            <el-tooltip :content="$t('btn_nav_next')" placement="top">
                                <div class="nav next" @click="nextPage">
                                    <icon source="chevron-right" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </div>
        </page>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Show',
    components : {
        pdf
    },
    data() {
        return {
            path: 'platform/student/documents',
            pageLoading: true,
            documentLoading: true,
            response: null,

            pdf : {
                page : 1,
                total : 0
            },
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },
        prevPage() {
            if (this.pdf.page <= 1) {
                return false;
            }

            this.pdf.page --;
        },

        nextPage() {

            if (this.pdf.page === this.pdf.total) {
                return false;
            }

            this.pdf.page ++;
        },

    }
}
</script>
