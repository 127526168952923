export const SET_ERROR = 'setError';
export const SET_PRELOADING = 'setPreLoading';
export const SET_UI = 'setUi';
export const SET_UI_SETTINGS = 'setUiSettings';
export const SET_UI_CONTRACT = 'setUiContract';
export const SET_UI_VISIBLE_PLUGIN = 'setUiVisiblePlugin';
export const SET_AUTH_TOKEN = 'setAuthToken';
export const DESTROY_AUTH_TOKEN = 'destroyAuthToken';
export const SET_ACCOUNT = 'setAccount';
export const SET_ACCOUNT_CHAT_MESSAGE_COUNT = 'setAccountChatMessageCount';

// Crud
export const SET_CRUD_PAGINATION = 'setCrudPagination';
export const SET_CRUD_ITEMS = 'setCrudItems';
export const SET_CRUD_ITEM = 'setCrudItem';
export const SET_PUSH_CRUD_ITEM = 'pushCrudItem';
export const SET_UPDATE_CRUD_ITEM = 'updateCrudItem';
export const SET_DESTROY_CRUD_ITEM = 'destroyCrudItem';

// Checkout
export const SET_CHECKOUT_NAVIGATION_STEP = 'setCheckoutNavigationStep';
export const SET_CHECKOUT_QUANTITY = 'setCheckoutQuantity';
export const SET_CHECKOUT_INSTALLMENT = 'setCheckoutInstallment';
export const SET_CHECKOUT_SUMMARY = 'setCheckoutSummary';
export const SET_CHECKOUT_ADDRESS = 'setCheckoutAdress';

