<template>
    <aside class="checkout-sidebar" v-loading="pageLoading" v-if="summary && elementSize(summary.products)">
        <div class="box price-box">
            <div class="box-head">
                <div class="title">
                    <span>Sipariş</span> Özeti
                </div>
                <div class="actions">
                    {{ summary.quantity }}x Ürün
                </div>
            </div>
            <div class="box-body">
                <div class="item">
                    <div class="text">
                        <div class="title">
                            <span>Ürünler</span> Toplamı
                        </div>
                        <div class="description">( KDV Dahil )</div>
                    </div>
                    <div class="price">
                        {{ $n(summary.price, 'currency') }}
                    </div>
                </div>
                <div class="item" v-if="summary.is_shipping">
                    <div class="text">
                        <div class="title">
                            <span>Kargo</span> Tutarı
                        </div>
                        <div class="description" v-if="summary.is_free_shipping">Ücretsiz kargo</div>
                    </div>
                    <div class="price" v-if="!summary.is_free_shipping && summary.is_shipping">
                        {{ $n(summary.shipping_price, 'currency') }}
                    </div>
                    <div class="price" v-if="summary.is_free_shipping">
                        <del class="fs-14 font-weight-300">
                            {{ $n(summary.shipping_price, 'currency') }}
                        </del>
                        <div>{{ $n(0, 'currency') }}</div>
                    </div>
                </div>

                <div class="item" v-if="summary.installment_price">
                    <div class="text">
                        <div class="title">
                            <span>Taksit</span> Tutarı
                        </div>
                        <div class="description">{{ summary.installment_no}} Taksit</div>
                    </div>
                    <div class="price">
                        {{ $n(summary.installment_price, 'currency') }}
                    </div>
                </div>

                <div class="item" v-if="summary.discount_price">
                    <div class="text">
                        <div class="title">
                            <span>İndirim</span> Tutarı
                        </div>
                        <div class="description"></div>
                    </div>
                    <div class="price">
                        {{ $n(summary.discount_price, 'currency') }}
                    </div>
                </div>

                <template v-for="(item, index) in summary.campaigns">
                    <div class="item" :key="index">
                        <div class="text">
                            <div class="title">
                                <span>Kampanya</span> Tutarı
                            </div>
                            <div class="description">{{ item.title }}</div>
                        </div>
                        <div class="price">
                            {{ $n(item.price, 'currency') }}
                        </div>
                    </div>
                </template>

                <div class="item" v-if="summary.coupon">
                    <div class="text">
                        <div class="title">
                            <span>İndirim</span> Kodu
                        </div>
                        <div class="description">{{ summary.coupon.code }}</div>
                    </div>
                    <div class="price">
                        <div>
                            {{ $n(summary.coupon.price, 'currency') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-footer checkout-sidebar-box-footer">
                <div class="total">
                    <div class="text">
                        <span>Genel</span> Toplam
                    </div>
                    <div class="price">
                        {{ $n(summary.total, 'currency') }}
                    </div>
                </div>

                <slot></slot>
            </div>
        </div>

        <div class="box coupon-box">
            <el-form :model="form" label-position="top" @submit.native.prevent="store">
                <el-form-item :class="{ 'is-error': submitted && $v.form.code.$error }">
                    <el-input v-model="form.code" prefix-icon="el-icon-discount" placeholder="Promosyon kodu kullan" v-if="!summary.coupon" />
                    <el-input v-model="summary.coupon.code" prefix-icon="el-icon-discount" placeholder="Promosyon kodu kullan" disabled  v-if="summary.coupon"/>
                </el-form-item>
                <div class="action">
                    <el-button type="primary" icon="el-icon-plus" @click="store" :loading="loading" :disabled="loading" v-if="!summary.coupon"/>
                    <el-button type="danger" icon="el-icon-delete" @click="destroy" :loading="loading" :disabled="loading" v-if="summary.coupon"/>
                </div>
            </el-form>
        </div>
    </aside>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CHECKOUT_SUMMARY, GET_CHECKOUT_COUPON, DESTROY_CHECKOUT_COUPON} from "@/store/actions.type";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'SidebarCheckout',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            },

            pageLoading : false,
        }
    },
    validations: {
        form: {
            code: {required},
        }
    },
    computed: mapGetters(['summary']),
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CHECKOUT_SUMMARY)
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(GET_CHECKOUT_COUPON, this.form.code)
                .then((response) => {
                    this.loading = false;
                    this.submitted = false;

                    localStorage.setItem('coupon', response.data.id)

                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                })
        },

        destroy() {
            this.loading = true;

            this.$store
                .dispatch(DESTROY_CHECKOUT_COUPON)
                .then(() => {
                    this.loading = false;
                    localStorage.removeItem('coupon');

                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
