<template>
    <div class="product-row-package">
        <div class="product-row-box">
            <div class="column media">
                <div class="cover single">
                    <img :src="response.cover" alt="cover">

                    <div class="caption" @click="dialogVisible = true" v-if="response.product.video.source">
                        <div class="caption-action">
                            <icon source="play" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="column content">

                <div class="product-row-short-description" v-html="response.short_description"></div>

                <template v-if="response.product.stock_status.id === ui.settings.default_stock_status_id">
                    <div class="product-row-features margin-top-20">
                        <div class="feature">
                            <div class="media">
                                <icon source="calendar-month-outline" />
                            </div>
                            <div class="text">
                                <div class="title">Eğitim Tarihi</div>
                                <div class="description">{{ response.product.start_at.date }} - {{ response.product.end_at.date }}</div>
                            </div>
                        </div>
                        <div class="feature">
                            <div class="media">
                                <icon source="message-video" />
                            </div>
                            <div class="text">
                                <div class="title">Ders</div>
                                <div class="description">{{ response.package.lesson_count }}</div>
                            </div>
                        </div>
                        <div class="feature">
                            <div class="media">
                                <icon source="clock-outline" />
                            </div>
                            <div class="text">
                                <div class="title">Saat</div>
                                <div class="description">{{ response.package.lesson_hour }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="product-row-packages margin-top-20">
                        <template v-for="(content, index) in response.package.contents">
                            <div class="row" :key="index">
                                <div class="row-inline">
                                    <div class="content">
                                        <div class="title cursor-pointer" @click="handleContentDetailDialog(content)">
                                            {{ content.title }}
                                            <icon source="information-outline" ></icon>
                                        </div>
                                        <div class="teacher-row">
                                            <div class="teacher" v-if="elementSize(content.teachers) <= 1">
                                                <div class="teacher-info">
                                                    <avatar :name="content.teacher.name" :source="content.teacher.photo" />
                                                    <div class="name">
                                                        {{ content.teacher.name }}
                                                    </div>
                                                </div>
                                            </div>

                                            <el-dropdown @command="handleSelectTeacher" trigger="click" v-if="elementSize(content.teachers) > 1" placement="bottom-start">
                                            <span class="el-dropdown-link teacher cursor-pointer">
                                                <div class="teacher-info">
                                                    <avatar :name="content.teacher.name" :source="content.teacher.photo" />
                                                    <div class="name">
                                                        {{ content.teacher.name }}
                                                    </div>
                                                </div>
                                                <div class="action">
                                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                                </div>
                                            </span>
                                                <el-dropdown-menu slot="dropdown" class="teacher-item-dropdown">
                                                    <el-dropdown-item :command="{index: index, teacher : teacher}" v-for="(teacher, teacherIndex) in content.teachers" :key="teacherIndex">
                                                        <avatar :name="teacher.name" :source="publicFilePath(teacher.photo)" />
                                                        <div class="content-text">
                                                            {{ teacher.name }}
                                                        </div>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                    </div>
                                    <div class="variants">
                                        <template v-for="(variant, variantIndex) in content.teacher.variants">
                                            <el-tooltip :content="variant.variant_type === 'live_and_record' ? 'Canlı ve Kayıttan' : 'Sadece Kayıttan'">
                                                <div class="variant" :key="variantIndex" :class="{active : variant.id === content.teacher.variant.id}" @click="handleSelectVariant(content, variant)">
                                                    <template v-if="variant.variant_type === 'live_and_record'">
                                                        <svg width="101" height="78" viewBox="0 0 101 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path class="secondary" fill="currentColor" d="M89.84 0H10.159C4.558 0 0 4.557 0 10.16V64.342C0 69.944 4.558 74.502 10.16 74.502H15.66C15.775 75.102 16.034 75.67 16.428 76.158C17.151 77.058 18.227 77.576 19.381 77.576H79.939C81.093 77.576 82.169 77.058 82.888 76.162C83.284 75.672 83.544 75.101 83.659 74.5H89.843C95.446 74.5 100.002 69.943 100.002 64.342V10.16C100 4.557 95.442 0 89.84 0ZM25.958 61.641H73.357L75.217 70.006H24.099L25.958 61.641ZM75.156 54.07H24.161C21.394 54.07 19.079 56.181 18.808 58.914L17.026 66.932H10.159C8.732 66.932 7.57 65.77 7.57 64.342V10.16C7.57 8.732 8.732 7.57 10.159 7.57H89.84C91.268 7.57 92.43 8.732 92.43 10.16V64.342C92.43 65.77 91.268 66.932 89.84 66.932H82.29L80.508 58.914C80.238 56.182 77.923 54.07 75.156 54.07Z" />
                                                            <path class="tertiary" fill="currentColor" d="M33 12V54L66 33L33 12Z" />
                                                        </svg>
                                                    </template>
                                                    <template v-if="variant.variant_type === 'record'">
                                                        <svg width="101" height="78" viewBox="0 0 101 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path class="secondary" fill="currentColor" d="M89.84 0H10.159C4.558 0 0 4.557 0 10.16V64.342C0 69.944 4.558 74.502 10.16 74.502H15.66C15.775 75.102 16.034 75.67 16.428 76.158C17.151 77.058 18.227 77.576 19.381 77.576H79.939C81.093 77.576 82.169 77.058 82.888 76.162C83.284 75.672 83.544 75.101 83.659 74.5H89.843C95.446 74.5 100.002 69.943 100.002 64.342V10.16C100 4.557 95.442 0 89.84 0ZM25.958 61.641H73.357L75.217 70.006H24.099L25.958 61.641ZM75.156 54.07H24.161C21.394 54.07 19.079 56.181 18.808 58.914L17.026 66.932H10.159C8.732 66.932 7.57 65.77 7.57 64.342V10.16C7.57 8.732 8.732 7.57 10.159 7.57H89.84C91.268 7.57 92.43 8.732 92.43 10.16V64.342C92.43 65.77 91.268 66.932 89.84 66.932H82.29L80.508 58.914C80.238 56.182 77.923 54.07 75.156 54.07Z" />
                                                            <path class="tertiary" fill="currentColor" d="M68.7092 17.639C61.6242 17.639 55.6342 22.4 53.7592 28.889H44.3172C42.4422 22.4 36.4522 17.639 29.3672 17.639C20.7862 17.639 13.8052 24.621 13.8052 33.202C13.8052 41.782 20.7862 48.765 29.3672 48.765C36.7702 48.765 42.9762 43.568 44.5442 36.632H53.5332C55.0992 43.568 61.3072 48.765 68.7102 48.765C77.2912 48.765 84.2732 41.783 84.2732 33.202C84.2722 24.621 77.2912 17.639 68.7092 17.639ZM76.4802 33.201C76.4802 37.486 72.9942 40.971 68.7092 40.971C64.4242 40.971 60.9382 37.486 60.9382 33.201C60.9382 28.916 64.4232 25.43 68.7092 25.43C72.9942 25.431 76.4802 28.916 76.4802 33.201ZM37.1372 33.201C37.1372 37.486 33.6512 40.971 29.3662 40.971C25.0812 40.971 21.5962 37.486 21.5962 33.201C21.5962 28.916 25.0812 25.43 29.3662 25.43C33.6512 25.43 37.1372 28.916 37.1372 33.201Z" />
                                                        </svg>
                                                    </template>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </div>
                                    <div class="price-box">
                                        <Amount :item="content.teacher.variant.amount" :quantity="1" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="product-amount-box margin-top-20">
                        <div class="text">
                            <div class="title">
                                <span>Toplam</span> Fiyat
                            </div>
                            <div class="description">
                                Ücretsiz Kargo
                            </div>
                        </div>
                        <div class="price-box">
                            <Amount :item="response.package.amount" :isTotal="true" :quantity="1" />
                        </div>
                    </div>

                    <div class="product-add-basket-box">
                        <div class="product-add-basket-box">
                            <div class="column">
                                <SuggestionDialog :items="response.package.suggestGlobalSaleContents" type="package" />
                            </div>
                            <div class="column add-basket-btn">
                                <add-to-basket :item="response" />
                            </div>
                        </div>
                    </div>
                </template>

                <div class="stock-alert-bar margin-top-20" v-if="response.product.stock_status.id !== ui.settings.default_stock_status_id">
                    <div :style="{color : response.product.stock_status.bg_color}">{{ response.product.stock_status.title }}</div>
                </div>

            </div>
        </div>

        <el-dialog :title="dialog.response ? dialog.response.title : null" :visible.sync="dialog.visible" width="50%" append-to-body class="content-detail-dialog product-row">
            <div class="body" v-if="dialog.response">
                <div class="description" v-html="dialog.response.short_description"></div>
            </div>

            <div slot="footer" v-if="dialog.response">
                <div class="product-row-features">
                    <div class="feature date">
                        <div class="media">
                            <icon source="calendar-month-outline" />
                        </div>
                        <div class="text">
                            <div class="title">Eğitim Tarihi</div>
                            <div class="description">{{ dialog.response.education_end_at_date_format }} - {{ dialog.response.education_start_at_date_format }}</div>
                        </div>
                    </div>
                    <div class="feature">
                        <div class="media">
                            <icon source="message-video" />
                        </div>
                        <div class="text">
                            <div class="title">Ders</div>
                            <div class="description">{{ dialog.response.lesson_count }}</div>
                        </div>
                    </div>
                    <div class="feature">
                        <div class="media">
                            <icon source="clock-outline" />
                        </div>
                        <div class="text">
                            <div class="title">Saat</div>
                            <div class="description">{{ dialog.response.lesson_time_count }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
import SuggestionDialog from "@/app/Pages/Shop/_parts/SuggestionDialog";
import Amount from "@/app/Pages/Shop/_parts/Amount";
import {mapGetters} from "vuex";

export default {
    name: 'Package',
    props : ['response'],
    components: {
        Amount,
        SuggestionDialog,
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOptions: {
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                },
                effect: 'coverflow',
                grabCursor: true,
                loop: true,
                centeredSlides: true,
                keyboard: true,
                slidesPerView: 'auto',
                speed: 500,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 94,
                    depth: 45,
                    modifier: 3,
                    slideShadows: false
                },
                breakpoints: {
                    768: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 77,
                            depth: 45,
                            modifier: 3,
                            slideShadows: false
                        },
                    },
                    800: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 70,
                            depth: 45,
                            modifier: 3,
                            slideShadows: false
                        }
                    },
                    992: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 70,
                            depth: 45,
                            modifier: 3,
                            slideShadows: false
                        }
                    },
                    1200: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 71,
                            depth: 45,
                            modifier: 3,
                            slideShadows: false
                        }
                    }
                },
                simulateTouch: true,
            },

            dialog : {
                visible : false,
                response : null
            }
        }
    },
    computed: mapGetters(['ui']),
    methods: {
        handleSelectVariant(content, variant) {
            content.teacher.variant = variant;
            this.calculate();
        },

        handleSelectTeacher(command) {
            this.response.package.contents[command.index].teacher = command.teacher;
            this.calculate();
        },

        calculate() {
            let total = 0;
            let price = 0;
            let lesson_count = 0;
            let lesson_hour = 0;
            let lesson_usage_time = 0;

            this.lodash.forEach(this.response.package.contents, (product) => {
                price += parseFloat(product.teacher.variant.price);
                lesson_count += parseInt(product.teacher.variant.lesson_count);
                lesson_hour += parseInt(product.teacher.variant.lesson_time_count);

                if (parseInt(product.teacher.variant.lesson_usage_time) > lesson_usage_time) {
                    lesson_usage_time = parseInt(product.teacher.variant.lesson_usage_time);
                }
            });

            total = price;

            if (this.response.package.amount.discount) {
                this.response.package.amount.discount.price = price - ((price / 100) * parseFloat(this.response.package.amount.discount.percent));
            }

            this.response.package.amount.price = total;
            this.response.package.amount.total = total;
            this.response.package.lesson_count = lesson_count;
            this.response.package.lesson_hour = lesson_hour;
        },

        handleContentDetailDialog(response) {
            this.dialog.visible = true;
            this.dialog.response = response;
        }
    }
}
</script>
