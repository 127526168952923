<template>
    <el-tooltip :content="$t('feedback.title')" placement="right">
        <div class="item" @click="dialogVisible = true">
            <icon source="pencil-outline"/>

            <el-dialog :title="$t('feedback.title')" :visible.sync="dialogVisible" width="50%" append-to-body>
                <div class="dialog-description margin-bottom-20">
                    Size daha iyi hizmet verebilmemiz ve diğer katılımcılarımıza yol gösterebilmek için eğitimlerimiz ve
                    sistemimiz hakkında görüşlerinizi bizimle paylaşmak ister misiniz?
                </div>

                <el-form :model="form" label-position="top" @submit.native.prevent="store">
                    <el-form-item :class="{ 'is-error': submitted && $v.form.description.$error }">
                        <el-input v-model="form.description" prefix-icon="el-icon-message"
                                  :placeholder="$t('inputs.your_message')" type="textarea" rows="6"/>
                    </el-form-item>
                    <el-form-item :class="{ 'is-error': submitted && $v.form.has_access_web_site.$error }"
                                  class="margin-bottom-0">
                        <el-checkbox v-model="form.has_access_web_site" :label="$t('feedback.share')" class="no-margin"/>
                    </el-form-item>
                </el-form>

                <template slot="footer" class="dialog-footer">
                    <el-button type="primary" class="fluid" size="large" @click="store" :loading="loading" :disabled="loading">
                        {{ $t('btn_send') }}
                    </el-button>
                </template>
            </el-dialog>
        </div>
    </el-tooltip>
</template>

<script>
import {mapGetters} from "vuex";
import {required, minLength} from "vuelidate/lib/validators";
import {STORE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'FeedbackHeader',
    computed: mapGetters(['account']),
    data() {
        return {
            path: 'platform/student/misc/feedback',
            loading: false,
            submitted: false,
            form: {
                //
            },

            dialogVisible: false,
        }
    },
    validations: {
        form: {
            description: {required, minLength: minLength(10)},
            has_access_web_site: {},
        }
    },
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;
            this.form.disable_push = true;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
