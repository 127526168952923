import AuthLayout from "@/app/Layouts/AuthLayout";

// Pages
import Login from "@/app/Pages/Auth/Login";
import Register from "@/app/Pages/Auth/Register";
import ForgotPassword from "@/app/Pages/Auth/ForgotPassword";
import ResetPassword from "@/app/Pages/Auth/ResetPassword";

export default {
    path: '/auth',
    component: AuthLayout,
    meta: {
        auth: true,
        title: 'Auth Islemleri',
    },
    children: [
        {
            name: 'app.auth.login.index',
            path: 'login',
            component: Login,
            meta: {
                auth: true,
                title: 'Giris',
                layout : 'content'
            },
        },
        {
            name: 'app.auth.register.index',
            path: 'register',
            component: Register,
            meta: {
                auth: true,
                title: 'Kayit Ol',
                layout : 'content'
            },
        },
        {
            name: 'app.auth.forgot_password.index',
            path: 'forgot-password',
            component: ForgotPassword,
            meta: {
                auth: true,
                title: 'Sifremi Unuttum!',
                layout : 'content'
            },
        },
        {
            name: 'app.auth.reset_password.index',
            path: 'reset-password',
            component: ResetPassword,
            meta: {
                auth: true,
                title: 'Sifremi Sifirla',
                layout : 'content'
            },
        },

    ]
}
