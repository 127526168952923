import ApiService from "@/services/api.service";
import {
	SET_ERROR,
	SET_CHECKOUT_NAVIGATION_STEP,
	SET_CHECKOUT_QUANTITY,
	SET_CHECKOUT_INSTALLMENT,
	SET_CHECKOUT_SUMMARY,
	SET_CHECKOUT_ADDRESS,
} from "./mutations.type";
import {
	GET_CHECKOUT_QUANTITY,
	GET_CHECKOUT_SUMMARY,
	STORE_CHECKOUT_ADD_TO_BASKET,
	DESTROY_CHECKOUT_ADD_TO_BASKET,
	TRUNCATE_CHECKOUT_ADD_TO_BASKET,
	STORE_CHECKOUT_ADDRESS,
	GET_CHECKOUT_COUPON,
	DESTROY_CHECKOUT_COUPON,
} from "./actions.type";

const state = {
	step : 1,
	quantity : 0,
	installment : null,
	summary : null,
};

const getters = {
	step(state) {
		return state.step;
	},

	quantity(state) {
		return state.quantity;
	},

	installment(state) {
		return state.installment;
	},

	summary(state) {
		return state.summary;
	}
};

const mutations = {
	[SET_CHECKOUT_NAVIGATION_STEP](state, payload) {
		state.step = payload;
	},

	[SET_CHECKOUT_QUANTITY](state, payload) {
		state.quantity = payload;
	},

	[SET_CHECKOUT_INSTALLMENT](state, payload) {
		state.installment = payload;
	},

	[SET_CHECKOUT_SUMMARY](state, payload) {
		state.summary = payload;
	},

	[SET_CHECKOUT_ADDRESS](state, payload) {
		state.summary.delivery_address_id = payload.delivery_address_id;
		state.summary.invoice_address_id = payload.invoice_address_id;
	},
};

const actions = {
	[GET_CHECKOUT_QUANTITY](context) {
		return new Promise(resolve => {
			ApiService.get('platform/student/checkout/quantity')
				.then(({data}) => {
					resolve(data);
					context.commit(SET_CHECKOUT_QUANTITY, data.data);
				});
		});
	},

	[GET_CHECKOUT_SUMMARY](context) {
		return new Promise(resolve => {
			ApiService.get('platform/student/checkout/summary?coupon_id=' + localStorage.getItem('coupon') + '&installment=' + encodeURIComponent( JSON.stringify(context.state.installment) ))
				.then(({data}) => {
					resolve(data);
					context.commit(SET_CHECKOUT_SUMMARY, data.data);
					context.commit(SET_CHECKOUT_QUANTITY, data.data.quantity ? data.data.quantity : 0);
				});
		});
	},

	[STORE_CHECKOUT_ADD_TO_BASKET](context, products) {
		return new Promise((resolve, reject) => {
			ApiService.store('platform/student/checkout/add-to-basket', {products : products})
				.then(({data}) => {
					resolve(data.data);
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	},

	[DESTROY_CHECKOUT_ADD_TO_BASKET](context, id) {
		return new Promise(resolve => {
			ApiService.destroy('platform/student/checkout/add-to-basket?id=' + id)
				.then(({data}) => {
					resolve(data);
				});
		});
	},

	[TRUNCATE_CHECKOUT_ADD_TO_BASKET](context) {
		return new Promise(resolve => {
			ApiService.destroy('platform/student/checkout/truncate')
				.then(({data}) => {
					resolve(data);
				});
		});
	},

	[STORE_CHECKOUT_ADDRESS](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.store('platform/student/checkout/addresses', params)
				.then(({data}) => {
					resolve(data.data);
					context.commit(SET_CHECKOUT_ADDRESS, data.data);
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	},

	[GET_CHECKOUT_COUPON](context, code) {
		return new Promise((resolve, reject) => {
			ApiService.get('platform/student/checkout/coupons/' + code)
				.then(({data}) => {
					resolve(data);
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	},

	[DESTROY_CHECKOUT_COUPON](context) {
		return new Promise((resolve, reject) => {
			ApiService.destroy('platform/student/checkout/coupons')
				.then(({data}) => {
					resolve(data);
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	},
};

export default {
	state,
	actions,
	mutations,
	getters
};
