import Vue from "vue";
import Vuex from "vuex";
import FormResponseService from '../services/form_response.service';
import Config from "../config/app";

// Init
Vue.use(Vuex);

// Global process
import {SET_ERROR, SET_PRELOADING} from "@/store/mutations.type";

// Modules
import ui from "./ui.module";
import auth from "./auth.module";
import account from "./account.module";
import crud from "./crud.module";
import checkout from "./checkout.module";

export default new Vuex.Store({
    namespaced: true,
    state: {
        config : Config,
        errors: null,
        preloading : true,
    },
    mutations : {
        [SET_ERROR](state, error) {
            state.errors = error;
            FormResponseService.error(error)
        },
        [SET_PRELOADING](state, payload) {
            state.preloading = payload;
        },
    },
	modules: {
        ui,
        auth,
        account,
        crud,
        checkout
	}
});
