import { render, staticRenderFns } from "./AccountLayout.vue?vue&type=template&id=ee40de58"
import script from "./AccountLayout.vue?vue&type=script&lang=js"
export * from "./AccountLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports