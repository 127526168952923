export const tr = {
	error: {
		title: 'Üzgünüm aradığınız sayfa bulunamadı :(',
		access: 'Üzgünüm bu sayfaya erişim yetkiniz bulunmuyor :(',
		maintenance_title: 'İnternet bağlantınızdan dolayı erişim sağlayamıyorsunuz, Lütfen 1 - 2 dakika bekledikten sonra sayfanızı yenileyin. Sorun devam ederse lütfen bizimle iletişime geçin.',
	},

	notification: {
		warning_title: 'Dikkat!',
		destroy: 'Seçmiş olduğunuz veri kalıcı olarak silinecek. Onaylıyor musunuz?',
		destroy_cancel: 'Silme işlemi iptal edildi.',
		sending_cancel: 'Gönderim işlemi iptal edildi.',
	},

	empty: {
		not_found: 'Üzgünüm aramanıza uygun sonuç bulunamadı!',
		not_result: 'Üzgünüm her hangi bir sonuc bulamadik.',
		building: 'Üzgünüm bu özellik şu anda yapım aşamasında!',
		today_lesson: 'Üzgünüm bugün hiç dersiniz bulunmuyor.',
		range_lesson: 'Üzgünüm seçtiğiniz tarihler arasında dersiniz bulunmuyor.',
		basket: 'Sepetin şu an boş.',
		notification: 'Bildirim bulunamadı.',
		homework_live: 'Aktif ödeviniz bulunmuyor.',
		lesson_live: 'Aktif dersiniz bulunmuyor.',
		feeds: 'Listelenecek bir işlem bulunamadı.',
		order: 'Henüz hiç siparişiniz bulunmuyor.',
		discount: 'Üzgünüm aktif bir indirim kuponunuz bulunmuyor.',
		lesson: 'Üzgünüm aramanıza uygun ders bulunamadı!',
		book: 'Üzgünüm aramanıza uygun kitap bulunamadı!',
		test: 'Üzgünüm aramanıza uygun test bulunamadı!',
		exam: 'Üzgünüm aramanıza uygun deneme bulunamadı!',
		reading: 'Üzgünüm aramanıza uygun okuma parçası bulunamadı!',
		document: 'Üzgünüm aramanıza uygun doküman bulunamadı!',
		video: 'Üzgünüm aramanıza uygun video bulunamadı!',
		listening: 'Üzgünüm aramanıza uygun dinleme bulunamadı!',
		word_list: 'Üzgünüm aramanıza uygun kelime listesi bulunamadı!',
		address: 'Üzgünüm aramanıza uygun adres bulunamadı!',
		newsletter: 'Üzgünüm henüz yayınlanmış bir bilgilendirme mektubunuz bulunmuyor.',
		homework: 'Üzgünüm henüz yayınlanmış bir ödeviniz bulunmuyor.',
		forum: 'Üzgünüm bu forumda henüz yayınlanmış bir içerik bulunmuyor',
		schedule: 'Üzgünüm seçtiğiniz tarihler arasında dersiniz bulunmuyor.',
		shop: 'Üzgünüm aramanıza uygun ürün bulunamadı!',
		question: 'Bu soru için henüz hiç soru sorulmadı!',
		announcement: 'Üzgünüm aramanıza uygun duyuru bulunamadı!',
		dictionary: 'Aradığınız kelime bulunamadı!',
        report: 'Üzgünüm henüz adınıza oluşturulmuş bir rapor bulunamadı',
	},

	menu: {
		dashboard: 'Platform',
		lessons: 'Dersler',
		classrooms: 'Sınıflar',
		books: 'Kitaplar',
		readings: 'Okuma',
		tests: 'Testler',
		exams: 'Denemeler',
        leveling_exams: 'Seviye Tespit',
		word_list: 'Kelime',
		videos: 'Videolar',
		listenings: 'Dinlemeler',
		documents: 'Dokümanlar',
		shop: 'Mağaza',
		forum: 'Forum',
		homework: 'Ödevler',
		messages: 'Mesajlar',
		newsletter: 'Bilgilendirme Mektupları',
		announcements: 'Duyurular',
		dictionary: 'Sözlük',
        private_reports: 'Raporlar',
	},

	auth: {
		remember_me: 'Beni Hatırla!',
		forgot_password: 'Şifremi Unuttum!',
		reset_password: 'Şifrenizi Sıfırlayın!',
		dont_have_account: 'Bir hesabınız yok mu?',
		have_account: 'Zaten bir hesabım var.',
	},

	account: {
		menu: {
			my_account: 'Hesabım',
			my_orders: 'Siparişlerim',
			my_address: 'Adreslerim',
			my_discount: 'Kupon ve İndirimlerim',
			my_notification: 'Bildirim Ayarlarım',
			logout: 'Çıkış Yap!',
		},

		my_profile: 'Profil Bilgilerim',
		my_password: 'Şifre Bilgilerim',
		my_addresses: 'Adreslerim',
		create_address: 'Yeni Adres Ekle',
		address_detail: 'Adres Bilgilerim',
		notification_title: 'Bildirim Ayarları',

		notifications: {
			titles: {
				campaigns: 'Kampanyalar & Duyurular',
				newsletters: 'Haber Bülteni',
				payments: 'Ödeme Bilgilendirmeleri',
				informations: 'Bilgilendirme Servisleri',
				educations: 'Eğitim Bilgilendirmeleri',
			}
		},

		orders: {
			address: {
				invoice: 'Fatura Adresi',
				delivery: 'Teslimat Adresi',
				shipping: 'Teslimat Adresi',
			}
		}
	},

	feedback: {
		title: 'Geri Bildirim',
		share: 'Bu geri bildirimin web sitesinde paylaşılmasına izin veriyorum.',
	},

	notifications: {
		title: 'Bildirimler',
		all: 'Tum Bildirimler',
		detail: 'Bildirim Bilgileri',
		actions: {
			add: 'sisteminize eklendi.',
			lesson_record: 'ders kaydıniz sisteminize eklendi.',
			lesson_live_reminder: 'canlı dersiniz başlamak üzere!',
			live_lesson_reminder: 'canlı dersiniz başlamak üzere!',
			sample_lesson_add: 'deneme dersiniz oluşturuldu.',
			webinar_lesson_reminder : 'webinar eğitiminiz başlamak üzere.'
		},
	},

	messages: {
		title: 'Mesajlar',
		search: 'ara...',
	},

	newsletters: {
		title: 'Bilgilendirme Mektupları',
		detail: 'Mektup',
	},

	homeworks: {
		title: 'Ödevler',
		detail: 'Ödev',
		filter: {
			all: 'Tüm Ödevler',
			passive: 'Geçmiş Ödevler',
			active: 'Gelecek Ödevler',
		}
	},

	shop: {
		basket: {
			title: 'Alışveriş Sepetiniz',
			btn: 'Alışverişi Tamamla',
		}
	},

	checkout: {
		delivery_address_not_selected : 'Devam edebilmek icin lutfen Teslimat Adresi seciniz.',
		invoice_address_not_selected : 'Devam edebilmek icin lutfen Fatura Adresi seciniz.',
	},

	dashboard: {
		title: 'Dashboard',
		lesson: 'Aktif Dersler',
		homework: 'Aktif Ödevler'
	},

	schedule: {
		title: 'Ders Programı'
	},

	lessons: {
		title: 'Dersler',
		detail: 'Ders',
		filter: {
			all: 'Tüm Dersler',
			passive: 'Geçmiş Dersler',
			active: 'Gelecek Dersler',
		},
		actions: {
			live: 'Derse Katıl',
			recording: 'Ders kaydı hazırlanıyor...',
			record: 'İzle',
			countdown: {
				days: 'g.',
				hours: 'sa.',
				minutes: 'dk.',
				seconds: 'sn.',
				after: 'sonra.',
			}
		}
	},

	books: {
		title: 'Kitaplar',
		detail: 'Kitap',
		filter: {
			all: 'Tüm Kitaplar',
			passive: 'Tamamladıklarınız',
			active: 'Tamamlamadıklarınız',
		},
	},

	tests: {
		title: 'Testler',
		detail: 'Test',
		report: 'Test Raporu',
		refresh: 'Tekrardan Çöz',
		filter: {
			all: 'Tüm Testler',
			passive: 'Çözmedikleriniz',
			active: 'Çözdükleriniz',
		},
		actions: {
			next: 'Devam Et',
			solve: 'Testi Çöz',
			report: 'Test Raporu',
		}
	},

	exams: {
		title: 'Denemeler',
		detail: 'Deneme',
		report: 'Deneme Raporu',
		filter: {
			all: 'Tüm Denemeler',
			passive: 'Çözmedikleriniz',
			active: 'Çözdükleriniz',
		},
		actions: {
			next: 'Devam Et',
			solve: 'Denemeyi Çöz',
			report: 'Deneme Raporu',
		}
	},

	readings: {
		title: 'Okuma Parçaları',
		detail: 'Okuma Parçası',
		filter: {
			all: 'Tüm Okuma Parçaları',
			passive: 'Tamamladıklarınız',
			active: 'Tamamlamadıklarınız',
		},
	},

	documents: {
		title: 'Dokümanlar',
		detail: 'Döküman',
	},

	videos: {
		title: 'Videolar',
		detail: 'Video',
		filter: {
			all: 'Tüm Videolar',
			passive: 'İzlediklerim',
			active: 'İzlemediklerim',
		},
	},

	listenings: {
		title: 'Dinlemeler',
		detail: 'Dinleme',
		filter: {
			all: 'Tüm Dinlemeler',
			passive: 'Dinlediklerim',
			active: 'Dinlemediklerim',
		},
	},

	word_list: {
		title: 'Kelime Listeleri',
		detail: 'Kelime Listesi',
		filter: {
			all: 'Tüm Kelime Listeleri',
			passive: 'İncelediklerim',
			active: 'İncelemediklerim',
		},
	},

	// inputs
	inputs: {
		choice: 'Seçiminiz',
		title: 'Başlık',
		description: 'Açıklama',
		start_at: 'Başlangıç Tarihi',
		end_at: 'Bitiş Tarihi',
		classrooms: 'Sınıflar',
		files: 'Dosyalar',
		homework: 'Ödev',
		your_message: 'Mesajınız...',
		your_email: 'E-posta Adresiniz',
		your_password: 'Şifreniz',
		your_password_repeat: 'Şifreniz (Tekrar)',
		language: 'Dil',
		sms: 'Sms',
		email: 'E-posta',
		mobile: 'Mobil',
		username: 'Kullanıcı Adı',
		birthday: 'Doğum Tarihi',
		password: 'Şifre',
		your_new_password: 'Yeni Şifreniz',
		your_new_password_repeat: 'Yeni Şifreniz (Tekrar)',
		main_address: 'Varsayılan İletişim Adresi',
		address: 'Adres',
		address_title: 'Adres Başlığı',
		first_name: 'Ad',
		last_name: 'Soyad',
		identification_no: 'T.C. Kimlik No.',
		phone: 'Telefon',
		country: 'Ülke',
		city: 'Şehir',
		town: 'İlçe',
		address_1: 'Adres 1',
		address_2: 'Adres 2',
		subject: 'Konu',
		message: 'Mesaj',
		translate: 'Çeviri',
		publish_at: 'Yayınlanma Tarihi',
		sub_total: 'Ara Toplam',
		full_name: 'Ad Soyad',
		card_number: 'Kart Numarası',
		month: 'Ay',
		year: 'Yıl',
		card_expiry: 'Ay / Yıl',
		card_cvv: 'CVC / CVV',
		your_question: 'Sorunuz...',
	},

	// Misc
	misc: {
		pagination_result: 'Toplam <b>{total}</b> sonuç bulundu.',
		welcome: 'Hoş geldiniz!',
		or: 'Veya',
		yes: 'Evet',
		no: 'Hayır',
		apps: 'Uygulamalar',
		detail: 'Detay',
		all_products: 'Tüm Gruplar',
		selected_products: '{total} Grup Seçildi',
	},

	// Buttons
	btn_login: 'Giriş Yap!',
	btn_register: 'Kayıt Ol',
	btn_send: 'Gönder',
	btn_save: 'Kaydet',
	btn_update: 'Güncelle',
	btn_change: 'Değiştir',
	btn_edit: 'Düzenle',
	btn_delete: 'Sil',
	btn_back: 'Geri Dön',
	btn_upload: 'Yükle',
	btn_create: 'Oluştur',
	btn_next: 'Devam Et',
	btn_re_calculate: 'Yeniden Hesapla',
	btn_search: 'Ara',
	btn_buy: 'Satın Al',
	btn_add: 'Ekle',
	btn_pay: 'Ödeme Yap',
	btn_select: 'Seç',
	btn_print: 'Yazdır',
	btn_add_file: 'Dosya Ekle',
	btn_view: 'Görüntüle',
	btn_close: 'Kapat',
	btn_facebook_login: 'Facebook İle Giriş Yapın',
	btn_google_login: 'Google İle Giriş Yapın',
	btn_facebook_register: 'Facebook İle Kayıt Olun',
	btn_google_register: 'Google İle Kayıt Olun',
	btn_shop: 'Mağaza',
	btn_to_week: 'Bu Hafta',
	btn_direction_prev: 'Geri',
	btn_direction_next: 'İleri',
	btn_cancel: 'İptal',
	btn_nav_prev: 'Geri',
	btn_nav_next: 'İleri',
	btn_preview: 'Ön İzleme',
	btn_repeat: 'Tekrar Et',
};
