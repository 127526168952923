<template>
    <div>
        <div class="box box-media-band suggestion-box margin-bottom-20">
            <div class="box-body">
                <div class="content" @click="calculate">
                    <div class="media">
                        <icon source="bookshelf" />
                    </div>
                    <div class="text">
                        <div class="title">DERS NOTLARINIZI UNUTMAYIN!</div>
                        <div class="description">Şimdi basılı olarak <b>ücretsiz</b> kargo imkanıyla alın!</div>
                    </div>
                </div>
                <div class="action">
                    <el-button type="primary" plain @click="store" :loading="loading" :disabled="loading">
                        Tümünü Ekle
                    </el-button>
                </div>
            </div>
        </div>

        <el-dialog title="Basılı ürünlerden seç" :visible.sync="dialogVisible" width="80%" append-to-body class="suggestion-dialog">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <div class="products">
                <template v-for="(item, index) in summary.suggestions">
                    <div class="item" :key="index">
                        <div class="content">
                            <div class="media">
                                <icon :source="findProductIcon(item.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <template v-for="(group, featureIndex) in item.features">
                                    <div class="metas" :key="featureIndex">
                                        <template v-for="(feature, featureItemIndex) in group.items">
                                            <el-tooltip :content="feature.title">
                                                <div class="meta" :key="featureItemIndex">
                                                    <img :src="publicFilePath(feature.image)" alt="feature">
                                                    {{ feature.description }}
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="action">
                            <el-input-number v-model="item.product.quantity" :step="1" :min="0" class="product-quantity" @change="calculate" />
                        </div>
                        <div class="price">
                            <Amount :item="item.product.amount" :quantity="item.product.quantity" />
                        </div>
                    </div>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <div class="total">
                    <div class="title">Eklenecek Tutar</div>
                    <div class="price">
                        {{ $n(total, 'currency') }}
                    </div>
                </div>
                <div class="btn">
                    <el-button icon="el-icon-check" type="primary" class="fluid" @click="store" :loading="loading" :disabled="loading">
                        Seçilen Ürünleri Ekle
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {STORE_CHECKOUT_ADD_TO_BASKET} from "@/store/actions.type";
import {SET_CHECKOUT_QUANTITY} from "@/store/mutations.type";
import Amount from "@/app/Pages/Shop/_parts/Amount";

export default {
    name: 'SuggestionBand',
    components: {Amount},
    props : ['index'],
    data() {
        return {
            dialogVisible : false,
            total : 0,
            loading : false,
        }
    },
    computed: mapGetters(['summary']),
    methods: {
        calculate() {
            this.dialogVisible = true;

            this.total = 0;

            this.lodash.forEach(this.summary.suggestions, (item) => {
                let quantity = parseInt(item.product.quantity);
                this.total += quantity * parseFloat(item.product.amount.price);
            })
        },

        store() {

            let products = [];

            this.lodash.forEach(this.summary.suggestions, (suggestion) => {
                if (suggestion.product.quantity) {
                    products.push({
                        content_id: suggestion.id,
                        product_id: suggestion.product.id,
                        quantity: suggestion.product.quantity,
                    })
                }
            });

            this.loading = true;

            this.$store
                .dispatch(STORE_CHECKOUT_ADD_TO_BASKET, products)
                .then((response) => {
                    this.loading = false;
                    this.$store.commit(SET_CHECKOUT_QUANTITY, response.quantity);
                    this.dialogVisible = false;

                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
