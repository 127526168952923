<template>
    <div class="content" v-loading="pageLoading">
        <template v-if="response">
            <div class="head">
                <div class="head-content">
                    <div class="media">
                        <avatar :source="response.avatar" :name="response.name" />
                    </div>
                    <div class="text">
                        <div class="name">{{ response.name }}</div>
                    </div>
                </div>
                <div class="head-action">
                    <el-tooltip :content="$t('btn_back')">
                        <el-button plain icon="el-icon-back" size="mini" @click="back"/>
                    </el-tooltip>
                </div>
            </div>
            <div class="body" id="MessageContainer">
                <template v-for="(item, index) in response.messages">
                    <div class="message" :key="index" :class="{me : response.name !== item.receiver.name}">
                        <div class="user">
                            <avatar :source="item.receiver.avatar" :name="item.receiver.name" />
                            <div class="metas">
                                <div class="meta name">{{ item.receiver.name }}</div>
                                <div class="meta time">{{ item.message.date }}</div>
                            </div>
                        </div>
                        <div class="text">
                            <div class="text-wrp">
                                <div class="file" v-if="item.message.file_path">
                                    <div class="file-media">
                                        <div class="file-media-icon">
                                            <icon :source="filePreviewIcon(item.message.file_type)" />
                                        </div>
                                        <div class="file-media-text">
                                            <div class="name">{{ item.message.file_name }}</div>
                                            <div class="size">{{ formatBytes(item.message.file_size) }}</div>
                                        </div>
                                    </div>
                                    <div class="file-action" @click="openBlankWindow(item.message.file_path)">
                                        <i class="el-icon-download"></i>
                                    </div>
                                </div>
                                <div class="msg">
                                    <div v-html="linkify(item.message.text)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="message-form">
                <el-form :model="form" label-position="top">
                    <input type="file" ref="file" style="display: none" @change="handleFileMessage"
                           accept="image/jpeg,image/png,application/octet-stream,audio/mpeg,mpga,mp3,wav,video/x-ms-asf,video/x-flv,video/mp4,application/x-mpegURL,video/MP2T,video/3gpp,video/quicktime,video/x-msvideo,video/x-ms-wmv,video/avi,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    <div class="attach" @click="handleClickFile">
                        <icon source="paperclip" />
                    </div>
                    <el-form-item class="margin-bottom-0" :class="{ 'is-error': submitted && $v.form.message.$error }">
                        <el-input v-model="form.message" :placeholder="$t('inputs.your_message')" type="textarea" />
                    </el-form-item>
                    <div class="send-button" @click="store" v-loading="loading">
                        <icon source="send-outline" />
                    </div>
                </el-form>
            </div>
        </template>

        <el-dialog :title="$t('btn_preview')" :visible.sync="fileMessageDialog" width="50%" append-to-body class="message-dialog">
            <div class="image" v-if="fileMessage.type === 'image'">
                <img :src="fileMessage.src" alt="image">
            </div>
            <div class="video" v-if="fileMessage.type === 'video'">
                <video :src="fileMessage.src" controls></video>
            </div>
            <div class="document" v-if="fileMessage.type !== 'video' && fileMessage.type !== 'image'">
               <div>
                   <icon source="file-document-outline" />
                   <div class="text">On izleme bulunamadi</div>
               </div>
            </div>
            <template slot="footer">
                <div class="message-form">
                    <el-form :model="form" label-position="top">
                        <el-form-item class="margin-bottom-0" :class="{ 'is-error': submitted && $v.form.message.$error }">
                            <el-input v-model="form.message" :placeholder="$t('inputs.your_message')" type="textarea" />
                        </el-form-item>
                        <div class="send-button" @click="store" v-loading="loading">
                            <icon source="send-outline" />
                        </div>
                    </el-form>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";
import AwsService from "@/services/aws.service";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'Message',
    props: ['id', 'back'],
    data() {
        return {
            path: 'platform/student/misc/messages',
            pageLoading: true,
            response: null,
            loading: false,
            submitted: false,
            form: {
                //
            },

            fileMessageDialog: false,
            fileMessage: {
                //
            },
        }
    },
    validations: {
        form: {
            message: {required},
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            ApiService.setHeader();
            ApiService.get(this.path + '/' + this.id)
                .then(({data}) => {
                    this.response = data.data;
                    this.pageLoading = false;
                    this.scroolBottom();
                })
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.$refs.file.files[0]) {
                return this.fileStore();
            }

            return this.post();
        },

        fileStore() {
            this.loading = true;

            let file = this.$refs.file.files[0];
            let type = (file.name).split('.').pop();
            let name = (new Date()).getTime() + '-' + URLify((file.name).replace('.' + type, ''));
            let path = 'chats/' + name + '.' + type;

            AwsService.upload(path, file)
                .then(() => {
                    this.form.file = {
                        type: file.type,
                        name: name + '.' + type,
                        size: file.size,
                        path: path,
                    }

                    this.post();
                })
        },

        post() {
            this.loading = true;

            ApiService.store(this.path, {
                room_id: this.id,
                user_id: this.response.id,
                is_sms: this.form.is_sms,
                message: this.form.message,
                file: this.form.file,
            })
                .then(({data}) => {
                    this.loading = false;
                    this.submitted = false;
                    this.fileMessageDialog = false;
                    this.form = {
                        //
                    }
                    this.fileMessage = {
                        //
                    }
                    this.$refs.file.value = null;

                    this.response.messages.push(data.data);

                    this.scroolBottom();
                })
                .catch((response) => {
                    this.loading = false;
                    this.submitted = false;
                    this.$notify({
                        type: 'error',
                        customClass: 'alert-error',
                        message: response.body.message
                    });
                })
        },

        handleClickFile() {
            this.$refs.file.click()
        },

        handleFileMessage() {
            this.fileMessageDialog = true;

            let types = (this.$refs.file.files[0].type).split('/');

            this.fileMessage.type = types[0];

            if (this.fileMessage.type === 'image' || this.fileMessage.type === 'video') {
                this.fileMessage.src = URL.createObjectURL(this.$refs.file.files[0])
            }
        },

        scroolBottom() {
            setTimeout(() => {
                let container = this.$el.querySelector('#MessageContainer');
                container.scrollTop = container.scrollHeight;
            }, 200)
        },
    }
}
</script>
