<template>
    <div class="dictionary-page-head">
        <div class="text cursor-pointer" @click="goRoute('/dictionary')">
            <div class="title">Sözlük</div>
            <div class="description">Bir kelime hakkında bilmeniz gereken her şeye tek bir arama ile ulaşın.</div>
        </div>
        <div class="search">
            <el-form @submit.native.prevent="goWordDetail">
                <el-form-item class="margin-bottom-0">
                    <el-autocomplete placeholder="Sözlükte arama yapın..." v-model="form.word" :fetch-suggestions="querySearchAsync" @select="goWordDetail" value-key="title">
                        <div class="suffix" slot="suffix">
                            <div class="target" @click="toggle('en')" v-if="form.directory === 'tr'">
                                <div>tr</div>
                                <div>
                                    <icon source="chevron-right" />
                                </div>
                                <div>en</div>
                            </div>
                            <div class="target" @click="toggle('tr')" v-if="form.directory === 'en'">
                                <div>en</div>
                                <div>
                                    <icon source="chevron-right" />
                                </div>
                                <div>tr</div>
                            </div>
                            <div class="btn" @click="goWordDetail" v-loading="loading">
                                <i class="icon el-icon-search"></i>
                            </div>
                        </div>
                    </el-autocomplete>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: 'DictionaryHead',
    data() {
        return {
            path: 'platform/student/dictionary',
            loading: false,
            submitted: false,
            form: {
                directory: 'en',
                word: null
            },
            results: [],
        }
    },
    mounted() {
        this.form.word = this.$route.params.id;

        if (this.$route.query.directory) {
            this.form.directory = this.$route.query.directory;
        }
    },
    methods: {
        goWordDetail() {
            if (this.form.word === this.$route.params.id) {
                return false;
            }

            return this.goRoute('/dictionary/' + this.form.word + '?directory=' + this.form.directory);
        },

        toggle(directory) {
            this.form.directory = directory;
        },

        querySearchAsync(queryString, cb) {
            ApiService.get(this.path, {params: this.form})
                .then(({data}) => {
                    this.loading = false;
                    this.results = data.data;

                    cb(this.results)
                })
        }
    }
}
</script>
