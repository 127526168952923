import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Homeworks/Index";
import Show from "@/app/Pages/Homeworks/Show";

export default {
    path: '/homeworks',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Ödevler',
    },
    children: [
        {
            name: 'app.homeworks.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Ödevler',
            },
        },
        {
            name: 'app.homeworks.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Ödev',
            },
        },

    ]
}
