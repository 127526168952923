import ApiService from '../services/api.service';
import PluginService from "../services/plugin.service";

import {
    GET_UI,
} from "./actions.type";

import {
    SET_PRELOADING,
    SET_UI,
    SET_UI_SETTINGS,
    SET_UI_CONTRACT,
    SET_UI_VISIBLE_PLUGIN,
} from "./mutations.type";

const state = {
    ui: null,
    lang: 'tr',
    menus: [
        {
            route: 'lessons',
            icon: 'app-lessons',
            name: 'lessons',
        },
        {
            route: 'books',
            icon: 'app-books',
            name: 'books',
        },
        {
            route: 'tests',
            icon: 'app-tests',
            name: 'tests'
        },
        {
            route: 'exams',
            icon: 'app-exams',
            name: 'exams'
        },
        {
            route: 'leveling-exams',
            icon: 'app-leveling-exam',
            name: 'leveling_exams'
        },
        {
            route: 'readings',
            icon: 'app-readings',
            name: 'readings'
        },
        {
            route: 'documents',
            icon: 'app-documents',
            name: 'documents'
        },
        {
            route: 'videos',
            icon: 'app-videos',
            name: 'videos'
        },
        {
            route: 'listenings',
            icon: 'app-listenings',
            name: 'listenings'
        },
        {
            route: 'word-lists',
            icon: 'app-words',
            name: 'word_list'
        }
    ],
    contract: {
        visible: false,
        index: null,
    }
};

const getters = {
    ui(state) {
        return state.ui;
    },

    lang() {
        return localStorage.getItem('language');
    },

    menus(state) {
        return state.menus;
    },

    contract(state) {
        return state.contract;
    },
};

const mutations = {
    [SET_UI](state, payload) {
        state.ui = payload;
        $('body').attr('data-color', payload.view.color);

        setTimeout(() => {
            PluginService.CrispChat(state.ui.plugins.CrispChat)
            PluginService.JivoChat(state.ui.plugins.JivoChat)
        }, 2500);
    },

    [SET_UI_SETTINGS](state, payload) {
        state.ui.settings = payload;
    },

    [SET_UI_CONTRACT](state, payload) {
        state.contract = payload;
    },

    [SET_UI_VISIBLE_PLUGIN](state, payload) {
        if (document.getElementsByClassName('crisp-client')[0]) {
            if (payload) {
                document.getElementsByClassName('crisp-client')[0].style.display = 'block';
            } else {
                document.getElementsByClassName('crisp-client')[0].style.display = 'none';
            }
        }
    }
};

const actions = {
    [GET_UI](context) {

        context.commit(SET_PRELOADING, true);

        return new Promise(resolve => {
            ApiService.get('platform/ui')
                .then(({data}) => {
                    resolve(data);

                    context.commit(SET_UI, data.data);
                    context.commit(SET_UI_VISIBLE_PLUGIN, true);
                    context.commit(SET_PRELOADING, false);

                    if (!localStorage.getItem('platform_version')) {
                        localStorage.setItem('platform_version', data.data.view.version)
                    } else {
                        if (localStorage.getItem('platform_version') != data.data.view.version) {
                            localStorage.setItem('platform_version', data.data.view.version)

                            return window.location.reload(true)
                        }
                    }

                    if (window.location.hash === '#/maintenance') {
                        window.location = '/#/dashboard'
                    }
                })
                .catch(() => {
                    if (window.location.hash !== '#/maintenance') {
                        window.location = '#/maintenance'
                    } else {
                        context.commit(SET_PRELOADING, false);
                    }

                    if (window.location.hash === '#/maintenance') {
                        context.commit(SET_PRELOADING, false);
                    }
                });
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
