import settings from '@/config/app'
import lodash from "lodash";
import * as AWS from 'aws-sdk';

AWS.config.update({
	region: 'eu-central-1',
	credentials: new AWS.CognitoIdentityCredentials({
		IdentityPoolId: 'eu-central-1:733faa1b-37bf-4c19-a438-de2391272f4d'
	})
});

let s3 = new AWS.S3({
	apiVersion: '2006-03-01',
	params: {Bucket: 'saturn-storage'}
});

const AwsService = {
	upload(path, file) {

		path = settings.tenantName + '/' + path;

		return s3
			.upload({
				Key: path,
				Body: file,
				ACL: 'public-read',
			})
			.promise();
	},

	async multipleUpload(path, files) {
		return await Promise.all(
			lodash.forEach(files, (file) => this.upload(path + file.name, file))
		)
	}
};

export default AwsService;