import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Documents/Index";
import Show from "@/app/Pages/Documents/Show";

export default {
    path: '/documents',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Dokümanlar',
    },
    children: [
        {
            name: 'app.documents.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Dokümanlar',
            },
        },
        {
            name: 'app.documents.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Doküman',
            },
        },
    ]
}
