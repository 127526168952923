<template>
    <div></div>
</template>

<script>
export default {
    name: 'Section',
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
