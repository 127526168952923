import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Readings/Index";
import Show from "@/app/Pages/Readings/Show";

export default {
    path: '/readings',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Okuma Parçaları',
    },
    children: [
        {
            name: 'app.readings.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Okuma Parçaları',
            },
        },
        {
            name: 'app.readings.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Okuma Parçası',
            },
        },
    ]
}
