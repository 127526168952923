<template>
    <page>
        <div class="page dashboard">
            <div class="page-body" v-loading="pageLoading">
                <div v-if="response">
                    <div class="showcase">
                        <swiper ref="mySwiper" :options="swiperOptions">
                            <template v-for="slider in response.sliders">
                                <swiper-slide>
                                    <div class="slider-item">
                                        <div class="content" v-html="slider.description"></div>
                                        <div class="media">
                                            <img :src="slider.cdn_image" alt="slider">
                                        </div>
                                    </div>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>

                    <div class="apps margin-top-40">
                        <div class="head">Uygulamalar</div>
                        <div class="items">
                            <router-link to="/reports" class="item app-report">
                                <div class="media">
                                    <svg-icon src="app-words" />
                                </div>
                                <div class="title">{{ $t('menu.private_reports') }}</div>
                            </router-link>
                            <template v-for="menu in menus">
                                <router-link :to="'/' + menu.route" class="item"  v-if="ui.permissions['has_access_' + menu.name]" :class="'app-' + menu.name">
                                    <div class="media">
                                        <svg-icon :src="menu.icon" />
                                    </div>
                                    <div class="title">{{ $t('menu.' + menu.name) }}</div>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'

export default {
    name: 'DashboardIndex',
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            path: 'platform/student/dashboard',
            pageLoading: false,
            response: null,
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                },
                effect: 'fade',
            }
        }
    },
    computed: mapGetters(['ui', 'menus']),
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: null, page: 1})
                .then((response) => {
                    this.pageLoading = false;
                    this.response = response;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        }
    }
}
</script>
