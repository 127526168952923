<template>
    <div class="product-row">
        <div class="product-row-tab">
            <div class="box box-tabs">
                <div class="box-tab-head">
                    <div class="item" :class="{active : activeTab === 1}" @click="activeTab = 1">
                        Açıklama
                    </div>
                    <div class="item" :class="{active : activeTab === 2}" @click="activeTab = 2" v-if="elementSize(response.tabs)">
                        Ne Öğreneceksiniz?
                    </div>
                    <div class="item" :class="{active : activeTab === 3}" @click="activeTab = 3" v-if="elementSize(response.faqs)">
                        Sıkça Sorulan Sorular
                    </div>
                    <div class="item" :class="{active : activeTab === 4}" @click="activeTab = 4">
                        Değerlendirmeler
                    </div>
                </div>
                <div class="box-body box-tab-body">
                    <div class="box-tab-content-item box-tab-description" v-show="activeTab === 1">
                        <div class="section-description" v-html="response.description"></div>
                    </div>

                    <div class="box-tab-content-item box-tab-programs" v-show="activeTab === 2">
                        <template v-if="response.type === 'package'">
                            <template v-for="(product, productIndex) in response.tabs">
                                <div class="package-collapse" :key="productIndex">
                                    <div class="package-collapse-title">
                                        {{ product.title }}
                                    </div>
                                    <el-collapse accordion>
                                        <template v-for="(item, index) in product.items">
                                            <el-collapse-item :name="index" :key="index">
                                                <template slot="title">
                                                    <div class="head">
                                                        <div class="title">{{ item.short_description ? item.short_description : item.title }}</div>
                                                        <div class="description">Detayları görüntülemek için tıklayınız.</div>
                                                    </div>
                                                </template>
                                                <div class="short-description" v-if="item.short_description">
                                                    {{ item.title }}
                                                </div>
                                                <div class="description" v-html="item.description"></div>
                                            </el-collapse-item>
                                        </template>
                                    </el-collapse>
                                </div>
                            </template>
                        </template>
                        <template v-if="response.type !== 'package'">
                            <el-collapse accordion>
                                <template v-for="(item, index) in response.tabs">
                                    <el-collapse-item :name="index" :key="index">
                                        <template slot="title">
                                            <div class="head">
                                                <div class="title">{{ item.short_description ? item.short_description : item.title }}</div>
                                                <div class="description">Detayları görüntülemek için tıklayınız.</div>
                                            </div>
                                        </template>
                                        <div class="short-description" v-if="item.short_description">
                                            {{ item.title }}
                                        </div>
                                        <div class="description" v-html="item.description"></div>
                                    </el-collapse-item>
                                </template>
                            </el-collapse>
                        </template>
                    </div>

                    <div class="box-tab-content-item box-tab-faqs" v-show="activeTab === 3">
                        <el-collapse accordion class="faqs">
                            <template v-for="(item, index) in response.faqs">
                                <el-collapse-item :name="index" :key="index">
                                    <template slot="title">
                                        <div class="head">
                                            <div class="title">{{ item.title }}</div>
                                        </div>
                                    </template>
                                    <div class="description" v-html="item.description"></div>
                                </el-collapse-item>
                            </template>
                        </el-collapse>
                    </div>

                    <div class="box-tab-content-item box-tab-comments" v-show="activeTab === 4">
                        <ContentComment :id="response.id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ContentComment from "@/app/Components/Comments/Content";
export default {
    name: 'Tab',
    components: {ContentComment},
    props : ['type', 'response'],
    data() {
        return {
            activeTab: 1
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
