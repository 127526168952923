<template>
    <page :title="$t('menu.videos')" v-loading="pageLoading">
        <template #actions>
            <div class="breadcrumb-wrp">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/dashboard' }">
                        {{ $t('menu.dashboard') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/videos' }">
                        {{ $t('menu.videos') }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        {{ $route.meta.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </template>

        <div class="page video-page video-page-detail" >
            <div class="page-head">
                <div class="video-page--head">
                    {{ response.title }}
                </div>
            </div>

            <div class="page-body">
                <div class="video-page--media">
                    <video ref="VideoPlayer" :poster="response.poster" :src="response.src" controls controlsList="nodownload" oncontextmenu="return false;"></video>
                </div>

                <div class="box margin-top-20">
                    <div class="box-body video-page--text">
                        <div v-html="response.description"></div>
                    </div>
                </div>

                <RelationTest :items="response.tests" view="answer" />
                <RelationWordList :items="response.word_lists" />
            </div>
        </div>
    </page>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import RelationWordList from "@/app/Pages/WordLists/_parts/RelationWordList.vue";
import RelationTest from "@/app/Pages/Tests/_parts/RelationTest.vue";

export default {
    name: 'Show',
    components: {RelationTest, RelationWordList},
    data() {
        return {
            path: 'platform/student/videos',
            pageLoading: true,
            response: null,

            isCover: true,
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        handleClickPlay() {
            this.isCover = false;
            this.$refs.VideoPlayer.play();
        }
    }
}
</script>
