import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Reports/Index";
import Show from "@/app/Pages/Reports/Show";

export default {
    path: '/reports',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Bireysel Değerlendirme Raporları',
    },
    children: [
        {
            name: 'app.reports.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Rapor',
            },
        },
        {
            name: 'app.reports.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Rapor',
            },
        },
    ]
}
