<template>
    <select-box :options="options" icon="package-up" :container="container" :model="model" :multiple="true" v-loading="loading" v-if="elementSize(options)" />
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: 'ProductBox',
    props: {
        container: {
            default: {}
        },
        model: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: false,
            options: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.loading = true;

            ApiService.get('platform/misc/search/classrooms')
                .then(({data}) => {
                    this.options = data.data;
                    this.loading = false;
                });
        }
    }
};
</script>
