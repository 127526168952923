<template>
    <div class="container">
        <header id="ContentHeader">
            <AppMenu :title="title" />
            <div class="actions">
                <slot name="actions"></slot>
            </div>
        </header>
        <div class="main-content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import AppMenu from "@/app/Layouts/Parts/AppMenu.vue";

export default {
    props : ['title'],
    components: {
        AppMenu
    }
}
</script>
