<template>
    <div v-if="action" class="upload-form">
        <el-upload
            multiple
            :headers="headers"
            :action="action"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="handleSuccess"
            :limit="limit"
            :on-exceed="handleExceed"
            :file-list="fileList"
        >
            <el-button type="warning" class="fluid" plain icon="el-icon-upload">
                {{ $t('btn_add_file') }}
            </el-button>
        </el-upload>
    </div>
</template>

<script>
import Config from "../config/app.js";
import JwtService from "@/services/jwt.service";

export default {
    name: 'FileUploads',
    props: {
        fileList: {
            default: []
        },
        remove: {
            //
        },
        add: {
            //
        },
        limit: {
            default: 6
        },
        alias: {
            default: 'homeworks'
        },
    },
    data() {
        return {
            action: null,
            headers: {
                //
            }
        };
    },
    mounted() {
        this.action = Config.endPoint + '/platform/misc/file-manager?alias=' + this.alias;
        this.headers.Authorization = 'Bearer ' + JwtService.getToken();
        this.headers.Accept = 'application/json';
        this.headers.Tenant = Config.tenantName;
    },
    methods: {
        handleSuccess(file) {
            this.add(file.data);
        },
        handleRemove(file, fileList) {
            return this.remove(fileList);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`The limit is ${this.limit}, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption);
        }
    }
};
</script>
