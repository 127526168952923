<template>
    <div class="comments" v-loading="pageLoading">
        <div class="comment-empty" v-if="!pageLoading && !elementSize(items(storePath(path)))">
            <div class="comment-empty-wrp">
                <div class="media">
                    <img src="/img/empty/comment.svg" alt="empty">
                </div>
                <div class="text">
                    <div class="title">Henüz Yorum Yapılmamış.</div>
                    <div class="description">Bu gönderiye ilk yorum yapan sen ol.</div>
                </div>
            </div>
        </div>

        <div class="head-title">
            <span>Kendi Yorumunu</span> Paylaş
        </div>

        <div class="comment-form">
            <el-form :model="form" label-position="top" @submit.native.prevent="store">
                <div class="comment-form-row">
                    <el-form-item :class="{ 'is-error': submitted && $v.form.content.$error }">
                        <el-input v-model="form.content" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_message')" type="textarea" rows="1" />
                    </el-form-item>
                    <div class="action">
                        <el-button plain type="primary" icon="mdi mdi-send" @click="store" :loading="loading" :disabled="loading" class="visible-desktop" />
                        <el-button plain type="primary" @click="store" :loading="loading" :disabled="loading" class="visible-mobile">
                            {{ $t('btn_send') }}
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="messages">
            <template v-for="(item, index) in items(storePath(path))">
                <div class="message" :key="index" v-if="!item.is_active && account.id === item.user.id">
                    <div class="head">
                        <div class="author">
                            <avatar :name="item.user.name" :source="item.user.avatar" />
                            <div class="name">
                                {{ item.user.name }}
                            </div>
                        </div>
                        <div class="date">
                            <icon source="clock-outline" /> {{ item.date }}
                        </div>
                        <div class="status text-yellow" v-if="!item.is_active && account.id === item.user.id">
                            Onay Bekliyor
                        </div>
                    </div>
                    <div class="body">
                        <div class="message-body-wrp">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
    </div>
</template>

<script>
import {DESTROY_CRUD_ITEM, GET_CRUD_ITEMS, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {minLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

const marked = require('marked');

export default {
    name: 'ContentComment',
    props: ['id'],
    data() {
        return {
            path: 'platform/misc/comments/',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,

            loading: false,
            submitted: false,
            form: {
                //
            },

            dialogVisible: false,
        }
    },
    validations: {
        form: {
            content: {required, minLength: minLength(10)},
        }
    },
    computed: mapGetters(['pagination', 'items', 'account']),
    mounted() {
        this.path = this.path + this.id + '/contents';

        this.index();
    },
    methods: {

        viewer(text) {
            return marked(text);
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create(parentId) {
            this.dialogVisible = true;
            this.form = {
                //
            }

            if (parentId) {
                this.form.parent_id = parentId
            }
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        edit(item) {
            this.dialogVisible = true;
            this.form = {
                id: item.id,
                content: item.description
            }
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        }
    }
}
</script>