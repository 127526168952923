<template>
    <div class="basket-page">

        <empty alias="basket" title="basket" size="popover" v-if="summary && !elementSize(summary.products)" />

        <template v-if="summary && elementSize(summary.products)">
            <el-row :gutter="24" v-loading="pageLoading">
                <el-col :span="17" :xs="24">
                    <SuggestionBand :index="index" v-if="elementSize(summary.suggestions)" />

                    <div class="basket-box">
                        <div class="box">
                            <div class="box-head">
                                <div class="title">
                                    <span>Sepet</span> Bilgilerim
                                </div>
                            </div>
                            <div class="box-body">
                                <div class="products">
                                    <template v-for="(item, index) in summary.products">
                                        <div class="item" :key="index">
                                            <div class="content">
                                                <div class="media">
                                                    <icon :source="findProductIcon(item.type)" />
                                                </div>
                                                <div class="text">
                                                    <div class="title">
                                                        {{ item.title }}
                                                    </div>
                                                    <div class="metas">
                                                        <div class="meta variant" v-if="item.variant_type">
                                                            <div>
                                                                <template v-if="item.variant_type === 'live_and_record'">
                                                                    <span>Canlı</span> ve Kayıttan
                                                                </template>
                                                                <template v-if="item.variant_type === 'record'">
                                                                    Sadece <span>Kayıttan</span>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="meta" v-if="item.type === 'group'">
                                                            <icon source="account-tie-outline" />
                                                            {{ item.user.name }}
                                                        </div>
                                                    </div>
                                                    <div class="package-products" v-if="elementSize(item.child)">
                                                        <template v-for="(packageItem, packageIndex) in item.child">
                                                            <div class="pb" :key="packageIndex">
                                                                <div class="pb-media">
                                                                    <icon source="message-video" />
                                                                </div>
                                                                <div class="pb-text">
                                                                    <div class="pb-text-title">
                                                                        {{ packageItem.title }}
                                                                    </div>
                                                                    <div class="pb-text-description">
                                                                        <template v-if="packageItem.variant_type === 'live_and_record'">
                                                                            <span>Canlı</span> ve Kayıttan
                                                                        </template>
                                                                        <template v-if="packageItem.variant_type === 'record'">
                                                                            Sadece <span>Kayıttan</span>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="price-box">
                                                {{ $n(item.price, 'currency') }}
                                                <div class="description">
                                                    {{ item.is_shipping ? '(Kargolu Ürün)' : '(Kargosuz Ürün)'}}
                                                </div>
                                            </div>
                                            <div class="actions">
                                                <el-tooltip :content="$t('btn_delete')" placement="left">
                                                    <el-button plain size="small" type="danger" icon="el-icon-delete" @click="destroy(item.id)" />
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-for="(item, index) in summary.gifts">
                                        <div class="item gift" :key="index + '-gift'">
                                            <div class="content">
                                                <div class="media">
                                                    <icon source="gift-outline" />
                                                </div>
                                                <div class="text">
                                                    <div class="title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="price-box">
                                                <del class="description">{{ $n(item.price, 'currency') }}</del>
                                                <div>
                                                    Hediye
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="box-footer checkout-box-footer">
                                <div class="checkout-nav-btn left" @click="goRoute('/shop/' + ui.shop_categories[0].slug)" v-if="ui.shop_categories[0]">
                                    <icon source="arrow-left" />
                                    <div class="txt">
                                        <span>Alışverişe</span> Geri Dön
                                    </div>
                                </div>
                                <div class="checkout-nav-btn right" @click="truncate">
                                    <div class="txt">
                                        <span>Sepeti</span> Temizle
                                    </div>
                                    <icon source="trash-can-outline" />
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="7" :xs="24">
                    <SidebarCheckout ref="CheckoutSummary">
                        <el-button type="primary" icon="el-icon-shopping-cart-2" class="fluid checkout-btn" @click="store">
                            Alışverişi Tamamla
                        </el-button>
                    </SidebarCheckout>
                </el-col>
            </el-row>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_CHECKOUT_NAVIGATION_STEP} from "@/store/mutations.type";
import {DESTROY_CHECKOUT_ADD_TO_BASKET, TRUNCATE_CHECKOUT_ADD_TO_BASKET} from "@/store/actions.type";
import SidebarCheckout from "@/app/Pages/Checkout/_parts/Sidebar";
import SuggestionBand from "@/app/Pages/Checkout/_parts/SuggestionBand";

export default {
    name: 'Summary',
    components: {SuggestionBand, SidebarCheckout},
    data() {
        return {
            pageLoading : false,
            dialogVisible : false,
            suggestionTotal : 0
        }
    },
    computed: mapGetters(['summary', 'ui']),
    mounted() {
        this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 1)
    },
    methods: {
        index() {
            this.$refs.CheckoutSummary.index();
        },

        store() {
            this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 2);
            this.goRoute('/checkout/address')
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.pageLoading = true;

                    this.$store
                        .dispatch(DESTROY_CHECKOUT_ADD_TO_BASKET, id)
                        .then(() => {
                            this.$refs.CheckoutSummary.index();
                            this.pageLoading = false;
                        })
                        .catch(() => {
                            this.pageLoading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },

        truncate() {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.pageLoading = true;

                    this.$store
                        .dispatch(TRUNCATE_CHECKOUT_ADD_TO_BASKET)
                        .then(() => {
                            this.$refs.CheckoutSummary.index();
                            this.pageLoading = false;
                        })
                        .catch(() => {
                            this.pageLoading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },
    }
}
</script>
