<template>
    <div class="page-video">
        <div class="box">
            {{ response }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Listening',
    props: ['response'],
}
</script>
