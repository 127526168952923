import PageLayout from "@/app/Layouts/PageLayout";

// Pages
import IndexNewsletter from "@/app/Pages/Misc/Newsletters/Index";
import ShowNewsletter from "@/app/Pages/Misc/Newsletters/Show";

import IndexAnnouncement from "@/app/Pages/Misc/Announcements/Index";
import ShowAnnouncement from "@/app/Pages/Misc/Announcements/Show";

import IndexMessage from "@/app/Pages/Misc/Messages/Index";

import TalkIndex from "@/app/Pages/Misc/Talks/Index";
import TalkShow from "@/app/Pages/Misc/Talks/Show";

export default {
    path: '/misc',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Misc',
    },
    children: [
        {
            name: 'app.misc.messages.show',
            path: 'messages',
            component: IndexMessage,
            meta: {
                auth: true,
                title: 'Mesajlar',
            },
        },
        {
            name: 'app.misc.talks.index',
            path: 'talks',
            component: TalkIndex,
            meta: {
                auth: true,
                title: 'Mesajlar',
            },
        },
        {
            name: 'app.misc.talks.show',
            path: 'talks/:id',
            component: TalkShow,
            meta: {
                auth: true,
                title: 'Mesajlar',
            },
        },
        {
            name: 'app.misc.newsletters.index',
            path: 'newsletters',
            component: IndexNewsletter,
            meta: {
                auth: true,
                title: 'Bilgilendirme Mektupları',
            },
        },
        {
            name: 'app.misc.newsletters.show',
            path: 'newsletters/:id',
            component: ShowNewsletter,
            meta: {
                auth: true,
                title: 'Mektup',
            },
        },
        {
            name: 'app.misc.announcements.index',
            path: 'announcements',
            component: IndexAnnouncement,
            meta: {
                auth: true,
                title: 'Duyurular',
            },
        },
        {
            name: 'app.misc.announcements.show',
            path: 'announcements/:id',
            component: ShowAnnouncement,
            meta: {
                auth: true,
                title: 'Duyuru',
            },
        },
    ]
}
