<template>
    <div v-if="response">
        <page :title="response.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/listenings' }">
                            {{ $t('menu.listenings') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page listening-page" >
                <div class="page-body">
                    <div class="box">
                        <div class="box-body rows">
                            <div class="row">
                                <div class="content">
                                    <div class="media">
                                        <icon source="music-box-outline" />
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ response.title }}
                                        </div>
                                        <div class="metas">
                                            <div class="meta">
                                                <icon source="calendar-outline" />
                                                {{ response.date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <audio ref="AudioPlayer" controls :src="response.src"></audio>
                            </div>
                        </div>
                    </div>

                    <RelationTest :items="response.tests" view="answer" />
                    <RelationWordList :items="response.word_lists" />
                </div>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import RelationTest from "@/app/Pages/Tests/_parts/RelationTest.vue";
import RelationWordList from "@/app/Pages/WordLists/_parts/RelationWordList.vue";

export default {
    name: 'Show',
    components: {RelationWordList, RelationTest},
    data() {
        return {
            path: 'platform/student/listenings',
            pageLoading: true,
            response: null,
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        }
    }
}
</script>
