<template>
    <div class="education-relation-word-lists">
        <template v-for="wordList in items">
            <div class="margin-top-20">
                <div class="word-list-rows">
                    <el-row :gutter="24">
                        <template v-for="(item, index) in wordList.words">
                            <el-col :span="12">
                                <WordItem :item="item" :key="index" />
                            </el-col>
                        </template>
                    </el-row>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import WordItem from "@/app/Pages/WordLists/_parts/Word.vue";

export default {
    name: 'RelationWordList',
    components: {WordItem},
    props : ['items'],
}
</script>
