<template>
    <div>
        <el-dialog :visible.sync="contract.visible" width="65%" append-to-body :before-close="handleClickClose" class="contract-dialog no-header">
            <div class="dialog-global-close-btn" @click="handleClickClose">
                <i class="el-icon-close"></i>
            </div>
            <div class="body" v-if="contract.index">
                <template v-for="(item, index) in ui.contracts">
                    <div v-if="index === contract.index">
                        <div class="contract-dialog-title">{{ item.title }}</div>
                        <div class="contract-dialog-wrp">
                            <div v-html="reFormatDescription(item.description)"></div>
                        </div>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_UI_CONTRACT} from "@/store/mutations.type";

export default {
    name: 'Contract',
    computed: mapGetters(['ui', 'account', 'contract']),
    data() {
        return {
            props: {
                first_name: '<span class="prop_first_name"> </span>',
                last_name: '<span class="prop_last_name"> </span>',
                identification_no: '<span class="prop_identification_no"> </span>',
                birthday: '<span class="prop_birthday"> </span>',
                address_1: '<span class="prop_address_1"> </span>',
                phone: '<span class="prop_phone"> </span>',
                email: '<span class="prop_email"> </span>',
            }
        }
    },
    methods: {
        handleClickClose() {
            this.$store.commit(SET_UI_CONTRACT, {visible: false, index: null})
        },
        reFormatDescription(text) {

            if (!this.account) {
                return text;
            }

            this.lodash.forEach(this.props, (value, index) => {
                text = text.replace(value, this.account.contact[index]);
            })

            return text;
        }
    }
}
</script>
