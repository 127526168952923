<template>
    <div class="orders">
        <empty alias="order" title="order" v-if="!pageLoading && !elementSize(items(storePath(path)))" />
        <div class="items" v-loading="pageLoading">
            <template v-for="(item, index) in items(storePath(path))">
                <div class="item" :key="index">
                    <div class="summary">
                        <div class="products">
                            <template v-for="(product, productIndex) in item.products">
                                <div class="product-item" :key="productIndex" v-if="productIndex <= 2">
                                    <icon :source="findProductIcon(product.type)" />
                                </div>
                            </template>
                            <div class="product-item count" v-if="elementSize(item.products) > 3">
                                +{{ elementSize(item.products) - 3 }}
                            </div>
                        </div>
                        <div class="info">
                            <div class="code">{{ item.code }}</div>
                            <div class="date">{{ item.date }}</div>
                        </div>
                        <div class="status" :style="{color: item.status.bg_color}" v-if="item.status">
                            {{ item.status.title }}
                        </div>
                        <div class="price">
                            <div class="amount">
                                {{ $n(item.total, 'currency') }}
                            </div>
                            <div class="payment">
                                {{ item.payment.title }}
                            </div>
                        </div>
                        <div class="action">
                            <el-tooltip :content="$t('btn_view')">
                                <el-button plain circle class="is-icon" type="primary" icon="el-icon-view" @click="show(item)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="page-pagination pagination" v-if="pagination(storePath(path)) && pagination(storePath(path)).total > pagination(storePath(path)).per_page">
            <el-pagination background layout="prev, pager, next" :current-page.sync="page" :total="pagination(storePath(path)).total" :page-size="pagination(storePath(path)).per_page" @current-change="handleChangePage" />
        </div>

        <el-dialog :title="response.code + ' Sipariş Bilgileri'" :visible.sync="dialogVisible" width="80%" append-to-body class="order-dialog checkout-page">
            <div class="head">
                <div class="info">
                    <div class="code">{{ response.code }}</div>
                    <div class="date">{{ response.date }}</div>
                </div>
                <div class="status" :style="{color: response.status.bg_color}" v-if="response.status">
                    {{ response.status.title }}
                </div>
            </div>

            <div class="message" v-if="response.payment && response.payment.alert" v-html="response.payment.alert"></div>

            <div class="products">
                <template v-for="(product, index) in response.products">
                    <div class="product item" :key="index">
                        <div class="content">
                            <div class="media">
                                <icon :source="findProductIcon(product.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ product.title }}</div>
                                <div class="description" v-if="product.variant_type">
                                    {{ findProductType(product.variant_type) }}
                                </div>
                                <div class="package-products" v-if="elementSize(product.child)">
                                    <template v-for="(packageItem, packageIndex) in product.child">
                                        <div class="pb" :key="packageIndex" style="padding: 10px 0">
                                            <div class="pb-media">
                                                <icon :source="findProductIcon(packageItem.type)" />
                                            </div>
                                            <div class="pb-text">
                                                <div class="pb-text-title">
                                                    {{ packageItem.title }} <span class="text-secondary" v-if="packageItem.band" v-html="packageItem.band"></span>
                                                </div>
                                                <div class="metas">
                                                    <div class="pb-text-description">
                                                        <template v-if="packageItem.variant_type === 'live_and_record'">
                                                            <span>Canlı</span> ve Kayıttan
                                                        </template>
                                                        <template v-if="packageItem.variant_type === 'record'">
                                                            Sadece <span>Kayıttan</span>
                                                        </template>
                                                    </div>
                                                    <div class="meta margin-left-20">
                                                        {{  packageItem.remaining_at }} /  {{  packageItem.remaining_at_full }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <template v-if="product.type === 'group'">
                            <div class="remaing">
                                <el-tooltip content="Kalan Kullanım Süresi">
                                    <div>
                                        <div>
                                            {{ product.remaining_at }}
                                        </div>
                                        <div>
                                            {{ product.remaining_at_full }}
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>

                        <div class="actions">
                            <div class="price">{{ $n(product.total, 'currency') }}</div>
                            <div class="price-description">
                                <template v-if="product.is_shipping">
                                    (Kargolu Ürün)
                                </template>
                                <template v-if="!product.is_shipping">
                                    (Kargosuz Ürün)
                                </template>
                            </div>
                            <div class="price-description" v-if="product.is_gift">
                                (Hediye Ürün)
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <el-row :gutter="24">
                <el-col :span="16" :xs="24">
                    <div class="addresses">
                        <div class="head">Adres Bilgileri</div>
                        <template v-for="(address, index) in response.addresses">
                            <div class="address" :key="index">
                                <div class="media">
                                    <icon source="truck" v-if="address.type === 'delivery'" />
                                    <icon source="receipt" v-if="address.type === 'invoice'" />
                                </div>
                                <div class="text">
                                    <div class="title">{{ $t('account.orders.address.' + address.type) }}</div>
                                    <div class="description">{{ address.description }}</div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="contract fs-16 font-weight-600 margin-top-40">
                        <a @click="contractDialog('sales')" target="_blank">Mesafeli satış sözleşmesi</a>
                    </div>
                </el-col>
                <el-col :span="8" :xs="24">
                    <div class="payment mobile-margin-top-40">
                        <div class="head">Ödeme Bilgileri</div>
                        <div class="items">
                            <div class="item">
                                <div class="text" v-if="response.payment">
                                    <div class="name">{{ response.payment.title }}</div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="text">
                                    <div class="name"><span>Ürünler</span> Toplamı</div>
                                    <div class="description">( KDV Dahil )</div>
                                </div>
                                <div class="price">
                                    {{ $n(response.price, 'currency') }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="text">
                                    <div class="name"><span>İndirim</span>  Toplamı</div>
                                </div>
                                <div class="price">
                                    {{ $n(response.discount, 'currency') }}
                                </div>
                            </div>
                            <div class="item active">
                                <div class="text">
                                    <div class="name"><span>Genel</span>  Toplam</div>
                                </div>
                                <div class="price">
                                    {{ $n(response.total, 'currency') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'OrderAccount',
    data() {
        return {
            path: 'platform/account/orders',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
            dialogVisible: false,
            response: {
                //
            },
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        show(item) {
            this.dialogVisible = true;
            this.response = item;
        },
    }
}
</script>
