<template>
  <div v-if="response">
      <page :title="response.title" v-loading="pageLoading">
          <template #actions>
              <div class="breadcrumb-wrp">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/dashboard' }">
                          {{ $t('menu.dashboard') }}
                      </el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/books' }">
                          {{ $t('menu.books') }}
                      </el-breadcrumb-item>
                      <el-breadcrumb-item>
                          {{ $route.meta.title }}
                      </el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
          </template>
          <div class="page book-page" >
              <div class="book-page-directory" slot="reference">
                  <div class="column unit" @click="handleDirectoryVisible('unit')">
                      <div class="title">Ünite</div>
                      <div class="description">
                          {{ directory.unit ? directory.unit.title : 'Seçiniz' }}
                      </div>
                  </div>
                  <div class="column section" @click="handleDirectoryVisible('section')">
                      <div class="title">Bölüm</div>
                      <div class="description">
                          {{ directory.section ? directory.section.title : 'Seçiniz' }}
                      </div>
                  </div>
                  <div class="column exercise" @click="handleDirectoryVisible('section')">
                      <div class="title">Alıştırma</div>
                      <div class="description">
                          {{ directory.exercise ? directory.exercise.title : 'Seçiniz' }}
                      </div>
                  </div>
              </div>

              <div class="page-body margin-top-20">
                  <router-view />
              </div>

              <el-dialog title="Dizin" :visible.sync="directory.visible" width="50%" append-to-body class="book-directory-dialog custom-padding">
                  <div class="body" v-if="response">
                      <div class="book-page-directory" slot="reference">
                          <div class="column unit" @click="handleChangeTab('unit')">
                              <div class="title">Ünite</div>
                              <div class="description">
                                  {{ directory.unit ? directory.unit.title : 'Seçiniz' }}
                              </div>
                          </div>
                          <div class="column section" @click="handleChangeTab('section')">
                              <div class="title">Bölüm</div>
                              <div class="description">
                                  {{ directory.section ? directory.section.title : 'Seçiniz' }}
                              </div>
                          </div>
                          <div class="column exercise" @click="handleChangeTab('section')">
                              <div class="title">Alıştırma</div>
                              <div class="description">
                                  {{ directory.exercise ? directory.exercise.title : 'Seçiniz' }}
                              </div>
                          </div>
                      </div>
                      <div class="tab-column units" :class="{active : directory.viewType === 'unit'}">
                          <template v-for="(unit, index) in response.units">
                              <div class="unit" :key="index"
                                   :class="{active : (directory.unit && directory.unit.id === unit.id)}"
                                   @click="handleSelectUnit(unit)">
                                  <div class="media">
                                      <icon source="book-open-page-variant-outline" />
                                  </div>
                                  <div class="text">
                                      <div class="title">{{ unit.title }}</div>
                                      <div class="description">{{ unit.description }}</div>
                                  </div>
                              </div>
                          </template>
                      </div>
                      <div class="tab-column sections" :class="{active : directory.viewType === 'section'}">
                          <template v-for="(section, index) in (directory.unit ? directory.unit.sections : [])">
                              <div class="section" :key="index">
                                  <div class="head">
                                      <div class="title">{{ section.title }}</div>
                                      <div class="description">{{ section.description }}</div>
                                  </div>
                                  <div class="exercises">
                                      <template v-for="(exercise, exerciseIndex) in section.exercises">
                                          <div class="exercise" :key="exerciseIndex"
                                               @click="handleSelectSection(section, exercise)"
                                               :class="{active : (directory.exercise && directory.exercise.id === exercise.id)}">
                                              <div class="title">{{ exercise.title }}</div>
                                              <div class="description">{{ exercise.description }}</div>
                                          </div>
                                      </template>
                                  </div>
                              </div>
                          </template>
                      </div>
                  </div>
              </el-dialog>
          </div>
      </page>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Show',
    data() {
        return {
            path: 'platform/student/books',
            pageLoading: true,
            response: null,

            directory: {
                viewType: 'section',
                visible: false,
                unit: null,
                section: null,
                exercise: null,
            }
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));

                    this.setDirectory();
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        setDirectory() {
            this.lodash.forEach(this.response.units, (unit) => {

                this.lodash.forEach(unit.sections, (section) => {
                    if (section.id === parseInt(this.$route.params.sectionId)) {
                        this.directory.section = section;
                    }

                    this.lodash.forEach(section.exercises, (exercise) => {
                        if (exercise.id === parseInt(this.$route.params.exerciseId)) {
                            this.directory.exercise = exercise;
                        }
                    })
                })

                if (unit.id === parseInt(this.$route.params.unitId)) {
                    this.directory.unit = unit;
                }
            });
        },

        handleDirectoryVisible(viewType) {
            if (this.directory.unit && viewType === 'section') {
                this.directory.viewType = 'section';
            } else {
                this.directory.viewType = 'unit';
            }

            this.directory.visible = true;
        },

        handleChangeTab(viewType) {
            if (this.directory.unit && viewType === 'section') {
                this.directory.viewType = 'section';
            } else {
                this.directory.viewType = 'unit';
            }
        },

        handleSelectUnit(unit) {
            this.directory.viewType = 'section';
            this.directory.unit = unit;
        },

        handleSelectSection(section, exercise) {
            this.directory.section = section;
            this.directory.exercise = exercise;

            this.directory.visible = false;

            this.goRoute('/books/' + this.response.id + '/units/' + this.directory.unit.id + '/sections/' + this.directory.section.id + '/exercises/' + this.directory.exercise.id)
        }
    }
}
</script>
