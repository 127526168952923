<template>
    <div class="success-page checkout-page" v-loading="pageLoading">

        <empty alias="order" title="not_result" v-if="!pageLoading && !response" />

        <template v-if="response">
            <SuccessAnimated />

            <div class="box margin-top-100">
                <div class="box-body">
                    <div class="order-dialog">
                        <div class="head">
                            <div class="info">
                                <div class="code">{{ response.code }}</div>
                                <div class="date">{{ response.date }}</div>
                            </div>
                            <div class="status" :style="{color: response.status.bg_color}" v-if="response.status">
                                {{ response.status.title }}
                            </div>
                        </div>

                        <div class="message" v-if="response.payment && response.payment.alert" v-html="response.payment.alert"></div>

                        <div class="products margin-top-20">
                            <template v-for="(product, index) in response.products">
                                <div class="product item" :key="index">
                                    <div class="content">
                                        <div class="media">
                                            <icon :source="findProductIcon(product.type)" />
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ product.title }}</div>
                                            <div class="description" v-if="product.variant_type">
                                                {{ findProductType(product.variant_type) }}
                                            </div>

                                            <div class="package-products" v-if="elementSize(product.child)">
                                                <template v-for="(packageItem, packageIndex) in product.child">
                                                    <div class="pb" :key="packageIndex" style="padding: 10px 0">
                                                        <div class="pb-media">
                                                            <icon source="message-video" />
                                                        </div>
                                                        <div class="pb-text">
                                                            <div class="pb-text-title">
                                                                {{ packageItem.title }}
                                                            </div>
                                                            <div class="metas">
                                                                <div class="pb-text-description">
                                                                    <template v-if="packageItem.variant_type === 'live_and_record'">
                                                                        <span>Canlı</span> ve Kayıttan
                                                                    </template>
                                                                    <template v-if="packageItem.variant_type === 'record'">
                                                                        Sadece <span>Kayıttan</span>
                                                                    </template>
                                                                </div>
                                                                <div class="meta margin-left-20">
                                                                    {{  packageItem.remaining_at }} /  {{  packageItem.remaining_at_full }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="product.type === 'group'">
                                        <div class="remaing">
                                            <el-tooltip content="Kalan Kullanım Süresi">
                                                <div>
                                                    <div>
                                                        {{ product.remaining_at }}
                                                    </div>
                                                    <div>
                                                        {{ product.remaining_at_full }}
                                                    </div>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>

                                    <div class="actions">
                                        <div class="price">{{ $n(product.total, 'currency') }}</div>
                                        <div class="price-description">
                                            <template v-if="product.is_shipping">
                                                (Kargolu Ürün)
                                            </template>
                                        </div>
                                        <div class="price-description" v-if="product.is_gift">
                                            (Hediye Ürün)
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <el-row :gutter="24">
                            <el-col :span="16" :xs="24">
                                <div class="addresses">
                                    <div class="head">Adres Bilgileri</div>
                                    <template v-for="(address, index) in response.addresses">
                                        <div class="address" :key="index">
                                            <div class="media">
                                                <icon source="truck" v-if="address.type === 'delivery'" />
                                                <icon source="receipt" v-if="address.type === 'invoice'" />
                                            </div>
                                            <div class="text">
                                                <div class="title">{{ $t('account.orders.address.' + address.type) }}</div>
                                                <div class="description">{{ address.description }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <div class="contract fs-16 font-weight-600 margin-top-40">
                                    <a @click="contractDialog('sales')" target="_blank">Mesafeli satış sözleşmesi</a>
                                </div>
                            </el-col>
                            <el-col :span="8" :xs="24">
                                <div class="payment mobile-margin-top-40">
                                    <div class="head">Ödeme Bilgileri</div>
                                    <div class="items">
                                        <div class="item">
                                            <div class="text" v-if="response.payment">
                                                <div class="name">{{ response.payment.title }}</div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="text">
                                                <div class="name"><span>Ürünler</span> Toplamı</div>
                                                <div class="description">( KDV Dahil )</div>
                                            </div>
                                            <div class="price">
                                                {{ $n(response.price, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="text">
                                                <div class="name"><span>İndirim</span>  Toplamı</div>
                                            </div>
                                            <div class="price">
                                                {{ $n(response.discount, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="item active">
                                            <div class="text">
                                                <div class="name"><span>Genel</span>  Toplam</div>
                                            </div>
                                            <div class="price">
                                                {{ $n(response.total, 'currency') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="box-footer">
                    <el-button type="success" @click="goRoute('/dashboard')">
                        Platform Sayfasına Dön
                    </el-button>
                    <el-button type="primary" @click="goRoute('/account/orders')">
                        Siparişlerime Git
                    </el-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {SET_CHECKOUT_NAVIGATION_STEP, SET_CHECKOUT_QUANTITY} from "@/store/mutations.type";
import SuccessAnimated from "@/app/Pages/Checkout/_parts/SuccessAnimated";
import ApiService from "@/services/api.service";

export default {
    name: 'Success',
    components: {SuccessAnimated},
    data() {
        return {
            pageLoading: true,
            response: null,
        }
    },
    mounted() {

        localStorage.removeItem('coupon');

        this.index();
    },
    methods: {
        index() {
            this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 4);

            this.pageLoading = true;

            ApiService.get('platform/student/checkout/success')
                .then(({data}) => {
                    this.pageLoading = false;
                    this.response = data.data
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        }
    }
}
</script>
