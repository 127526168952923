<template>
    <page :title="$route.meta.title">
        <template #actions>
            <div class="page-filter">
                <ProductFilter :filter="handleSelectedProduct" />
                <StatusFilter type="tests" :filter="handleSelectedTab" />
            </div>
        </template>
        <div class="page page-test" v-loading="pageLoading">
            <div class="page-search">
                <SearchFilter :container="filter" model="title" :action="index" />
            </div>
            <div class="page-body is-search">
                <div class="box">
                    <empty alias="test" title="test" v-if="!pageLoading && !elementSize(items(storePath(path)))" />
                    <div class="box-body rows">
                        <template v-for="(item, index) in items(storePath(path))">
                            <div class="row cursor-pointer" :key="index" @click="goRoute('/tests/' + item.id)" :class="{tertiary : item.status === 'wait'}">
                                <div class="content">
                                    <div class="media">
                                        <svg-icon src="app-tests" />
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ item.title }}
                                        </div>
                                        <div class="metas">
                                            <div class="meta">
                                                <icon source="ballot-recount-outline" />
                                                {{ item.metas.question_count }} Soru
                                            </div>
                                            <template v-if="item.status !== 'wait'">
                                                <div class="meta">
                                                    <icon source="check-circle-outline" />
                                                    {{ item.metas.correct_count }} Doğru
                                                </div>
                                                <div class="meta">
                                                    <icon source="alert-circle-outline" />
                                                    {{ item.metas.wrong_count }} Yanlış
                                                </div>
                                                <div class="meta">
                                                    <icon source="checkbox-blank-circle-outline" />
                                                    {{ item.metas.empty_count }} Boş
                                                </div>
                                                <div class="meta">
                                                    <icon source="clock-outline" />
                                                    {{ formatSecondTime(item.metas.time) }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
            </div>
        </div>
    </page>
</template>

<script>
import ProductFilter from "@/app/Components/Filters/Product";
import StatusFilter from "@/app/Components/Filters/Status";
import SearchFilter from "@/app/Components/Filters/Search";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'Index',
    components: {SearchFilter, StatusFilter, ProductFilter},
    data() {
        return {
            path: 'platform/student/tests',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        search() {
            this.page = 1;
            this.index();
        },

        handleSelectedProduct(productIds) {
            this.filter.products = productIds;
            this.page = 1;
            this.index();
        },

        handleSelectedTab(value) {
            this.filter.tab = value;
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, products : this.filter.products, tab : this.filter.tab, title : this.filter.title})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
    }
}
</script>
