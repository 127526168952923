<template>
    <div class="payment-page" v-loading="pageLoading">
        <template v-if="plugin">
            <el-row :gutter="24">
                <el-col :span="17" :xs="24">
                    <div class="payment-box">
                        <div class="box">
                            <div class="box-head">
                                <div class="title">
                                    <span>Ödeme</span> Seçenekleri
                                </div>
                            </div>
                            <div class="payment-tab-container">
                                <div class="payment-tabs">
                                    <template v-for="(item, index) in plugins">
                                        <div class="tab" :key="index" :class="{active : item.id === plugin.id}"
                                             @click="handleChangePaymentMethod(item)">
                                            <svg v-if="item.icon === 'icon-cards'" version="1.1" id="Layer_1"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"
                                                 xml:space="preserve">
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path d="M90.82,32.72h-9.53l0-15.79c0-5.06-4.12-9.18-9.18-9.18l-62.94,0C4.12,7.75,0,11.87,0,16.93L0,58.1
                                                                        c0,5.06,4.12,9.18,9.18,9.18l7.88,0l0,15.79c0,5.06,4.12,9.18,9.18,9.18l64.59,0c5.06,0,9.18-4.12,9.18-9.18l0-41.17
                                                                        C100,36.84,95.88,32.72,90.82,32.72z M5.24,16.93c0-2.17,1.76-3.93,3.93-3.93l62.94,0c2.17,0,3.93,1.76,3.93,3.93v3.66l-70.8,0
                                                                        L5.24,16.93z M17.06,41.9l0,20.14H9.18c-2.17,0-3.93-1.76-3.93-3.93l0-20.12h12.69C17.37,39.17,17.06,40.5,17.06,41.9z
                                                                         M5.24,32.74l0-6.9l70.8,0v6.88l-49.82,0c-0.18,0-0.35,0.01-0.53,0.02H5.24z M90.82,87H26.23c-2.17,0-3.93-1.76-3.93-3.93
                                                                        l0-41.17c0-2.06,1.59-3.75,3.61-3.92l52.76,0c0.1,0,0.19-0.01,0.29-0.02l11.87,0c2.17,0,3.93,1.76,3.93,3.93l0,41.17h0
                                                                        C94.76,85.24,92.99,87,90.82,87z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                    <path d="M68.08,49.84c-2.98,0-5.72,1.04-7.89,2.77c-2.22-1.79-4.98-2.78-7.91-2.78c-6.97,0-12.64,5.67-12.64,12.64
                                                                        c0,6.97,5.67,12.64,12.64,12.64c2.92,0,5.69-0.99,7.91-2.78c2.16,1.73,4.91,2.77,7.89,2.77c6.97,0,12.64-5.67,12.64-12.64
                                                                        C80.72,55.51,75.05,49.84,68.08,49.84z M56.86,68.29c-1.29,1.02-2.89,1.59-4.58,1.59c-4.08,0-7.4-3.32-7.4-7.4
                                                                        c0-4.08,3.32-7.4,7.4-7.4c1.69,0,3.29,0.57,4.58,1.59c-0.91,1.74-1.42,3.72-1.42,5.81C55.44,64.58,55.95,66.55,56.86,68.29z
                                                                         M68.08,69.88c-4.08,0-7.39-3.32-7.39-7.4c0-4.08,3.32-7.39,7.39-7.39c4.08,0,7.39,3.32,7.39,7.39
                                                                        C75.47,66.56,72.16,69.88,68.08,69.88z" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <path opacity="0.25" style="opacity:0.25;" d="M20.96,36.82v52.55h76.35V36.82H20.96z M77.04,66.57c-0.44,2.39-1.52,5.14-3.87,6.23
                                                            c-2.82,1.31-6.26,0-9.02-0.84c-1.56-0.48-3.11-0.92-4.76-0.97c-1.86-0.06-3.72,0-5.58-0.16c-2.98-0.26-6.13-1.09-8.52-2.97
                                                            c-2.11-1.65-3.17-4.11-3.06-6.78c0.12-2.8,1.13-5.43,3.81-6.66c3.05-1.4,6.74-2.18,9.97-0.99c0.41,0.15,0.58,0.51,0.56,0.86
                                                            c0.41,0.21,0.84,0.38,1.29,0.5c1.04,0.28,2.12,0.13,3.16-0.1c1.91-0.42,3.79-1.11,5.78-0.98c1.75,0.12,3.39,0.78,4.86,1.71
                                                            c1.49,0.94,3.12,2.08,4.21,3.49C77.55,61.1,77.51,63.98,77.04,66.57z" />
                                                </g>
                                            </svg>
                                            <svg v-if="item.icon === 'icon-eft'" version="1.1" id="Layer_1"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"
                                                 xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M35.85,29.8c1.98,0,3.58,1.6,3.58,3.58c0,1.51,1.21,2.72,2.72,2.72s2.72-1.21,2.72-2.72c0-4.03-2.66-7.46-6.3-8.61v-1.53
				c0-1.51-1.21-2.72-2.72-2.72s-2.72,1.21-2.72,2.72v1.53c-3.66,1.15-6.3,4.58-6.3,8.61c0,4.99,4.38,9.02,9.37,9.02
				c-0.08,0,0,0,0.02,0c1.8,0.18,3.23,1.7,3.23,3.56c0,1.98-1.6,3.58-3.58,3.58c-1.98,0-3.58-1.6-3.58-3.58
				c0-1.51-1.21-2.72-2.72-2.72c-1.51,0-2.72,1.21-2.72,2.72c0,4.03,2.66,7.46,6.3,8.61v1.51c0,1.51,1.21,2.72,2.72,2.72
				c1.51,0,2.72-1.21,2.72-2.72v-1.51c3.66-1.15,6.3-4.58,6.3-8.61c0-4.99-4.05-9.04-9.04-9.04c0.08,0,0,0-0.02,0
				c-1.82-0.18-3.56-1.68-3.56-3.54C32.27,31.4,33.89,29.8,35.85,29.8z" />
		</g>
        <g>
			<path d="M97.2,40.21l-11.41-9.88V13.42c0-1.51-1.21-2.72-2.72-2.72h-20L51.8,0.95c-1.02-0.88-2.54-0.88-3.56,0L36.97,10.7H16.98
				c-1.51,0-2.72,1.21-2.72,2.72v16.91L2.83,40.21c-1.12,0.33-1.94,1.37-1.94,2.6v54.17c0,1.51,1.21,2.72,2.72,2.72h92.77
				c1.51,0,2.72-1.21,2.72-2.72V42.81C99.12,41.58,98.32,40.56,97.2,40.21z M85.78,37.55l6.26,5.42l-6.26,5.42V37.55z M50.02,6.63
				l4.7,4.07H45.3L50.02,6.63z M19.72,16.16h60.61v36.95L50.02,79.31l-30.3-26.21V16.16z M14.26,37.55v10.84L8,42.97L14.26,37.55z
				 M6.36,48.78l24.4,21.12L6.36,91.02V48.78z M10.96,94.25L34.95,73.5l13.29,11.49c1.04,0.92,2.68,0.86,3.56,0L65.09,73.5
				l23.99,20.75H10.96z M93.68,91.02L69.26,69.9l24.4-21.12v42.23H93.68z" />
            <path d="M96.38,100H3.62c-1.66,0-3.01-1.35-3.01-3.01V42.81c0-1.3,0.85-2.47,2.09-2.87l11.28-9.76V13.42
				c0-1.66,1.35-3.01,3.01-3.01h19.87l11.19-9.67c1.12-0.97,2.82-0.97,3.95,0l11.19,9.67h19.89c1.66,0,3.01,1.35,3.01,3.01v16.78
				l11.26,9.76c1.25,0.42,2.07,1.57,2.04,2.87v54.17C99.4,98.65,98.04,100,96.38,100z M16.98,10.99c-1.34,0-2.43,1.09-2.43,2.43
				v17.04L2.98,40.47l-0.06,0.02c-1.02,0.3-1.73,1.26-1.73,2.32v54.17c0,1.34,1.09,2.43,2.43,2.43h92.77c1.34,0,2.43-1.09,2.43-2.43
				V42.81c0.02-1.07-0.66-2-1.69-2.32l-0.06-0.02L85.5,30.46V13.42c0-1.34-1.09-2.43-2.43-2.43H62.96L51.61,1.17
				c-0.91-0.78-2.27-0.78-3.18,0l-11.36,9.82H16.98z M89.87,94.54h-79.7l24.78-21.43l13.48,11.65c0.89,0.79,2.37,0.79,3.16,0.01
				l13.49-11.67L89.87,94.54z M11.74,93.95h76.55L65.09,73.89l-13.1,11.32c-1.01,0.99-2.83,0.99-3.95,0L34.95,73.89L11.74,93.95z
				 M6.06,91.66V48.14L31.21,69.9L6.06,91.66z M6.65,49.43v40.95L30.31,69.9L6.65,49.43z M94.47,91.31h-1.1v-0.18L68.81,69.9
				l25.15-21.76v42.72L94.47,91.31z M69.71,69.9l23.66,20.46V49.43L69.71,69.9z M50.02,79.7L19.43,53.24V15.87h61.2v37.38
				L50.02,79.7z M20.02,52.97l30,25.95l30.02-25.95V16.45H20.02V52.97z M85.48,49.03V36.91l7,6.06L85.48,49.03z M86.07,38.19v9.56
				l5.52-4.78L86.07,38.19z M14.56,49.03l-7-6.06l7-6.06V49.03z M8.45,42.97l5.52,4.78v-9.56L8.45,42.97z M55.5,10.99H44.51
				l5.51-4.75L55.5,10.99z M46.09,10.4h7.84l-3.91-3.39L46.09,10.4z" />
		</g>
        <g>
			<path d="M55.23,28.55h16.17c1.51,0,2.72-1.21,2.72-2.72c0-1.51-1.21-2.72-2.72-2.72H55.23c-1.51,0-2.72,1.21-2.72,2.72
				C52.5,27.33,53.72,28.55,55.23,28.55z" />
		</g>
        <g>
			<path d="M55.23,41.29h16.17c1.51,0,2.72-1.21,2.72-2.72c0-1.51-1.21-2.72-2.72-2.72H55.23c-1.51,0-2.72,1.21-2.72,2.72
				C52.49,40.07,53.72,41.29,55.23,41.29z" />
		</g>
        <g>
			<path d="M55.23,54.03h11.23c1.51,0,2.72-1.21,2.72-2.72c0-1.51-1.21-2.72-2.72-2.72H55.23c-1.51,0-2.72,1.21-2.72,2.72
				C52.49,52.81,53.72,54.03,55.23,54.03z" />
		</g>
	</g>
    <g opacity="0.25" style="opacity:0.25;">
		<path d="M55.68,81.03c9.18-9.18,18.37-18.37,27.55-27.55V13.22H16.3v39.92c0.03,0.02,0.05,0.05,0.08,0.07
			c3.55,3.55,7.1,7.1,10.65,10.65l17.16,17.16H55.68z" />
	</g>
</g>
</svg>

                                            {{ item.title }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="box-body">
                                <CardPaymentMethod ref="PaymentMethod" :plugin="plugin" :summary="summary" v-if="plugin.icon === 'icon-cards'" />
                                <BankPaymentMethod ref="PaymentMethod" :plugin="plugin" :summary="summary" v-if="plugin.icon === 'icon-eft'" />
                            </div>
                            <div class="box-body">
                                <el-form>
                                    <el-form-item>
                                        <el-checkbox v-model="form.contract">
                                            <a @click="contractDialog('sales')" target="_blank">Mesafeli satış</a> sözleşmesini okudum ve onaylıyorum.
                                        </el-checkbox>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="box-footer checkout-box-footer">
                                <div class="checkout-nav-btn left" @click="goRoute('/checkout/address')">
                                    <icon source="arrow-left" />
                                    <div class="txt">
                                        <span>Önceki Adıma</span> Geri Dön
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="7" :xs="24">
                    <SidebarCheckout ref="CheckoutSummary">
                        <el-button type="primary" icon="el-icon-bank-card" class="fluid" @click="store" :loading="loading" :disabled="loading">
                            Ödeme Yap
                        </el-button>
                    </SidebarCheckout>
                </el-col>
            </el-row>
        </template>

        <el-dialog :visible.sync="dialogVisible" width="56%" append-to-body class="no-header secure-payment-dialog">
            <template v-if="paymentLink">
                <el-alert :closable="false">
                    Lütfen "<b>işlem başarılı</b>" mesajını görene kadar bu sayfayı kapatmayın!
                </el-alert>

                <iframe id="PaymentDialogWindow" :src="paymentLink" frameborder="0" class="margin-top-20"></iframe>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {SET_CHECKOUT_NAVIGATION_STEP} from "@/store/mutations.type";
import {GET_CRUD_ITEMS, STORE_CRUD_ITEM} from "@/store/actions.type";
import SidebarCheckout from "@/app/Pages/Checkout/_parts/Sidebar";
import CardPaymentMethod from "@/app/Pages/Checkout/_methods/Card";
import BankPaymentMethod from "@/app/Pages/Checkout/_methods/Bank";
import FormResponseService from "@/services/form_response.service";

export default {
    name: 'Payment',
    components: {BankPaymentMethod, CardPaymentMethod, SidebarCheckout},
    data() {
        return {
            path: 'platform/student/checkout/payments',
            pageLoading: false,
            plugins: [],
            plugin: null,
            loading: false,
            form : {
                //
            },
            dialogVisible : false,
            paymentLink : null
        }
    },
    mounted() {
        if (this.$route.query.error) {
            FormResponseService.error({
                message: this.$route.query.error
            })
        }

        this.$store.commit(SET_CHECKOUT_NAVIGATION_STEP, 3);
        this.index();
    },
    methods: {

        summary() {
            this.$refs.CheckoutSummary.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path})
                .then((response) => {
                    this.pageLoading = false;
                    this.plugins = response;

                    if (this.elementSize(this.plugins)) {
                        this.plugin = this.plugins[0];
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        handleChangePaymentMethod(item) {
            this.plugin = item;
        },

        store() {
            let form = this.$refs.PaymentMethod.validation();

            if (!form) {
                return false;
            }

            form.path = this.path;
            form.disable_push = true;
            form.coupon_id = localStorage.getItem('coupon');
            form.contract = this.form.contract;

            this.paymentLink = null;

            this.loading = true;

            this.$store
                .dispatch(STORE_CRUD_ITEM, form)
                .then((response) => {
                    if (response.type === 'redirect') {
                        return this.goRoute('/checkout/success')
                    }

                    if (response.link) {
                        this.paymentDialog(response.link)
                    }

                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        success() {
            return this.goRoute('/checkout/success')
        },

        paymentDialog(link) {
            this.dialogVisible = true;
            this.paymentLink = link;

            setTimeout(() => {
                window.addEventListener('message', event => {
                    if (event.data && event.data === 'ConfirmDialogClose') {
                        this.dialogVisible = false;
                        this.paymentLink = null;
                    }

                    if (event.data && event.data === 'ConfirmSuccess') {
                        this.dialogVisible = false;
                        this.paymentLink = null;
                        this.pageLoading = true;
                        return this.goRoute('/checkout/success')
                    }
                });
            }, 1500)
        }
    }
}
</script>
