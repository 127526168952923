const PluginService = {

	createScript(src, async, attributes) {
		let page = document;
		let element = page.createElement('script');

		element.setAttribute('src', src);

		if (async) {
			element.setAttribute('async', async);
		}

		for (let i = 0; i < attributes.length; i++) {
			element.setAttribute(attributes[i].index, attributes[i].value);
		}

		setTimeout(() => {
			page.getElementsByTagName('body')[0].appendChild(element);
		}, 500)
	},

	CrispChat(plugin) {

		if (!plugin) {
			return false;
		}

		let content = 'window.$crisp = []; CRISP_COOKIE_DOMAIN="' + window.location.hostname + '";window.CRISP_WEBSITE_ID = "' + plugin.CRISP_WEBSITE_ID + '";(function () {d = document;s = d.createElement("script");s.src = "https://client.crisp.chat/l.js";s.async = 1;d.getElementsByTagName("head")[0].appendChild(s);})(); $crisp.push(["safe", true])';

		if (plugin.is_active && plugin.platform_is_active) {
			this.generateScriptTag(content);
		}
	},

	JivoChat(plugin) {

		if (!plugin) {
			return false;
		}

		if (plugin.is_active && plugin.platform_is_active) {
			this.createScript('//code-eu1.jivosite.com/widget/' + plugin.secret_key, 1, []);
		}
	},

	JivoChatUser(user) {
		let setinfo = setInterval(() => {
			const jivo = window.jivo_api;

			if (jivo) {
				jivo.setContactInfo({
					name: user.name,
					email: user.email,
					phone: user.phone,
				});

				clearInterval(setinfo);
			}
		}, 2000);
	},

	generateScriptTag(content) {
		let element = document.createElement('script');
		element.setAttribute('type', 'text/javascript');
		element.text = content;
		document.head.appendChild(element)
	}
};

export default PluginService;
