<template>
    <div @click="index">
        <el-tooltip :content="$t('notifications.title')" placement="bottom">
            <div class="mobile-item dot-badge-container">
                <icon source="bell-outline" />
            </div>
        </el-tooltip>

        <el-drawer :title="$t('notifications.title')" :visible.sync="visible" append-to-body>
            <div class="popover-notification-container feeds">
                <div class="body" v-loading="loading">
                    <empty alias="notification" title="notification" size="popover" v-if="!elementSize(items(storePath(path))) && !loading" />

                    <template v-for="(item, index) in items(storePath(path))">
                        <div class="feed padding-0" :key="index">
                            <div class="head">
                                <div class="date">
                                    <icon source="clock-time-five-outline" />
                                    {{ item.date }}
                                </div>
                            </div>
                            <div class="message">
                                <router-link :to="findNotificationLink(item.type, item.id)">
                                    {{ item.title }}
                                </router-link>
                                <span v-if="item.action">{{ $t('notifications.actions.' + item.action) }}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="footer" v-if="elementSize(items)">
                    <router-link to="/misc/notifications">
                        {{ $t('notifications.all') }}
                    </router-link>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'NotificationHeader',
    data() {
        return {
            path: 'platform/student/misc/notifications',
            loading: false,
            visible : false,
            filter: {
                //
            },
            page: 1
        }
    },
    computed: mapGetters(['pagination', 'items']),
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },
        search() {
            this.page = 1;
            this.index();
        },
        index() {
            this.loading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.loading = false;
                    this.visible = true;
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
