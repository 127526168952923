<template>
    <div id="PayForm" v-html="$route.query.form"></div>
</template>

<script>
export default {
    name: 'Pay',
    data() {
        return {
            //
        }
    },
    mounted() {
        setTimeout(() => {
            $('#PayForm form').submit();
        }, 1000)
    }
}
</script>