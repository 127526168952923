import JwtService from "@/services/jwt.service";

const _ = require('lodash');
import {Notification, MessageBox} from 'element-ui';

export const error = (response) => {
    if (!response) {
        return MessageBox.alert('Servislerimizde yaşanan yoğunluk nedeniyle şu anda erişim sağlayamıyorsunuz. Lütfen 1 - 2 dakika bekledikten sonra tekrar deneyin.', 'Üzgünüm :(', {
            showConfirmButton : false
        });
    }

    if (response.message === 'Unauthenticated.') {
        JwtService.destroyToken();
        window.location = '/#/auth/login';
    }

    if (response.errors) {
        _.forEach(response.errors, (error) => {
            Notification.error({
                title: 'Upss!',
                message: error[0],
                position: 'top-right',
                customClass: 'alert-error',
            });
        })
    }

    if (response.error) {
        Notification.error({
            title: 'Upss!',
            message: response.error,
            position: 'top-right',
            customClass: 'alert-error',
        });
    }

    if (response.message && (!response.error && !response.errors)) {
        Notification.error({
            title: 'Upss!',
            message: response.message,
            position: 'top-right',
            customClass: 'alert-error',
        });
    }
};

export const success = (message) => {

    if (!message) {
        return;
    }

    Notification.success({
        message: message,
        position: 'top-right',
        customClass: 'alert-success',
    });
};

export const info = (message) => {
    Notification.success({
        message: message,
        position: 'top-right',
        customClass: 'alert-info',
    });
};

export const warning = (message) => {
    Notification.success({
        message: message,
        position: 'top-right',
        customClass: 'alert-warning',
    });
};

export default {error, success, warning, info};
