<template>
   <div v-if="response">
       <page :title="response.title" v-loading="pageLoading">
           <template #actions>
               <div class="breadcrumb-wrp">
                   <el-breadcrumb separator-class="el-icon-arrow-right">
                       <el-breadcrumb-item :to="{ path: '/dashboard' }">
                           {{ $t('menu.dashboard') }}
                       </el-breadcrumb-item>
                       <el-breadcrumb-item :to="{ path: '/lessons' }">
                           {{ $t('menu.lessons') }}
                       </el-breadcrumb-item>
                       <el-breadcrumb-item>
                           {{ $route.meta.title }}
                       </el-breadcrumb-item>
                   </el-breadcrumb>
               </div>
           </template>
           <div class="page lesson-page" >
               <div class="page-body is-head">
                   <div class="box" v-loading="lessonLoading">
                       <div class="box-body rows">
                           <div class="row">
                               <div class="content">
                                   <div class="media">
                                       <icon source="message-video" />
                                   </div>
                                   <div class="text">
                                       <div class="title">
                                           {{ response.title }}
                                       </div>
                                       <div class="metas">
                                           <div class="meta">
                                               {{ response.teacher }}
                                           </div>
                                           <div class="meta">
                                               <icon source="timer-sand" />
                                               {{ response.duration }} dk.
                                           </div>
                                           <div class="meta">
                                               <icon source="clock-outline" />
                                               {{ response.times[0] }} - {{ response.times[1] }}
                                           </div>
                                           <div class="meta">
                                               <icon source="calendar-outline" />
                                               {{ response.date }}
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div class="lesson-preview">
                       <div class="box">
                           <div v-if="response" class="media" v-loading="lessonLoading" :key="response.id">
                               <div class="cover" v-if="response.status !== 'record'">
                                   <img src="/img/lesson-video-cover.jpg" alt="cover">
                                   <div class="caption-wrp">
                                       <template v-if="response.status === 'wait'">
                                           <LessonCountdown :now="response.now_unix" :start="response.start_unix" :link="'/lessons/' + response.id + '/live'" />
                                       </template>
                                       <template v-if="response.status === 'live'">
                                           <div class="play-btn" @click="goRoute('/lessons/' + response.id + '/live')">
                                               <icon source="play" />
                                           </div>
                                       </template>
                                       <template v-if="response.status === 'recording'">
                                           <div class="message">
                                               {{ $t('lessons.actions.recording') }}
                                           </div>
                                       </template>
                                   </div>
                               </div>

                               <div class="lesson-player" v-if="response.status === 'record'">
                                   <LessonPlayer :lesson="response" />
                               </div>
                           </div>
                           <div class="directory">
                               <template v-for="(directory, index) in directories">
                                   <div class="item" :key="index" :class="{active : isActiveDirectory(directory.id)}" @click="changeLesson(directory.id)">
                                       <div class="title">
                                           {{ directory.title }}
                                       </div>
                                       <div class="metas">
                                           <div class="meta">
                                               <icon source="timer-sand" />
                                               {{ directory.duration }} dk.
                                           </div>
                                           <div class="meta">
                                               <icon source="clock-outline" />
                                               {{ directory.times[0] }} - {{ directory.times[1] }}
                                           </div>
                                           <div class="meta">
                                               <icon source="calendar-outline" />
                                               {{ directory.date }}
                                           </div>
                                       </div>
                                   </div>
                               </template>
                           </div>
                       </div>
                   </div>

                   <div class="box" v-if="response && ui.permissions.has_access_homeworks" v-loading="lessonLoading">
                       <div class="box-head">
                           <div class="title">{{ $t('homeworks.title') }}</div>
                       </div>
                       <div class="box-body rows">
                           <empty alias="homework" title="homework_live" size="crud-small" v-if="!elementSize(response.homeworks)" />
                           <template v-for="(homework, index) in response.homeworks">
                               <div class="row cursor-pointer" :key="index" @click="goRoute('/homeworks/' + homework.id)">
                                   <div class="content">
                                       <div class="media">
                                           <icon source="book-edit" />
                                       </div>
                                       <div class="text">
                                           <div class="title">
                                               {{ homework.title }}
                                           </div>
                                           <div class="metas">
                                               <div class="meta">
                                                   {{ homework.teacher }}
                                               </div>
                                               <div class="meta">
                                                   <icon source="calendar-outline" />
                                                   {{ homework.date }}
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="actions">
                                       <el-button plain circle class="is-icon" type="primary" icon="el-icon-arrow-right" />
                                   </div>
                               </div>
                           </template>
                       </div>
                   </div>
               </div>
           </div>
       </page>
   </div>
</template>

<script>
import LessonCountdown from "@/app/Pages/Lessons/_parts/Countdown";
import LessonPlayer from "@/app/Pages/Lessons/_parts/Player";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";
import ApiService from "@/services/api.service";

export default {
    name: 'Show',
    components: {LessonPlayer, LessonCountdown},
    data() {
        return {
            path: 'platform/student/lessons',
            pageLoading: true,
            response: null,
            directories : [],

            lessonLoading : false
        }
    },
    computed: mapGetters(['item', 'ui']),
    mounted() {
        this.show();
        this.getDirectory()
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        getDirectory() {
            ApiService.setHeader();
            ApiService.get(this.path + '/' + this.$route.params.id, {params: {isDirectory: true}})
                .then(({data}) => {
                    this.directories = data.data;
                })
        },

        changeLesson(id) {
            this.lessonLoading = true;

            ApiService.setHeader();
            ApiService.get(this.path + '/' + id)
                .then(({data}) => {
                    this.response = data.data;
                    this.lessonLoading = false;

                    window.history.pushState('', '', '/#/lessons/' + id);
                })
        },

        isActiveDirectory(id) {
            if (!this.response) {
                return false;
            }

            return this.response.id == id;
        }
    }
}
</script>
