<template>
    <svg class="svg-icon">
        <use :xlink:href="'/dist/icons.svg#mock-' + src"></use>
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: ['src'],
};
</script>
