<template>
    <div class="wordy-intro">
        <div class="wordy-intro-wrp">
            <div class="title">{{ response.title }}</div>
            <div class="description" v-html="response.description"></div>
            <div class="countdown">
                <div class="contdown-wrp">
                    {{ time }}
                </div>
            </div>
            <div class="slogan"> Öğreneceğiniz <b>{{ elementSize(response.words) }}</b> Kelime ile Tanışın! </div>
            <el-button type="primary" @click="next('image-app')" class="app-btn margin-top-30">
                {{ $t('btn_next') }}
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntroWordy',
    props: ['response', 'next'],
    data() {
        return {
            time : 5,
            timer : null
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.time --;

            if (this.time <= 0) {
                this.next('image-app');
                clearInterval(this.timer);
            }
        }, 1000);
    }
}
</script>
