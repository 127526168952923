<template>
    <div class="filter-item" v-if="account">
        <el-popover placement="bottom-end" width="500" trigger="click" append-to-body popper-class="filter-list-popover">
            <div class="action" slot="reference">
                <div class="title">
                    <template v-if="!productSelectedCount">
                        {{ $t('misc.all_products') }}
                    </template>
                    <template v-if="productSelectedCount">
                        {{ $t('misc.selected_products', {total: productSelectedCount}) }}
                    </template>
                </div>
                <div class="media">
                    <i class="icon el-icon-arrow-down"></i>
                </div>
            </div>
            <div class="items overflow-300">
                <template v-for="(item, index) in account.products">
                    <div class="item" :key="index" :class="{active : item.is_selected}" @click="handleSelectedProduct(item)">
                        <div class="media">
                            <icon :source="findProductIcon(item.type)" />
                        </div>
                        <div class="text">
                            <div class="title">{{ item.title }}</div>
                            <div class="description">{{ item.variant }}</div>
                            <div class="description">{{ item.teacher }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </el-popover>

        <div class="action action-mobile" @click="dialogVisible = true">
            <div class="title">
                <template v-if="!productSelectedCount">
                    {{ $t('misc.all_products') }}
                </template>
                <template v-if="productSelectedCount">
                    {{ $t('misc.selected_products', {total: productSelectedCount}) }}
                </template>
            </div>
            <div class="media">
                <i class="icon el-icon-arrow-down"></i>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="100%" append-to-body class="page-filter-item-dialog no-header">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <div class="filter-list-popover">
                <div class="items overflow-300">
                    <template v-for="(item, index) in account.products">
                        <div class="item" :key="index" :class="{active : item.is_selected}" @click="handleSelectedProduct(item)">
                            <div class="media">
                                <icon :source="findProductIcon(item.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <div class="description">{{ item.variant }}</div>
                                <div class="description">{{ item.teacher }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'ProductFilter',
    props: ['filter'],
    data() {
        return {
            productSelectedCount: 0,
            dialogVisible : false,
        }
    },
    computed: mapGetters(['account']),
    methods: {
        handleSelectedProduct(product) {

            this.productSelectedCount = 0;

            product.is_selected = !product.is_selected;

            let ids = [];

            this.lodash.forEach(this.account.products, (item) => {
                if (item.is_selected) {
                    this.productSelectedCount++;

                    ids.push(item.id)
                }
            });


            return this.filter(ids)
        }
    }
}
</script>
