<template>
    <div class="countdown">
        <div class="inline" v-if="!goLesson">
            <template v-if="days">
                <span>{{ days }}</span> {{ $t('lessons.actions.countdown.days') }}
            </template>
            <template v-if="hours">
                <span>{{ hours }}</span> {{ $t('lessons.actions.countdown.hours') }}
            </template>
            <template v-if="minutes">
                <span>{{ minutes }}</span> {{ $t('lessons.actions.countdown.minutes') }}
            </template>
            <span>{{ seconds }}</span> {{ $t('lessons.actions.countdown.seconds') }} {{ $t('lessons.actions.countdown.after') }}
        </div>
        <el-tooltip :content="$t('lessons.actions.live')">
            <el-button plain circle class="is-icon" type="success" icon="el-icon-arrow-right" @click="goRoute(link)" v-if="goLesson" />
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: 'LessonCountdown',
    props: ['now', 'start', 'link'],
    data() {
        return {
            timer: null,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            goLesson: false,
        }
    },
    mounted() {
        let distance = this.start - this.now;

        this.timer = setInterval(() => {

            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (this.minutes < 10 && !this.days && !this.hours) {
                clearInterval(this.timer);
                this.goLesson = true;
            }

            distance = distance - 1000

        }, 1000);
    }
}
</script>
