import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Videos/Index";
import Show from "@/app/Pages/Videos/Show";

export default {
	path: '/videos',
	component: PageLayout,
	meta: {
		auth: true,
		title: 'Videolar',
	},
	children: [
		{
			name: 'app.videos.index',
			path: '',
			component: Index,
			meta: {
				auth: true,
				title: 'Videolar',
			},
		},
		{
			name: 'app.videos.show',
			path: ':id',
			component: Show,
			meta: {
				auth: true,
				title: 'Video',
			},
		},
	]
}
