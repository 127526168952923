<template>
    <div class="total-amount">
        <template v-if="item.discount">
            <div class="total-price">
                <span class="total-price__old">{{ $n(item.price * quantity, 'currency') }}</span>
                <span class="total-price__main">{{ $n(item.discount.price * quantity, 'currency') }}</span>
            </div>
            <div class="total-sale">
                <el-tooltip :content="item.discount.description">
                    <span>%{{ item.discount.percent }}</span>
                </el-tooltip>
            </div>
        </template>
        <template v-if="!item.discount">
            <div class="total-price">
                <span class="total-price__main">{{ $n((isTotal ? item.total : item.price) * (quantity ? quantity : 1), 'currency') }}</span>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    name: 'Amount',
    props : ['item', 'quantity', 'isTotal'],
}
</script>
