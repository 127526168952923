<template>
    <div v-if="response">
        <page :title="$route.meta.title" v-loading="pageLoading">
            <template #actions>
                <div class="breadcrumb-wrp">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/dashboard' }">
                            {{ $t('menu.dashboard') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/homeworks' }">
                            {{ $t('menu.homework') }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            {{ $route.meta.title }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </template>
            <div class="page" >
                <div class="page-body is-head">
                    <div class="box">
                        <div class="box-body box-list">
                            <div class="box-list-item">
                                <div class="title">{{ response.teacher }}</div>
                                <div class="description">{{ response.date }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="title">{{ response.title }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="title" v-html="response.description"></div>
                            </div>
                            <div class="box-list-item" style="display: none">
                                <div class="media-text">
                                    <div class="media">
                                        <icon source="bookshelf" />
                                    </div>
                                    <div class="text">
                                        Deneme Kitabı 16. Ünite 4. Alıştırma
                                    </div>
                                </div>
                            </div>
                            <div class="box-list-item files">
                                <template v-for="(file, index) in response.files">
                                    <div :key="index">
                                        <el-tooltip :content="$t('btn_view')">
                                            <div class="media" @click="openBlankWindow(publicFilePath(file.path))">
                                                <icon :source="filePreviewIcon(file.mime_type)" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="box margin-top-30">
                        <div class="box-body">
                            <el-form :model="form" label-position="top" @submit.native.prevent="update">
                                <el-form-item :class="{ 'is-error': submitted && $v.form.message.$error }">
                                    <el-input v-model="form.message" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_message')" type="textarea" rows="4" />
                                </el-form-item>

                                <el-form-item :class="{ 'is-error': submitted && $v.form.files.$error }" class="margin-bottom-0">
                                    <file-uploads :file-list="form.files" :remove="fileDestroy" :add="fileAdd" alias="homeworks" />
                                </el-form-item>
                            </el-form>
                        </div>

                        <div class="box-footer">
                            <el-button class="fluid" type="primary" icon="el-icon-check" :loading="loading" :disabled="loading" @click="update">
                                {{ $t('btn_save') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </page>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {minLength, required} from "vuelidate/lib/validators";

export default {
    name: 'Show',
    data() {
        return {
            path: 'platform/student/homeworks',
            pageLoading: true,
            response: null,

            loading: false,
            submitted: false,
            form: {
                message : null,
                files : []
            },
        }
    },
    validations: {
        form: {
            message: {required, minLength: minLength(10)},
            files: {},
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                    this.form = this.cloneObjectData(this.response.me)
                    this.form.id = this.cloneObjectData(this.response.id)
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    //this.show();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        fileDestroy(files) {
            this.form.files = files;
        },

        fileAdd(file) {
            this.form.files.push(file)
        },
    }
}
</script>
