<template>
    <div class="item item-mobile-target icon margin-right-0" @click="dialogVisible = !dialogVisible">
        <svg width="60" height="46" viewBox="0 0 60 46" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="!dialogVisible">
            <rect width="60" height="5" rx="3" fill="#ffffff" />
            <rect y="20" width="40" height="5" rx="3" fill="#ffffff" />
            <rect y="40" width="60" height="5" rx="3" fill="#ffffff" />
        </svg>

        <i class="el-icon-close" v-show="dialogVisible"></i>

        <el-dialog :visible.sync="dialogVisible" width="100%" append-to-body class="mobile-menu-dialog no-header">

            <div class="account" v-if="account">
                <el-collapse>
                    <el-collapse-item>
                        <div slot="title" class="account-title">
                            <avatar :name="account.name" :source="account.avatar" class="media" />
                            <div class="text">
                                {{ account.name }}
                            </div>
                        </div>

                        <div class="popover-menu">
                            <div class="popover-menu-container">
                                <div class="popover-menu-item" @click="dialogVisible = false">
                                    <router-link to="/account/me">
                                        <div class="media">
                                            <icon source="account-outline" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.my_account') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div class="popover-menu-item" @click="dialogVisible = false">
                                    <router-link to="/account/orders">
                                        <div class="media">
                                            <icon source="cart-outline" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.my_orders') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div class="popover-menu-item" @click="dialogVisible = false">
                                    <router-link to="/account/addresses">
                                        <div class="media">
                                            <icon source="map-outline" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.my_address') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div class="popover-menu-item" @click="dialogVisible = false">
                                    <router-link to="/account/discounts">
                                        <div class="media">
                                            <icon source="sale" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.my_discount') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div class="popover-menu-item" @click="dialogVisible = false">
                                    <router-link to="/account/notifications">
                                        <div class="media">
                                            <icon source="bell-ring-outline" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.my_notification') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div class="popover-menu-divider"></div>
                                <div class="popover-menu-item active last" @click="dialogVisible = false">
                                    <router-link to="/account/logout">
                                        <div class="media text-red">
                                            <icon source="power-standby" />
                                        </div>
                                        <div class="text">
                                            {{ $t('account.menu.logout') }}
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>

            <div class="menu-items" v-if="account">
                <div class="menu-item" v-if="ui.permissions.has_access_shop && ui.shop_categories[0]" @click="dialogVisible = false">
                    <router-link :to="'/shop/' + ui.shop_categories[0].slug">
                        <icon source="storefront-outline" />
                    </router-link>
                </div>

                <div class="menu-item" @click="dialogVisible = false" >
                    <router-link to="/homeworks">
                        <icon source="book-edit-outline" />
                    </router-link>
                </div>
                <div class="menu-item" @click="dialogVisible = false" v-if="ui.permissions.has_access_newsletters">
                    <router-link to="/misc/newsletters">
                        <div class="dot-badge-container">
                            <icon source="email-outline" />
                            <div class="dot-badge" v-if="account.counter.newsletter"></div>
                        </div>
                    </router-link>
                </div>
                <div class="menu-item" @click="dialogVisible = false" v-if="ui.permissions.has_access_talk">
                    <router-link to="/misc/messages">
                        <div class="dot-badge-container">
                            <icon source="message-text-outline" />
                            <div class="dot-badge" v-if="account.counter.chat"></div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="apps">
                <template v-for="(menu, index) in menus">
                    <div class="item-wrp" @click="dialogVisible = false" v-if="ui.permissions['has_access_' + menu.name]">
                        <router-link :to="'/' + menu.route" class="item" :key="index" @click="dialogVisible = false">
                            <div class="media">
                                <svg-icon :src="menu.icon" />
                            </div>
                            <div class="title">{{ $t('menu.' + menu.name) }}</div>
                        </router-link>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'MobileMenu',
    props: ['active'],
    data() {
        return {
            dialogVisible: false,
        }
    },
    computed: mapGetters(['menus', 'account', 'ui']),
    mounted() {
        this.$store.dispatch(GET_ACCOUNT);
    },
    methods: {}
}
</script>
