import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Lessons/Index";
import Show from "@/app/Pages/Lessons/Show";
import Live from "@/app/Pages/Lessons/Live";

export default {
    path: '/lessons',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Dersler',
    },
    children: [
        {
            name: 'app.lessons.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Dersler',
            },
        },
        {
            name: 'app.lessons.live',
            path: ':id/live',
            component: Live,
            meta: {
                auth: true,
                title: 'Canlı Ders',
            },
        },
        {
            name: 'app.lessons.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Ders',
            },
        },
    ]
}
