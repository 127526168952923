import PageLayout from "@/app/Layouts/PageLayout";
import Index from "@/app/Pages/Forums/Index";
import Category from "@/app/Pages/Forums/Category";
import Topic from "@/app/Pages/Forums/Topic";

export default {
    path: '/forums',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Forum',
    },
    children: [
        {
            name: 'app.forums.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Forum',
            },
        },
        {
            name: 'app.forums.category',
            path: ':categoryId',
            component: Category,
            meta: {
                auth: true,
                title: 'Forum',
            },
        },
        {
            name: 'app.forums.show',
            path: ':categoryId/:topicId',
            component: Topic,
            meta: {
                auth: true,
                title: 'Forum',
            },
        },

    ]
}
