<template>
    <div class="shop-index">
        <template v-if="category">
            <div class="box" v-if="!pageLoading && !elementSize(items(storePath(path)))">
                <empty alias="shop" title="shop"  />
            </div>

            <div class="product-rows" v-loading="pageLoading">
                <el-row :gutter="24">
                    <template v-for="(item, index) in items(storePath(path))">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6" :key="index">
                            <div class="product-row" :class="item.type">
                                <div class="product-row-content">
                                    <div class="media" @click="goRoute('/shop/'+ category.slug +'/' + item.slug)">

                                        <img :src="item.image" alt="cover">

                                        <div class="badges" v-if="elementSize(item.badges)">
                                            <template v-for="(badge, badgeIndex) in item.badges">
                                                <div class="badge" :style="badge.style" :key="badgeIndex">
                                                    {{ badge.title }}
                                                </div>
                                            </template>
                                        </div>

                                        <div class="caption" v-if="item.stock_status.id === ui.settings.default_stock_status_id">
                                            <div class="teachers">
                                                <template v-for="(teacher, teacherIndex) in item.teachers">
                                                    <el-tooltip :content="teacher.name" placement="bottom">
                                                        <div class="item" :key="teacherIndex">
                                                            <avatar :name="teacher.name" :source="teacher.avatar" />
                                                        </div>
                                                    </el-tooltip>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="title" @click="goRoute('/shop/' + category.slug + '/' + item.slug)">
                                        {{ item.title }}
                                    </div>

                                    <div class="information" v-if="item.stock_status.id === ui.settings.default_stock_status_id">
                                        <div class="metas">
                                            <div class="meta" v-if="item.metas.lesson_count">
                                                <icon source="message-video" />
                                                {{ item.metas.lesson_count }} Ders
                                            </div>
                                            <div class="meta" v-if="item.metas.lesson_hour">
                                                <icon source="clock-outline" />
                                                {{ item.metas.lesson_hour }} Sa.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="price-box" v-if="item.stock_status.id === ui.settings.default_stock_status_id">
                                        <div class="price-box-wrp">
                                            <QuickAddCart :slug="item.slug" />
                                            <Amount :item="item.amount" :quantity="1" />
                                        </div>
                                    </div>

                                    <div class="stock-alert-bar" v-if="item.stock_status.id !== ui.settings.default_stock_status_id">
                                        <div class="stock-alert-bar-wrp">
                                            <div :style="{color : item.stock_status.bg_color}">{{ item.stock_status.title }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </template>
                </el-row>
            </div>

            <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import Amount from "@/app/Pages/Shop/_parts/Amount";
import QuickAddCart from "@/app/Pages/Shop/_parts/QuickAddCart";

export default {
    name: 'Index',
    components: {QuickAddCart, Amount},
    data() {
        return {
            path: 'platform/student/shop',
            filter: {
                //
            },
            page: 1,
            pageLoading: true,

            category : null,
            all : {
                id : 'all',
                title : 'Tümü',
                is_selected : true
            },

            dialogVisible : false,
        }
    },
    computed: mapGetters(['pagination', 'items', 'ui']),
    mounted() {
        this.findCategory();
        this.index();
    },
    methods: {
        findCategory() {
            this.lodash.forEach(this.ui.shop_categories, (item) => {
                if (item.slug === this.$route.params.slug) {
                    this.category = item;
                }
            })
        },

        handleSelectedCategory(category, child) {
            category.is_selected = child;

            this.index();
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        index() {
            this.pageLoading = true;
            let categories = []

            this.lodash.forEach(this.category.childs, (category) => {
                if (category.is_selected) {
                    categories.push(category.is_selected.id)
                }
            })

            if (!this.elementSize(categories)) {
                categories.push(this.category.id)
            }

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, page: this.page, categories : categories})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
    }
}
</script>
