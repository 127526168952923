<template>
    <div>
        <el-tooltip content="Hızlı Ekle" placement="top">
            <div class="quick-add-btn" @click="show">
                <icon source="cart-plus" />
            </div>
        </el-tooltip>

        <el-dialog :visible.sync="dialogVisible" width="60%" append-to-body class="product-row-dialog">
            <div class="dialog-global-close-btn" @click="dialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <div style="min-height: 200px" v-loading="pageLoading">
                <div v-if="response">
                    <div class="product-row">
                        <div class="product-row-head">
                            <div class="media">
                                <icon :source="findProductIcon(response.type)" />
                            </div>
                            <div class="text">
                                <div class="title">
                                    {{ response.title }}
                                </div>
                                <div class="metas" v-if="response.type === 'group'|| response.type === 'package'">
                                    <div class="meta">
                                        <div class="name">
                                            {{ response.type !== 'package' ? 'Eğitmen' : 'Eğitim'}}
                                        </div>
                                        <div class="description">{{ response.teacher_count }}</div>
                                    </div>
                                    <div class="meta">
                                        <div class="name">Kullanım Süresi</div>
                                        <div class="description">
                                            {{ response.product.lesson_usage_time }} ay
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Group :response="response" v-if="response.type === 'group'" />
                        <Package :response="response" v-if="response.type === 'package'" />
                        <Digital :response="response" v-if="response.type === 'digital'" />
                        <Physical :response="response" v-if="response.type === 'physical'" />
                    </div>
                </div>
            </div>

            <template slot="footer" class="dialog-footer">
                <div v-if="response">
                    <router-link :to="'/shop/' + $route.params.slug + '/' + response.slug">
                        Ürünü İncele
                        <icon source="arrow-right" />
                    </router-link>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import Group from "@/app/Pages/Shop/_types/Group";
import Package from "@/app/Pages/Shop/_types/Package";
import Digital from "@/app/Pages/Shop/_types/Digital";
import Physical from "@/app/Pages/Shop/_types/Physical";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'QuickAddCart',
    components: {Physical, Digital, Package, Group},
    props: ['slug'],
    data() {
        return {
            path: 'platform/student/shop',
            pageLoading: true,
            response: null,

            dialogVisible: false,
        }
    },
    computed: mapGetters(['item']),
    methods: {
        show() {
            this.dialogVisible = true;
            this.pageLoading = true;
            this.response = null;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.slug, is_dialog : true})
                .then(() => {
                    this.pageLoading = false;
                    this.response = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                    this.response = null;
                })
        }
    }
}
</script>
