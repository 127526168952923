import ApiService from '../services/api.service';
import JwtService from '../services/jwt.service';
import PluginService from '../services/plugin.service';
import FormResponseService from '../services/form_response.service';

import {
	GET_ACCOUNT,
	UPDATE_ACCOUNT,
	CHANGE_AVATAR_ACCOUNT,
} from "./actions.type";

import {
	SET_ERROR,
	SET_PRELOADING,
	SET_ACCOUNT,
	SET_ACCOUNT_CHAT_MESSAGE_COUNT
} from "./mutations.type";

const state = {
	account: null
};

const getters = {
	account(state) {
		return state.account;
	}
};

const mutations = {
	[SET_ACCOUNT](state, payload) {
		state.account = payload;

		if (payload) {
			PluginService.JivoChatUser({
				name : payload.name,
				email : payload.email,
				phone : payload.phone
			})
		}
	},

	[SET_ACCOUNT_CHAT_MESSAGE_COUNT](state, payload) {
		state.account.counter.chat = payload;
	},
};

const actions = {
	[GET_ACCOUNT](context) {
		if (context.state.account) {
			return false;
		}

		context.commit(SET_PRELOADING, true);

		return new Promise((resolve, reject) => {
			ApiService.get('platform/account/me')
				.then(({data}) => {
					context.commit(SET_ACCOUNT, data.data);
					resolve(data);

					setTimeout(() => {
						context.commit(SET_PRELOADING, false);

						$('#Account').attr('data-id', data.data.id);
						$('#Account').attr('data-name', data.data.name);
						$('#Account').attr('data-email', data.data.email);
						$('#Account').attr('data-phone', data.data.phone);

					}, 500)
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
					JwtService.destroyToken();

					setTimeout(() => {
						window.location = '/#/auth/login'
					}, 1000)
				});
		});
	},

	[UPDATE_ACCOUNT](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.update('platform/account/me/1', params)
				.then(({data}) => {
					context.commit(SET_ACCOUNT, data.data);
					resolve(data);

					FormResponseService.success(data.message)
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	},

	[CHANGE_AVATAR_ACCOUNT](context, params) {
		return new Promise((resolve, reject) => {

			let headers = {
				'Content-Type': 'multipart/form-data'
			}

			ApiService.store('platform/account/me', params, {headers: headers})
				.then(({data}) => {
					context.commit(SET_ACCOUNT, data.data);
					resolve(data);
					FormResponseService.success(data.message)
				})
				.catch(({data}) => {
					context.commit(SET_ERROR, data);
					reject(data);
				});
		});
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
