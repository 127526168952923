<template>
    <div class="single-reading">
        <div class="page-body">
            <template v-for="(page, index) in response.pages">
                <div :key="index" >

                    <div class="single-reading--media">
                        <img :src="page.image" alt="cover">
                    </div>

                    <div class="page-footer margin-top-20">
                        <div class="action-buttons">
                            <el-tooltip content="Çevir">
                                <div class="action" @click="isTranslate = !isTranslate" :class="{active : isTranslate}">
                                    <icon source="translate" />
                                </div>
                            </el-tooltip>
                            <el-tooltip content="Dinle">
                                <div class="action" @click="!isListen ? listening() : cancel()" :class="{active : isListen}">
                                    <icon source="volume-high" />
                                </div>
                            </el-tooltip>
                            <template v-if="isListen">
                                <el-tooltip content="Başa Sar">
                                    <div class="action" @click="refresh">
                                        <icon source="backup-restore" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip content="Duraklat">
                                    <div class="action" @click="pause" v-if="isPlay">
                                        <icon source="pause" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip content="Dinlemeye devam et">
                                    <div class="action" @click="play" v-if="!isPlay">
                                        <icon source="play" />
                                    </div>
                                </el-tooltip>
                            </template>
                        </div>
                    </div>

                    <div class="box margin-top-20">
                        <div class="box-body">
                            <div class="reading-text">
                                <div class="description">
                                    <div v-html="page.description"></div>
                                </div>
                                <div class="translate" v-if="isTranslate">
                                    <div v-html="page.translation"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <RelationTest :items="response.tests" view="answer" />
            <RelationWordList :items="response.word_lists" />
        </div>
    </div>
</template>

<script>
import RelationTest from "@/app/Pages/Tests/_parts/RelationTest.vue";
import RelationWordList from "@/app/Pages/WordLists/_parts/RelationWordList.vue";

export default {
    name: 'SingleReading',
    props : ['response'],
    components: {RelationWordList, RelationTest},
    data() {
        return {
            isListen : false,
            isPlay : false,
            isTranslate : false,
            synth : null,
            utter : null,
            page : null,
        }
    },
    mounted() {
        this.page = this.response.pages[0];
    },
    methods: {
        listening() {
            this.isListen = true;
            this.isPlay = true;
            this.synth = window.speechSynthesis;
            this.utter = new SpeechSynthesisUtterance();

            this.utter.lang = 'en-US';
            this.utter.text = this.page.speak_script

            this.synth.speak(this.utter);
        },

        cancel() {
            this.isListen = false;
            this.isPlay = false;
            this.synth.cancel();
        },

        pause() {
            this.isPlay = false;
            this.synth.pause();
        },

        play() {
            this.isPlay = true;
            this.synth.resume();
        },

        refresh () {
            this.synth.cancel();
            this.listening();
        }
    }
}
</script>
